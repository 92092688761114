import React, { createElement } from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';
import fonts from '../../utils/fonts';
import Container from '../atoms/Container';
import Popup from '../organisms/Popup';
import breakpoints from '../../utils/breakpoints';
import Footer from './Footer';
import { FormattedHTMLMessage } from 'react-intl';
import { FaHandshake } from 'react-icons/all';
import AuthorDescription from '../organisms/AuthorDescription';
import LogoPKOFintech from '../images/LogoPKOFintech';
import LogoPKOTFI from '../images/LogoPKOTFI';
import LogoChmuraKrajowa from '../images/LogoChmuraKrajowa';
import LogoTheHeart from '../images/LogoTheHeart';
import fintechPolandLogo from '../../images/fintech-poland-logo.png';

const Header = styled.header`
  display: grid;
  grid-template-areas: 'icon' 'title' 'hr';
  margin-bottom: 2rem;
  padding-top: 3.75rem;

  @media ${breakpoints.md} {
    padding-top: 5rem;
    grid-template-areas: 'icon title title';
    grid-column-gap: 2rem;
    align-items: center;
    justify-content: flex-start;
  }

  @media ${breakpoints.lg} {
    padding-top: 7rem;
  }
`;

const IconContainer = styled.div`
  grid-area: icon;

  @media ${breakpoints.md} {
    align-self: flex-start;
  }
`;

const StyledIcon = styled(FaHandshake)`
  font-size: 3rem;
  color: ${colors.grayLight};

  @media ${breakpoints.md} {
    font-size: 3.75rem;
  }
`;

const Title = styled.h2`
  grid-area: title;
  font-size: 2.5rem;
  font-family: ${fonts.pko};
  font-weight: bold;
  line-height: 1.125;
  margin: 0.625rem 0 1rem 0;
  align-self: flex-start;

  @media ${breakpoints.md} {
    margin: 0 0 5rem 0;
    font-size: 3rem;
  }

  @media ${breakpoints.lg} {
    font-size: 4.375rem;
  }
`;

const Hr = styled.div`
  width: 3.25rem;
  background-color: ${colors.red};
  border: 0;
  height: 0.5rem;
  margin: 3.75rem 0 0;

  @media ${breakpoints.md} {
    margin-top: 1rem;
    width: 100%;
  }
`;

const MobileHr = styled(Hr)`
  grid-area: hr;

  @media ${breakpoints.md} {
    display: none !important;
  }
`;

const DesktopHr = styled(Hr)`
  @media ${breakpoints.toMd} {
    display: none !important;
  }
`;

const DescriptionWrapper = styled.div`
  display: grid;
  grid-template-areas: '.' '.' '.' '.';
  grid-row-gap: 2rem;
  margin-bottom: 3rem;

  @media ${breakpoints.md} {
    grid-row-gap: 4rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoints.lg} {
    grid-template-areas: '. .' '. .';
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 6rem;
    grid-row-gap: 5rem;
    margin-bottom: 7.5rem;
  }
`;

const Link = styled.a`
  color: ${colors.blue};
  text-decoration: none;

  &:hover {
    color: ${colors.black} !important;
  }
`;

const makeResponsiveLogo = c => styled(c)`
  height: 4.5rem;

  @media ${breakpoints.md} {
    width: auto;
    height: 6rem;
  }

  @media ${breakpoints.lg} {
    height: 6.875rem;
  }
`;

const ResponsiveImage = styled.img`
   height: 4.5rem;

  @media ${breakpoints.md} {
    width: auto;
    height: 6rem;
  }

  @media ${breakpoints.lg} {
    height: 6.875rem;
  }
`;

const ReportAuthorsPopupSection = ({ close }) => (
  <Popup close={close}>
    <Container>
      <Header>
        <IconContainer>
          <StyledIcon />
          <DesktopHr />
        </IconContainer>
        <Title>
          <FormattedHTMLMessage id="footer.reportAuthorsSection.title" />
        </Title>
        <MobileHr />
      </Header>

      <DescriptionWrapper>
        <AuthorDescription
          logo={createElement(makeResponsiveLogo(LogoPKOFintech))}
          link={
            <Link href="https://fintech.pkobp.pl">
              <FormattedHTMLMessage id="footer.reportAuthorsSection.meet" />{' '}
              fintech.pkobp.pl
            </Link>
          }
        >
          <FormattedHTMLMessage id="footer.reportAuthorsSection.fintech" />
        </AuthorDescription>
        <AuthorDescription
          logo={createElement(makeResponsiveLogo(LogoPKOTFI))}
          link={
            <Link href="https://pkotfi.pl">
              <FormattedHTMLMessage id="footer.reportAuthorsSection.meet" />{' '}
              pkotfi.pl
            </Link>
          }
        >
          <FormattedHTMLMessage id="footer.reportAuthorsSection.tfi" />
        </AuthorDescription>
        <AuthorDescription
          logo={createElement(makeResponsiveLogo(LogoChmuraKrajowa))}
          link={
            <Link href="https://chmurakrajowa.pl">
              <FormattedHTMLMessage id="footer.reportAuthorsSection.meet" />{' '}
              chmurakrajowa.pl
            </Link>
          }
        >
          <FormattedHTMLMessage id="footer.reportAuthorsSection.chmurakrajowa" />
        </AuthorDescription>
        <AuthorDescription
          logo={createElement(makeResponsiveLogo(LogoTheHeart))}
          link={
            <Link href="https://theheart.tech">
              <FormattedHTMLMessage id="footer.reportAuthorsSection.meet" />{' '}
              theheart.tech
            </Link>
          }
        >
          <FormattedHTMLMessage id="footer.reportAuthorsSection.theheart" />
        </AuthorDescription>
        <AuthorDescription
          logo={<ResponsiveImage src={fintechPolandLogo} />}
        >
          <FormattedHTMLMessage id="footer.reportAuthorsSection.fintechpoland" />
        </AuthorDescription>
      </DescriptionWrapper>
    </Container>

    <Footer onRedirect={close} />
  </Popup>
);

export default ReportAuthorsPopupSection;

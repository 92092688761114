import React, { useState } from 'react';
import BlockScroll from '../atoms/BlockScroll';
import Portal from '../organisms/Portal';
import PopupMenu from '../molecules/PopupMenu';
import styled, { css, keyframes } from 'styled-components';
import { acceleratedEasing, deceleratedEasing } from '../../utils/styles';
import breakpoints from '../../utils/breakpoints';

const closeAnimationDuration = 200;

const slideInBottom = keyframes`
from {
  transform: translateY(100%);
}
to {
  transform: translateX(0%);
}
`;

const slideOutBottom = keyframes`
from {
  transform: translateX(0%);
}
to {
  transform: translateY(100%);
}
`;

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.6) 20%
  );
  animation: ${fadeIn} ${closeAnimationDuration}ms ${deceleratedEasing};

  ${({ closing }) =>
    closing &&
    css`
      animation: ${fadeOut} ${closeAnimationDuration}ms ${deceleratedEasing};
    `};
`;

const Wrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background-color: white;
  z-index: 200;
  animation: ${slideInBottom} ${closeAnimationDuration}ms ${deceleratedEasing};

  ${({ closing }) =>
    closing &&
    css`
      animation: ${slideOutBottom} ${closeAnimationDuration}ms
        ${acceleratedEasing};
    `};

  display: flex;
  flex-direction: column;

  @media ${breakpoints.lg} {
    top: 80px;
  }
`;

const ScrollArea = styled.main`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
`;

const Popup = ({ close, children, ...props }) => {
  const [closing, setClosing] = useState(false);

  const closeHandler = () => {
    if (closing) {
      return;
    }
    setClosing(true);
    setTimeout(() => close(), closeAnimationDuration);
  };

  return (
    <Portal>
      <BlockScroll />
      <Backdrop closing={closing} />
      <Wrapper {...props} closing={closing}>
        <PopupMenu style={{ flexShrink: 0 }} onClose={closeHandler} />
        <ScrollArea>{children}</ScrollArea>
      </Wrapper>
    </Portal>
  );
};

export default Popup;

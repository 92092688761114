import styled from 'styled-components';
import breakpoints from './breakpoints';

const onlyLarge = _ => styled(_)`
  @media ${breakpoints.toMd} {
    display: none !important;
  }
`;


export default onlyLarge;

import flatten from 'flat';

import home from './home';
import header from './header';
import downloadreport from './downloadreport';
import letsfintech from './letsfintech';
import startups from './startups';
import footer from './footer';
import media from './media';

export const messages = {
  title: `StartUp The Bank`,
  description: `Meet 100 startups cooperating with banks in Poland. Find out what what they do, how they earn money and collaborate with the finance sector.`,
  keywords: `report, fintech, bank, startup, innovation, investments, corporate venture capital, cvc, vc, finance technologies`,
  home,
  downloadreport,
  letsfintech,
  startups,
  footer,
  media,
  header,
  404: {
    header: `404 Page not found`,
    text: `We are sorry but page with such address does not exist.`,
    button: `Return to homepage`,
  },
};

export default flatten(messages);

import React, { useState } from 'react';
import { FaShareAlt } from 'react-icons/all';
import styled, { css } from 'styled-components';
import ButtonBox from '../atoms/ButtonBox';
import breakpoints from '../../utils/breakpoints';
import SharePopup from '../organisms/SharePopup';
import { FormattedMessage } from 'react-intl';

const Button = styled(ButtonBox)`
  display: flex;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1.625rem;
  margin: 0 5px;
  padding: 0.5rem;

  ${({ simple }) =>
    simple
      ? css`
          @media ${breakpoints.md} {
            padding: 0.5rem;
          }
        `
      : css`
          width: 3.25rem;
          height: 3.25rem;

          @media ${breakpoints.md} {
            justify-content: space-between;
            padding: 1rem 1.625rem;
            width: auto;
            height: 3.75rem;
          }
        `};
`;

const ButtonText = styled.span`
  display: none;

  ${({ simple }) =>
    !simple &&
    css`
      @media ${breakpoints.md} {
        display: inline;
      }
    `};
`;

const StyledShareIcon = styled(FaShareAlt)`
  font-size: inherit;

  ${({ simple }) =>
    !simple &&
    css`
      @media ${breakpoints.md} {
        margin-left: 1rem;
      }
    `};
`;

const ShareButton = props => {
  const [showSharePopup, setShowSharePopup] = useState(false);

  return (
    <>
      <Button {...props} onClick={() => setShowSharePopup(true)}>
        <ButtonText simple={props.simple}>Share</ButtonText>
        <StyledShareIcon simple={props.simple} />
      </Button>
      {showSharePopup && (
        <SharePopup
          close={() => setShowSharePopup(false)}
          header={<FormattedMessage id="home.sharePopupHeader" />}
        />
      )}
    </>
  );
};

export default ShareButton;

export default {
  heading: `About Let’s Fintech`,
  title: `About Let’s Fintech`,
  section1: {
    link: 'https://fintech.pkobp.pl/fintech-en/cooperation-with-startups/',
    button: `Find out more`,
    text: `
      <p>
        <strong>Let’s Fintech with PKO Bank Polski!</strong> is an innovation platform, developed by one of the largest financial institutions in Central and Eastern Europe. Within Let’s Fintech, the bank cooperates with startups that are ready to run internal and product pilots and to scale their business.
      </p>

      <p>
        Let’s Fintech combines many activities related to the startup environment: not only business cooperation, but also a dedicated R&D infrastructure and CVC fund. Thanks to this, we can offer a lot of cooperation opportunities that enable development and growth of a company.
      </p>
    `,
  },
  section2: {
    heading: `Let’s Fintech with&nbsp;PKO&nbsp;BANK&nbsp;POLSKI!`,
    item1: `Business cooperation with startups`,
    item2: `R&D infrastructure `,
    item3: `CVC fund`,
    item4: `Cooperation with the external ecosystem`,
  },
  section3: {
    button1: {
      button: `Apply!`,
      link: 'https://fintech.pkobp.pl/fintech-en/cooperation-with-startups/apply/',
      text: `Are you willing to cooperate with us?`,
    },
    button2: {
      button: `Contact us`,
      text: `Are you looking for an investment?`,
    },
    heading: `Let’s cooperate!`,
    item1: {
      heading: `500+`,
      text: `applications`,
    },
    item2: {
      heading: `10`,
      text: `conducted pilots`,
    },
    item3: {
      heading: `12`,
      text: `implemented solutions`,
    },
    text: `We recruit startups in an open application process, and offer 3 consecutive, but independent cooperation models: <strong>1)</strong> Proof of Concept (POC), <strong>2)</strong> commercial implementation, and <strong>3)</strong> equity investment.`,
  },
  section4: {
    text: `
      <p>
        PKO Bank Polski has been involved in cooperation with startups for almost 5 years. During this time, together with startups, we carried out the largest implementation of blockchain technology in Europe, developed our open banking and built up an offer of value-added services for SME clients. In the Let’s Fintech programme, we have an individual approach to each startup and we adapt to the specifics of a given solution, which allows us to optimize the cooperation process. Additionally, we are proactive and engage in various initiatives supporting the innovation ecosystem - one of them is the “StartUp the Bank” Report.
      </p>
      <p>
        <em><strong>Grzegorz Pawlicki</strong> Head of Innovation, PKO Bank Polski</em>
      </p>
    `,
  },
  section5: {
    heading: `Our awards`,
  },
  section6: {
    heading: `Let’s Fintech Team`,
    text: `
      <p>
        If you decide to cooperate with us, our team will be your guide inside the bank structures. We will help you find a business owner who will implement your solution, and create a dedicated project team. However, if you are looking for financing, our CVC team will analyze your startup in terms of an investment.
      </p>
      <p>
        As Let’s Fintech, we combine business and technology competencies - our team consists of experienced bankers, IT experts, consulting veterans and ex startups employees. Thanks to this, we will look at your solution thoroughly. Moreover we will involve the best internal experts, who support us in introducing innovations in all areas of the PKO capital group.
      </p>
    `,
  },
};

import React from 'react';
import styled from 'styled-components';
import PopupType2 from './PopupType2';
import fonts from '../../utils/fonts';
import breakpoints from '../../utils/breakpoints';
import { darken } from 'polished';
import { FaFacebookSquare, FaLinkedin, FaTwitter } from 'react-icons/all';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { useIntl } from 'react-intl';

const PopupSeparator = styled.div`
  background: linear-gradient(to right, #326dad 0, rgba(255, 255, 255, 0) 100%);
  height: 0.0625rem;
  margin: 1.875rem 0;
  max-width: 25.625rem;
`;

const PopupSubmit = styled.span`
  align-items: center;
  background: #e42229;
  border-radius: 0.5rem 0 0.5rem 0;
  color: #f9f9f9;
  cursor: pointer;
  display: flex;
  font-size: 1.3125rem;
  height: 3.125rem;
  justify-content: center;
  margin-right: 1.875rem;
  outline: none;
  width: 3.125rem;

  @media ${breakpoints.lg} {
    font-size: 1.875rem;
    height: 3.75rem;
    padding: 0 1.5625rem;
    width: auto;
  }

  &:hover {
    background: ${darken(0.1, '#E42229')};
  }

  span {
    color: #ffffff;
    display: none;
    font-family: ${fonts.barlowCondensed};
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.375rem;
    margin-right: 1.25rem;

    @media ${breakpoints.lg} {
      display: block;
    }
  }
`;

const PopupBottom = styled.div`
  display: flex;
`;

const SharePopup = props => {
  const locale = useIntl().locale;
  const localeSuffix = locale === 'en' ? 'en/' : '';
  const path =
    typeof location !== 'undefined'
      ? location.protocol + '//' + location.host + '/' + localeSuffix
      : '';

  return (
    <PopupType2 {...props} mobileWidth={335}>
      <PopupSeparator />

      <PopupBottom>
        <FacebookShareButton url={path}>
          <PopupSubmit>
            <span>facebook</span>

            <FaFacebookSquare />
          </PopupSubmit>
        </FacebookShareButton>

        <LinkedinShareButton url={path}>
          <PopupSubmit>
            <span>linkedin</span>

            <FaLinkedin />
          </PopupSubmit>
        </LinkedinShareButton>

        <TwitterShareButton url={path}>
          <PopupSubmit>
            <span>twitter</span>

            <FaTwitter />
          </PopupSubmit>
        </TwitterShareButton>
      </PopupBottom>
    </PopupType2>
  );
};

export default SharePopup;

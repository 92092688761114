export default {
  xs: 'only screen and (min-width: 0px)',
  sm: 'only screen and (min-width: 576px)',
  toMd: 'only screen and (max-width: 767px)',
  md: 'only screen and (min-width: 768px)',
  toLg: 'only screen and (max-width: 991px)',
  lg: 'only screen and (min-width: 992px)',
  xl: 'only screen and (min-width: 1280px)',
  xxl: 'only screen and (min-width: 1366px)',
  hidpi: 'only screen and (min-width: 1440px)',
  desktop: 'only screen and (min-width: 1600px)',
  ie: '(-ms-high-contrast: none), (-ms-high-contrast: active)',
};

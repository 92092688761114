import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

function Head({ description, lang, meta, keywords, title }) {
  const image = `https://startupthebank.pl/assets/${lang}/startupthebank.png`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          property: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        },
      ].concat(meta || [])}
    >
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/object-fit-images/3.2.4/ofi.min.js"
        integrity="sha512-7taFZYSf0eAWyi1UvMzNrBoPVuvLU7KX6h10e4AzyHVnPjzuxeGWbXYX+ED9zXVVq+r9Xox5WqvABACBSCevmg=="
        crossOrigin="anonymous"
        async
      />
    </Helmet>
  );
}

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Head;

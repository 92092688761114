import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import breakpoints from '../../utils/breakpoints';
import fonts from '../../utils/fonts';

const HeadingContainer = styled.div`
  margin-bottom: 3.75rem;

  @media ${breakpoints.lg} {
    align-items: center;
    display: flex;
    position: relative;
  }

  ${props => props.line && `
    &::after {
      background-color: #E42229;
      content: '';
      display: block;
      height: 0.5rem;
      width: 3.25rem;

      @media ${breakpoints.lg} {
        bottom: 0;
        left: 0.1875rem;
        position: absolute;
      }
    }
  `}
`;

const HeadingIcon = styled.div`
  color: #D9D9D9;
  font-size: 2.625rem;

  @media ${breakpoints.lg} {
    font-size: 3.75rem;
    margin-right: 2.8125rem;
  }
`;

const HeadingTitle = styled.h1`
  color: #000010;
  font-family: ${fonts.pko};
  font-size: 2.5rem;
  line-height: 2.8125rem;
  margin: 0.625rem 0 1.5rem;

  @media ${breakpoints.lg} {
    font-size: 4.375rem;
    line-height: 5rem;
    margin: 0;
  }
`;

const Heading = props => (
  <HeadingContainer line={props.line !== undefined ? props.line : true}>
    {props.icon && (
      <HeadingIcon>
        {props.icon}
      </HeadingIcon>
    )}

    <HeadingTitle>
      <FormattedMessage id={props.title} />
    </HeadingTitle>
  </HeadingContainer>
);

export default Heading;

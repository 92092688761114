import React from 'react';
import logoTheHeart from '../../images/logo-the-heart.svg';

const LogoTheHeart = props => {
  return (
    <img src={logoTheHeart} alt="The Heart logo" {...props} />
  );
};

export default LogoTheHeart;

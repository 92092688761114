import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaBars } from 'react-icons/all';
import styled, { css } from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import {
  acceleratedEasing,
  containerStyles,
  primaryBoxButtonStyles,
} from '../../utils/styles';
import ButtonBox from '../atoms/ButtonBox';
import LogoPKOFintech from '../images/LogoPKOFintech';
import ShareButton from '../molecules/ShareButton';
import Menu from '../organisms/Menu';
import LogoPKOMobile from '../images/LogoPKOMobile';
import { HeaderTypes } from '../../utils/HeaderTypes';
import { Link } from 'gatsby';
import { getRoute } from '../../utils/router';
import { useIntl } from 'react-intl';
import onlyLarge from '../../utils/onlyLarge';
import onlyMobile from '../../utils/onlyMobile';
import ChangeLanguageButton from '../molecules/ChangeLanguageButton';

const HeaderWrapper = styled.div`
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 90;

  ${({ detached, hide }) =>
    css`
      transition: transform 200ms ${acceleratedEasing},
        height 200ms ${acceleratedEasing}, opacity 200ms ${acceleratedEasing};
      height: 6rem;
      display: flex;
      align-items: center;

      @media ${breakpoints.md} {
        height: 9rem;
      }

      ${detached &&
        css`
          height: 4rem;

          @media ${breakpoints.md} {
            height: 6rem;
          }

          @media ${breakpoints.lg} and (min-height: 650px) {
            height: 7rem;
          }
        `};

      ${hide &&
        css`
          opacity: 0;
          transform: translateY(-100%);
        `};
    `};

  &::after {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 16, 0.08) 0%,
      rgba(0, 0, 0, 0) 2.5rem
    );
    content: '';
    height: 40px;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
  }

  ${({ type }) =>
    type === HeaderTypes.SUBPAGE &&
    css`
      border-top: 4px solid #ededed;

      & > .progress-line {
        background-color: #004a99;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        top: -4px;
        transform: ;
        transition: transform 150ms linear;
        width: 100%;
      }

      @media ${breakpoints.md} {
        border-top-width: 8px;

        & > .progress-line {
          height: 8px;
          top: -8px;
        }
      }
    `};
`;

const StyledHeader = styled.header`
  ${containerStyles};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 0;
  padding-left: 15px;
  padding-right: 15px;

  @media ${breakpoints.lg} {
    max-width: 950px;
    flex-basis: 950px;
  }

  @media ${breakpoints.xl} and (min-height: 650px) {
    max-width: 1000px;
    flex-basis: 1000px;
  }

  @media ${breakpoints.xxl} and (min-height: 650px) {
    max-width: 1050px;
    flex-basis: 1050px;
  }

  @media ${breakpoints.hidpi} {
    max-width: 1180px;
    flex-basis: 1180px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-right: calc(-0.5rem - 0.3125rem);
`;

const StyledChangeLanguageButton = styled(ChangeLanguageButton)`
  width: 2rem;
  height: 2rem;
  font-size: 1.25rem;
  margin: 0 5px;
  padding: 0.375rem;

  @media ${breakpoints.md} {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.625rem;
    margin: 0 5px;
    padding: 0.5rem;
  }
`;

const StyledShareButton = styled(ShareButton)`
  width: 2rem;
  height: 2rem;
  font-size: 1.25rem;
  margin: 0 5px;
  padding: 0.375rem;

  @media ${breakpoints.md} {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.625rem;
    margin: 0 5px;
    padding: 0.5rem;
  }
`;

const Button = styled(ButtonBox)`
  display: flex;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  font-size: 1.25rem;
  margin: 0 5px;
  padding: 0.375rem;

  @media ${breakpoints.md} {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.625rem;
    margin: 0 5px;
    padding: 0.5rem;
  }
`;

const StyledLink = styled(Link)`
  ${primaryBoxButtonStyles};

  display: flex;
  justify-content: center;
  height: 2.75rem;
  width: 2.75rem;
  font-size: 1.625rem;
  padding: 0;
  margin: 0 10px;

  @media ${breakpoints.md} {
    padding: 0;
    margin: 0 40px;

    &:first-child {
      margin-left: 20px;
    }

    &:last-child {
      margin-right: 20px;
    }
  }
`;

const LogoContainer = styled(Link)`
  margin-right: auto;
  max-width: 100%;
  transform-origin: left center;
  transition: transform 200ms ${acceleratedEasing};

  ${({ detached }) =>
    detached &&
    css`
      transform: scale(0.75);
    `};
`;

const HeaderPlaceholder = styled.div`
  height: 6rem;

  @media ${breakpoints.md} {
    height: 9rem;
  }
`;

const LogoPKOFintechLarge = styled(onlyLarge(LogoPKOFintech))`
  max-width: 100%;
  height: auto;
`;

const LogoPKOFintechSmall = styled(onlyMobile(LogoPKOFintech))`
  width: 10rem;
  height: auto;
`;

const LogoPKOSmall = styled(onlyMobile(LogoPKOMobile))`
  width: 2.1875rem;
  height: auto;
`;

const callbackIds = {};

const Header = ({ type }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [percentageScrollPosition, setPercentageScrollPosition] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollingDown, setScrollingDown] = useState(false);
  const intl = useIntl();

  const calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset;
    const winHeight = window.innerHeight;
    const docHeight = getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    setPercentageScrollPosition(
      Math.floor((scrollTop / totalDocScrollLength) * 100)
    );
  };

  const getDocHeight = () =>
    Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

  const observeScrollTop = uuid => {
    requestAnimationFrame(() => {
      calculateScrollDistance();

      if (callbackIds[uuid]) {
        observeScrollTop(uuid);
      }
    });
  };

  useEffect(() => {
    const uuid = +new Date();

    let prevRelativeScrollTop = null;

    const listenToScroll = () => {
      const scrollTop = window.pageYOffset;
      setScrollPosition(scrollTop);
      const relativeScrollTop =
        scrollTop / (getDocHeight() - window.innerHeight);

      if (prevRelativeScrollTop == null) {
        prevRelativeScrollTop = relativeScrollTop;
        return;
      }

      if (relativeScrollTop > prevRelativeScrollTop + 0.04 && scrollTop > 400) {
        setScrollingDown(true);
        prevRelativeScrollTop = relativeScrollTop;
      } else if (
        relativeScrollTop < prevRelativeScrollTop - 0.04 ||
        scrollTop < 400
      ) {
        setScrollingDown(false);
        prevRelativeScrollTop = relativeScrollTop;
      }
    };

    window.addEventListener('scroll', listenToScroll);
    if (type === HeaderTypes.SUBPAGE) {
      callbackIds[uuid] = true;
      observeScrollTop(uuid);
    }

    return () => {
      delete callbackIds[uuid];
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  return (
    <>
      <HeaderPlaceholder type={type}>
        <HeaderWrapper
          type={type}
          detached={scrollPosition > 200}
          style={{
            backgroundColor:
              type === HeaderTypes.HOMEPAGE
                ? `rgba(255, 255, 255, ${Math.min(1, scrollPosition / 150)})`
                : 'white',
          }}
          hide={type === HeaderTypes.HOMEPAGE && scrollingDown}
        >
          {type === HeaderTypes.SUBPAGE && (
            <div
              className="progress-line"
              style={{
                transform: `translateX(${-100 + percentageScrollPosition}%)`,
              }}
            />
          )}
          <StyledHeader type={type}>
            <LogoContainer
              type={type}
              to={getRoute('ComponentHome', intl)}
              detached={scrollPosition > 200}
            >
              <LogoPKOFintechLarge />
              {type === HeaderTypes.HOMEPAGE ? (
                <LogoPKOFintechSmall />
              ) : (
                <LogoPKOSmall />
              )}
            </LogoContainer>

            <Buttons>
              {type === HeaderTypes.SUBPAGE && (
                <StyledLink transparent to={getRoute('ComponentHome', intl)}>
                  <FaArrowLeft />
                </StyledLink>
              )}

              <StyledChangeLanguageButton />
              <StyledShareButton transparent simple />
              <Button transparent onClick={() => setMenuOpen(true)}>
                <FaBars />
              </Button>
            </Buttons>
          </StyledHeader>
        </HeaderWrapper>
      </HeaderPlaceholder>
      {menuOpen && <Menu close={() => setMenuOpen(false)} />}
    </>
  );
};

export default Header;

import { darken, lighten } from 'polished';
import React from 'react';
import { FaTimes } from 'react-icons/all';
import styled from 'styled-components';
import IconButtonBox from '../atoms/IconButtonBox';
import breakpoints from '../../utils/breakpoints';
import ButtonBox from '../atoms/ButtonBox';

const Button = styled(ButtonBox)`
  background-color: #195ca3;
  border-radius: 0;
  width: 3.25rem;
  height: 3.25rem;
  color: white;
  padding: 0.5rem;

  &:hover {
    background-color: ${lighten(0.1, '#195ca3')};
  }

  &:active {
    background-color: ${darken(0.1, '#195ca3')};
  }

  @media ${breakpoints.md} {
    width: 3.75rem;
    height: 3.75rem;
    padding: 0.5rem;
  }
`;

const StyledFaTimes = styled(FaTimes)`
  font-size: 2rem;

  @media ${breakpoints.md} {
    font-size: 2.25rem;
  }
`;

const CloseButton = props => (
  <Button {...props}>
    <StyledFaTimes />
  </Button>
);

export default CloseButton;

export default {
  sharePopupHeader: 'Share the Report',
  nav: [
    `Intro`,
    `Research results`,
    `Expert Opinions`,
    `Startup Interviews`,
    `Startup wishlist`,
    `Founders tips`,
    `Check 100 startups`,
    `Authors`,
  ],
  welcomeSection: {
    peak: `Startups that transform Polish banking`,
  },
  informationSection: {
    id: `research-results`,
    header: {
      main: `Did you know that…`,
      subtitle: `Key findings`,
    },
    items: [
      {
        header: `<hlgblue>7</hlgblue><hsmgray>out of</hsmgray><hlggray>10</hlggray><hblack>companies</hblack>`,
        text: `Startups in Poland are increasingly able and willing to work with large financial
institutions, even if they are not classic fintechs: <strong>7 out of 10 companies surveyed by
us already have contracts with corporations in this industry.</strong>`,
      },
      {
        header: `<hlgblue>43</hlgblue><hblack>%</hblack>`,
        text: `Most technology companies in the initial phase of a business relationship focus on
quality, not quantity: <strong>hence 43% of them have a maximum of 3 clients</strong> from the
financial industry.`,
      },
      {
        header: `<hsmgray>one in</hsmgray><hlgblue>4</hlgblue><hblack>young companies</hblack>`,
        text: `At the same time, one in four young companies gain major partners through
<strong>participating in acceleration programs</strong>. Innovation teams are also key. They are
partners to 70 percent of the companies surveyed by us, cooperating with banks.`,
      },
      {
        header: `<hlgblue>70</hlgblue><hsmgray>%</hsmgray><hblack>of pilots</hblack>`,

        text: `<strong>How do you &quot;break into&quot; a bank?</strong> The most effective way is through piloting and
testing. Over 70% of pilots between startups and banks result in new business deals.`,
      },
      {
        header: `<hlgblue>3</hlgblue><hblack>key areas</hblack>`,
        text: `The fact that you don’t have to be a fintech to become a technological partner of a
bank is confirmed by three key implementation areas of Polish startups: <strong>data
analysis, optimisation of internal processes and customer service</strong>.`,
      },
      {
        header: `<hsmgray>What</hsmgray><hlgblue>?</hlgblue><hblack>technologies</hblack>`,
        text: `What technologies do startups focus on? Mostly <strong>API and mobile</strong>, but also the less
popular blockchain.`,
      },
      {
        header: `<hlgblue>48</hlgblue><hsmgray>%</hsmgray><hblack>responses</hblack>`,
        text: `New customer billing models are a breath of fresh air, where revenue sharing (<strong>used
by nearly one in four young companies</strong>) has a noticeable position, as does
subscription (<strong>48% of responses</strong>).`,
      },
      {
        header: `<hblack>Over</hblack><hlgblue>40</hlgblue><hsmgray>%</hsmgray>`,
        text: `New solutions are popping up everywhere – the startup leaders surveyed by us who
work with the financial industry, believe that the best is yet to come. After all, over <strong>40
percent of them believe that their solution is a &quot;must have&quot; for banks</strong>.`,
      },
      {
        header: `<hlgblue>&amp;</hlgblue><hsmgray>facing</hsmgray><hblack>challenges</hblack>`,
        text: `On the other hand, companies stress that the path to greatness in finance also
means facing challenges related to the <strong>long decision-making process and the
complex organisational structure of banks</strong>, as well as barriers resulting from high
requirements and complicated procedures prevailing in financial corporations.`,
      },
      {
        header: `<hlgblue>17</hlgblue><hsmgray>%</hsmgray><hblack>startups</hblack>`,
        text: `Why is it worth taking this journey? Only 17% of companies mention direct financial
benefits. More important are <strong>market recognition or access to the customer base and
feedback</strong> from a professional partner. The conclusion is obvious: Polish startups
working with the financial industry are already much more mature than their age
would indicate.`,
      },
    ],
  },
  expertsSection: {
    id: `expert-opinions`,
    header: `What experts say…`,
    items: [
      {
        title: `Global expansion – a challenge for Polish startups`,
        authors: [`Michał Kramarz`],
        company: `Google for Startups`,
        excerpt: `Everyone is talking about the “very high potential” of Central and Eastern Europe, noting that Poland has a powerful position there.`,
        intro: `We are already known in the world for our great talents and technological skills. There is plenty of evidence to confirm this: Polish developers have been recognised for years of work on the most advanced technologies in international companies that have their research centres in Poland, in top local IT companies, but also in startups. However, in the case of the latter, talent and skill are just some of the many components of the success achieved on global markets. It is clear we have a lot of work ahead of us. Combining technology with business, project management and the ability to build diverse teams is still a challenge for many Polish startups. We see this every day working on initiatives at Google to help these type of companies grow. These are the kind of barriers Polish startups face for the most part.`,
        sections: [
          {
            title: `Prescription for gaining foreign clients`,
            content: `A technologically perfect product is not the only key factor when it comes to thinking globally about a business. It is also developing a company based on a diverse team, various perspectives and experiences, which helps our product be much more versatile. This is why, for example, in the Google for Startups community in Warsaw, 35% of members are women and 32% are foreigners. We believe that the diversity of the teams that create startups is an essential driving force for new businesses. Another important component is choosing the markets for the startups to offer their products or services. The closest or best-known markets, such as the EU or the US, are not always the perfect solution. It is worth looking at a larger picture, such as the much more dynamic markets in Asia and South America.
According to studies carried out for the purpose of this report, as many as 56% of Polish startups offer their services and products on foreign markets. In this context, we are pleased to see such countries as the United Arab Emirates and Singapore in the list of TOP 10 markets. Let us hope that this expansion to various markets will increase, as these markets usually offer huge potential and lower barriers to entry.`,
          },
          {
            title: `Co-investing in experience`,
            content: `Finally, let us talk about financing the expansion. Despite the visible appetite of Polish startups for development abroad, as many as 82% of them declare they are financed mainly by the Polish capital. This means that our market is still at an early stage of development in terms of investments. Why is that so? Because we lack investor experience in scaling up businesses internationally. Our investment market (in particular its private sector) is still in the process of creating its portfolio. In order to make the leap to the global level (and take the startups along), investors and entrepreneurs should strive for co-investment between Polish VC funds and foreign funds. This is, in fact, part of the mission of Google for Startups in Poland. We are convinced that such investments will provide the Polish market with access to the experience and networks of contacts from attractive markets. Ultimately, this will allow us to make an even better use of the huge technological potential of startups in Poland.`,
          },
        ],
      },
      {
        title: `Venture Building, a common language for banks and innovators`,
        authors: [`Bartosz Sawicki`],
        company: `The Heart`,
        excerpt: `Corporations show great pride in excellent processes that minimise their risks and increase
efficiency. They would also like to put startups into this bureaucratic machine, but those
businesses rarely fit into it.`,
        intro: `Figures confirm this: a study carried out by PKO Bank Polski
reveals a number of problems that young companies face when trying to work directly with a
large corporation. The decision-making process itself is deemed “very long” by nearly half of
respondents. Other significant obstacles in such projects include bureaucracy, growing legal
barriers, technological backwardness and lack of trust on the part of large companies. So,
should corporations change their attitude?`,
        sections: [
          {
            title: `Energy and need`,
            content: `From the viewpoint of the best corporations in Poland and the world, this change is in fact
already taking place. Large companies have created dedicated expert units that serve as the
first contact for building business relationships with startups. Some large players simply
invest capital in the most interesting solutions. What can they do, however if a single startup
solution is not enough and a large financial institution needs a complex service based on a
multi-stage process that requires several different technologies provided by many
businesses? That’s when things start to get complicated. In such cases, it is virtually
impossible to convince all the major players in the corporation that such complex
implementations could be done on their own. Ordering such projects from external IT
companies sounds tempting, but in reality, it is not that easy because not many people are
willing to make that decision on the part of a large company. Managers do not want to be
fully accountable for possible failure or money spent on such initiatives. This is why
innovation departments and innovators have to implement such remuneration models as
revenue share or data monetisation. This is indicated by more than 30% of companies
surveyed in this report. However, large companies have an endless need for tailor-made
innovations, while startups are hungry for powerful partners.`,
          },
          {
            title: `Pit stops`,
            content: `Are there any other working alternatives? It turns out that the two worlds can find common
ground, not only through the innovation teams in banks or business development
departments. One way to deliver projects to corporations at the best possible price, time and
security level is Venture Building. In this model, it is the external partner that builds a startup
for the corporation, providing the necessary methodological background, organisational
resources or contacts with other technology companies. It acts like a team in Formula 1 pit
stops: it takes professional actions on behalf of small, agile founding teams. Soon, the first
projects of this type in Poland will make their debut in corporations, with the support of
corporate teams. In the near future, they may become an interesting investment option for
CVC funds. Of course, this is not the only right way to do it. Every startup and every large
company have to identify their best method of collaboration themselves. Their shared
mission, however, must be a belief that innovators and banks should not only stick to their
respective attitudes and give up available tools and mechanisms that could bring them
together.`,
          },
        ],
      },
      {
        title: `Cloud solutions in the Polish startup ecosystem`,
        authors: [`Michał Potoczek`],
        company: `Domestic Cloud Provider (OChK)`,
        excerpt: `The financial sector, especially banking, is one of the industries most open to innovation in Poland. New technologies in banking can be even more accessible and widespread thanks to cloud computing.`,
        intro: `In the past, banks used cloud to a moderate extent, but this trend is clearly changing. After all, it was PKO Bank Polski that came up with the idea of creating the Domestic Cloud Provider, and it resulted from the analysis of their needs. In an increasingly digital world, instead of taking part in an unequal fight with the technological tycoons to win customers, it is better to establish collaboration with such players and reach for the tools they use, additionally involving the energy and ideas of financial startups in the process. McKinsey’s analyses show that the vast majority of startups do not want to build their own infrastructure or implement software in the conventional model, but it is cloud computing that is their first, natural choice. As many as 92% of startups declare that they use cloud solutions.`,
        sections: [
          {
            title: `Regulations vs. cloud solutions`,
            content: `Until recently, possibilities of using cloud technologies have been very limited for banks, mainly due to applicable regulations. However, the situation is changing rapidly, and financial supervision regulators are opening up to digital banking solutions. The support of the key regulator, already noticeable on the market, may definitely accelerate the digital transformation of the sector. The Domestic Cloud also plays an important role in this processm as  the only one that ensures full regulatory compliance with requirements imposed by the Polish law and relevant institutions. This guarantee allows for faster and easier development of digital services in such sensitive sectors as banking.`,
          },
          {
            title: `Recipe by the Cloud`,
            content: `In this context, the Domestic Cloud has found a recipe to connect the world of corporations and that of startups by building dedicated solutions for both groups. These may include developer environments to build and verify new startup solutions for banks (this happens, for example as part of “Let’s fintech with PKO Bank Polski!”). Thanks to such platforms, startups can build and test software within the existing IT infrastructure. OChK is also planning to launch dedicated sandboxes: the first project of this kind is to take place in 2020 and  will be focused on blockchain technology. It will be a cloud-hosted network that will enable different partners (including startups) to work on the same platform without having to bear large costs in order to purchase the blockchain infrastructure. This will also allow the creation and testing of services, systems or products with the use of this technology.`,
          },
          {
            title: `Solutions for startups`,
            content: `Thanks to the cloud, young companies do not need to pay for expensive IT infrastructure in order to be able to provide services to millions of bank customers who want to cooperate with them. We can also provide startups with quick and easy access to state-of-the-art technology used by both large companies and the fastest growing startups around the world. We offer both physical infrastructure and application platforms, including Google Cloud solutions. These are advanced technologies, products and analytical tools based on leading technical infrastructure, providing computing power and data storage services. They include Compute Engine, App Engine, Google Kubernetes Engine, Cloud Bigtable, Cloud Spanner and BigQuery. What else can we give to startups that want to grow faster? Not just infrastructure, but also mentoring or a regulatory package. These may also come in handy for young tech companies on the bumpy road to commercialising their business idea. `,
          },
        ],
      },
      {
        title: `CVCs set to conquer Poland`,
        authors: [`Radosław Kwiatkowski`],
        company: `PKO VC`,
        excerpt: `Startup CEOs deal mainly with financing. We constantly have to fight for money, said one of the startup founders from the fintech sector in an interview with the authors of this report.`,
        intro: `Even though at the beginning own capital is still the main source of financing, this hardly isolated opinion shows that investors play a key role in the Polish ecosystem of young technology companies, and that they are ready to take great risks in exchange for the potential of an even greater reward. CVC funds are included in this group and, I believe, the best is yet to come for them. The Polish VC/CVC market is still gathering experience and learning important lessons, already learnt in the United States or Western Europe. This is evidenced by the hard data included in the report: 47% of startups declare that they have received VC and/or CVC financing, but only 29% say that the largest share in their financing is held by this type of investor.`,
        sections: [
          {
            title: `Financing by VC and CVC`,
            content: `At the same time, the deliberate demand for VC/CVC financing in Poland continues to grow. Talks with startups show that companies see an increasing value in picking the right partner for scaling their businesses. They understand perfectly well that the role of VC financing is not only to provide “fuel” for companies at an early stage of development when capital needs and growth potential are high. A capital from a good investor will also bring in smart money: technical support, network of contacts or access to assets or technology. Of course, VC financing in a startup also has a strategic and mental dimension. It requires founders to take a path of dynamic growth at the expense of their shares in the company, assuming a potential exit from the investment together with the VC investor.`,
          },
          {
            title: `Structure of collaboration and investment`,
            content: `In this context, it is not accidental that a growing number of CVCs are trying to conquer the market. CVC is a well-thought-out structure of collaboration and investment between a large corporation and a startup ecosystem. This is one of several parallel rapid growth paths (next to, for example, accelerators). A good CVC fund ensures that the investment process, contractual conditions and subsequent collaboration from the startup’s point of view follow the best known practices of VC funds. In the case of PKO Bank Polski Group, we can, as a separate and independent unit, make investment decisions quickly. We also focus on greater flexibility with regard to the period and size of investments, subsequent rounds and help in synergies with the bank. The latter is a key advantage of a CVC. Every startup that focuses on the financial sector would prefer a simplified path in the process of commercial implementation, for example in the largest banking group in Poland. In this model, PKO VC with Alior Bank, BNP Paribas and two local VC funds have recently invested in Autenti, a Polish fintech offering a platform for e-signing contracts and digital document circulation. This is the first transaction of its kind for banks in the CEE region, additionally demonstrating their ability to take unique steps in our market.`,
          },
          {
            title: `CVCs in Poland`,
            content: `So, what are banking CVCs looking for in Poland? They are willing to invest in strategic projects from the fintech and insurtech segments that translate directly into potential implementations in the investor’s structures. However, as with conventional VCs, they also have projects from other segments on their radar, such as marketplace, SaaS or martech, which have no direct potential in reference to synergies with the bank. Importantly, in both cases, in addition to conventional foundations such as market, product, customers, etc., an investment decision is significantly affected by another component that quickly gains in importance: the competence, vision and motivation of teams. Wise CVCs invest primarily in people.`,
          },
        ],
      },
      {
        title: `Hungry for innovation and open to startups`,
        authors: [`Joanna Misiewicz`, `Przemek Kondraciuk`],
        company: `PKO Bank Polski`,
        excerpt: `FOMO (fear of missing out) was one of the answers given by a startupper when asked about reasons for the growing openness of the financial sector to young technology companies.`,
        intro: `The report shows that the startupper had a point. The financial industry in Poland is hungry for innovation like no other. However, this appetite is well-thought-out and supported by a range of effective tools. This is confirmed by figures. Startups from Poland appreciate banks’ activities with regards to dedicated solutions. 25% of companies working with banks indicate that they sell their solutions to financial institutions through special collaboration programmes with start-ups. Moreover, 70% of companies declare that, in the finance industry, they most often work with corporate innovation teams. This is a standard for innovative banks. Why? Because the very nature of these institutions means that there are many challenges to implementing startup solutions.`,
        sections: [
          {
            title: `Tailor-made collaboration`,
            content: `“Our” (banks’) biggest sin is the long decision-making process. The high degree of market regulation and the resulting high requirements set for external partners are also important. All this makes any collaboration with a startup a mix of business, legal and cybersecurity expectations. Therefore, the role of innovation teams that act as a guide around banking intricacies and complicated structure of the organisation is becoming increasingly important, as thanks to them both sides speak the same language in the end. These people are also, in a way, the startup’s first sales team in a bank. Implementing innovations with startups forces banks to accept a unique and new approach – one that is usually tailor-made. Results? As many as 87% of startups cooperating with  financial institutions have completed at least one pilot project in a bank. This path to a dedicated process for startups was built at PKO Bank Polski, by creating a partnership programme called “Let's Fintech with PKO Bank Polski!” We take on the role of a guide, translator and business matchmaker for young technology companies that start working with us, enabling them to test and pilot their solutions together with the bank.`,
          },
          {
            title: `A startup must-have`,
            content: `What do startups think and say when they enter into business contacts with banks that are overcome by FOMO? They increasingly understand their own importance to the financial system, and they know their own value. Over 40% of companies working with banks even believe that their solutions are must-haves for the financial market. Their teams understand well enough that banks no longer compete only with each other. The pressure from GAFA, challengers or startups is changing banks from inside and out. Signs of this transformation include developing partnerships with technology companies. This collaboration most often takes place in the B2B model. The study shows that it most often concerns such areas as data analysis, internal process optimisation and customer service. Establishing a strategic partnership with such companies is a much more difficult decision, at least for banks. It often concerns startups operating in the B2C model. For a bank, this means it has to give up, in a way, direct relationships with the client, especially if the collaboration involves their core business (for example keeping accounts or granting loans).`,
          },
          {
            title: `The more you have, the more you want`,
            content: `According to startups, in reality collaboration between large and small businesses is a difficult path, but not many people abandon it. Benefits of such a banking/startup pairing are clear to both sides. Banks can innovate faster in areas where they have no experience. While the “young and bold ” appreciate not just money, as the main benefit of collaboration with banks, but also the credibility and testimonials they gain. If a bank trusted them, the market should as well.`,
          },
        ],
      },
      {
        title: `Towards better law`,
        authors: [`Piotr Brewiński`, `Paweł Widawski`],
        company: `Fintech Poland`,
        excerpt: `Can the law stimulate the development of financial startups in Poland? The answer to this
question is unclear, although, as a rule, market representatives treat legal regulations as
an obstacle.`,
        intro: `One of the startup representatives interviewed for the purpose of this report summarises:
        Certain Polish regulations are extremely strange and very demanding, reducing the market
openness to new technologies. Hence, some fintech components practised in the US, Israel or
Asia virtually cannot be used here due to legal constrains,. Other representatives of the
industry agree with the statement, pointing out that their innovative activity often goes
beyond the commonly applicable legal frameworks, and if something is not clearly defined, it
is categorised as the “grey regulatory area.” This is also confirmed by figures: 45% of the
startups included in the survey perceive legal regulations as one of the three main obstacles
to their activity.`,
        sections: [
          {
            title: `Legal challenges vs. success of the projects`,
            content: `Frequent regulatory changes directly translate into opportunities of a project to be
successful. This directly affects investors’ risk assessment and, thus, the possibility of
obtaining capital from them. Examples? The famous GDPR addresses, on one hand, an
important need to protect consumers’ personal data but, on the other, introduces
restrictions unknown to financial markets outside of the European Union. Results? The
absence of restrictive regulations in the field of data processing, with all the risks involved,
has become the basis for the rapid growth of financial innovations e.g. in Asia, providing
them with a significant competitive advantage. From the viewpoint of the projects
implemented in Poland, there is also the matter of the so-called gold plating, i.e. imposing
requirements on Polish financial service providers that go beyond the ones resulting from
European regulations. In such cases, if a startup already gets its chance in a corporation, the
need for an in-depth analysis of effects of the cooperation and potential legal risks prolongs
the decision-making process on the part of large financial institutions. This is indicated by as
much as 41% of the surveyed startups.`,
          },
          {
            title: `Market development`,
            content: `When complaining about the regulatory straitjacket, we cannot ignore the evidence that,
objectively speaking, regulations can indeed stimulate market development. Take PSD2, for
example, in its part that concerns the opening of the access to payment accounts to third
parties. Due to their open nature, these regulations stimulate market development by
fostering competitiveness. After all, this is the best possible fuel for startups. Results?
Despite the numerous legal challenges that the fintech market faces on a daily basis,
startups permeate the financial sector completely. They are present in retail banking,
payment services, the capital market and the insurance market. Banks have also learned to
cooperate with startups to efficiently address potential legal risks, and their accelerators are
now becoming important centres of development for the financial sector at the
technological and business level.`,
          },
        ],
      },
    ],
  },
  startupDialogSection: {
    id: `startup-interviews`,
    header: `What startups say…`,
    items: [
      {
        title: `Revolution is driving itself`,
        subtitle: `Interview with Tomasz Plata`,
        author: `Tomasz Plata`,
        company: `Autenti`,
        questions: [
          {
            question: `Autenti is like a dinosaur of the fintech world.`,
            answer: `We were indeed established in 2012 before the fintech boom in Poland. Autenti’s founder and originator is Grzegorz Wójcik whom I have known for two decades. It was Grzegorz who, while still working for a corporation, said that we needed to effectively make the business paperless. It was the time when the paperless idea was becoming extremely successful on the other side of the ocean. In Poland, instead, we were stuck with printers and “wet ink” signatures. Grzegorz believed in an electronic signature and in the increasing trust of digital documents. So, he brought together a few reliable managers for the Autenti project. In 2014, we launched our platform on the market in the proof-of-concept version.`,
          },
          {
            question: `What makes Autenti unique?`,
            answer: `We have built an online document signing platform that links technology, legal regulations and one other element that is of key importance to us: innovation. A person sends a document electronically using Autenti, so that its recipient (for example a consumer, a business partner or co-worker) can receive it on their mobile device or a PC and sign it. This is easy, quick and, above all, effective. All these activities are performed electronically. They also follow our corporate slogan: “one click e-signature,” as signing is supposed to be as easy as one click.`,
          },
          {
            question: `In 2012, such documentation management seemed a long way off. Did you feel you were risking a lot?`,
            answer: `Technically, up until 2016, we were not fully devoted to Autenti because we were still working in other organisations or had our own businesses. It was still a test or an experiment. We had a test version, handled the related marketing and were educating the market. Finally, in 2016, we saw positive developments in national and European legislation on electronic signatures. Additionally, which was somewhat unexpected, we won the 2016 “Great Pitch Contest” and the prize of USD 100,000 at the Wolves Summit conference. Soon after, our project (worth several million zlotys) received a huge NCRD grant. In an instant, we decided that it is sink or swim time. We became involved in Autenti 100%. In early 2017, we speeded things up, built a team and obtained key competences. The process was in full swing.`,
          },
          {
            question: `Did you get clients at that time?`,
            answer: `Yes, but before that happened, we had a lot of work, including doing our homework and establishing contacts thanks to such accelerators as PwC Startup Collider or Google Campus Warsaw Residency. We started to use their knowledge, and we presented our solution to key managers from the financial market, among other things. We successfully participated in the BNP Paribas hackathon. We were noticed at this event and, after a few months, we were invited to a tender for the implementation of the solution in one of the bank’s departments. We were awarded the contract, and a recognised player from the very demanding banking market validated our product by paying for it and using it every day.`,
          },
          {
            question: `Do you focus on large corporate clients only?`,
            answer: `We have both feet on the ground: SMEs are naturally good clients for us as well. Of course, a large bank means thousands of documents, potential implementations in successive segments of the bank or its capital group, excellent testimonials attracting other companies from the industry, and international prospects. But, at the same time, this has to be taken care of and processed (often in several scenarios) utilising the above-mentioned three factors. A large client also requires personalisation, which takes time and money. So, it is also worth looking kindly on smaller companies that pay less, but are ready to accept a 100% SaaS solution. In their case, the decision-making process is counted in hours rather than in months.`,
          },
          {
            question: `You mentioned that it took you a long time to become 100% engaged in Autenti. Why the initial hesitation?`,
            answer: `In 2014, we were ready with the product and launched it on the market, even giving it away for free during the test period. We heard a lot of “buts” including the one rooted in the mentality: we do not need this, we are managing without it by printing everything on paper. So, the initial confrontation was painful. We were unsure if the market was ready for our service. One of the clients, probably wanting to upset me while discussing Autenti, talked to me in a room full of printers. We realised that despite our work on the product and high level of excitement, we were still halfway there. However, we decided to try another way to find the key to open doors to corporations. The first step was focusing on the benefits and raising awareness of the risks associated with the use of printed documents.`,
          },
          {
            question: `What was the second one?`,
            answer: `It was certainly not a low price, as it is difficult to fight old habits only with the price. Once we won a client over to our idea, we had to find a person in the organisation who would fight for implementing it and would bring it to the company himself. We have learned to find such “evangelists of change” in organisations. They would deal with sales operations, contract flow, and document preparation at the same time. And we hit the jackpot. Today, we increasingly often start our relations with large clients from small steps that are safe for the organisation, i.e. with PoC (pilot implementation). It is over very quickly, within 2 or 3 weeks, for example in the B2E (business to employee) area involving HR documents. We encourage a bank to test the solution first on its employees or co-workers because they need to be hired, given clearance slips, tax returns, submit holiday requests, etc.`,
          },
          {
            question: `So, you are starting with a small step, but you are in fact ready for a bigger one, is that right?`,
            answer: `Yes, but we stay humble. Working with a large partner has taught us that we have to give it 110% if we want to lose the ‘startup’ label. There is no room for mistakes of youth here. In terms of the process, client approach and product advancement, we are already a mature, dynamic company. If we refer to ourselves as a “startup,” it is only for marketing purposes and in exceptional cases. It is clear to us that no bank will ever sign a contract with Autenti until they are sure we can provide adequate guarantees of security, system availability and effective performance. Our bargaining chip is also our professional experience, the age of the founders and our relations with the market. It is not by pure chance that our clients include Vienna Life, Medicover and Siemens.`,
          },
          {
            question: `Our conversations with other fintechs show that they need banks, but also complain about how such organisations operate: by infinitely delaying processes. Maybe they are unable to win the favour of an evangelist?`,
            answer: `If you have an evangelist, say, in a bank, this does not mean that the implementation will take a week or a month. It only means that you can stimulate the process to an extent, to move it forward. We realise banks want, need and seek innovation. Yet, at the same time, we refer to them as mammoths.`,
          },
          {
            question: `This does not sound as a compliment.`,
            answer: `We do not mean anything bad by it. Mammoths are really big, so they move slowly and carefully. Standard implementation of an IT solution in a bank takes almost six to twelve months. However, once we manage to get their commitment, we go on a big hunt together: to a market with hundreds of thousands or often millions of customers. Although, we still have to go through the ‘roasting’ stage.`,
          },
          {
            question: `This is something like corporate purgatory?`,
            answer: `There is no point in running away from it. It is worth going through it, as winning a large client from the financial industry is an achievement and a precedent. With all due respect to other companies on the market, if a bank has validated me, I know that I am doing good business and then others will consider our services too. Since all legal, compliance and technology departments and even the Polish Financial Supervision Authority (with its list of guidelines) have decided that not only do they want to, but also can use Autenti in a bank, this means we can attack the most difficult fortresses with our heads held high.`,
          },
          {
            question: `Is roasting not a waste of time?`,
            answer: `We have the patience and understanding for this kind of processes. After all, we also worked at corporations. This is how they operate and, often, this is a requirement streamlining future actions. It is important to not focus only on singing a contract with a bank by directing all your forces and resources to this purpose. In a corporation, what you mostly do is wait, and this costs money. Therefore, it is worth diversifying your offer by selling your services to SMEs as well. To this end, you have to prepare yourself for roasting: know the ins and outs of a potential client. We have even impersonated bank customers to see how different paper-based processes worked there even before our presentation.`,
          },
          {
            question: `So, you do not have any extreme requirements when it comes to banks as your clients?`,
            answer: `Technically we do, but we realise this is wishful thinking – we try to be realistic. Certainly, they should be even more open to change and should treat smaller companies as true business partners. After all, for banks, external innovation is an important element of building competitive advantage, optimisation and image improvement. Luckily, we notice a huge progress compared to what we saw even a few months ago. There is an increasing number of the so-called smart nutters in the finance industry who stand firmly behind new technologies. Fintechs usually only need the green light from a corporation, as in: OK, here is some space for a take-off and give us a pilot solution – we are opening our doors. This is enough for a start. Then, the revolution drives itself and spreads to other industries.`,
          },
        ],
      },
      {
        title: `Playing the innovation game together`,
        subtitle: `Interview with Anna Streżyńska`,
        author: `Anna Streżyńska`,
        company: `Carrotspot`,
        questions: [
          {
            question: `There are several technology companies in your group, including Carrotspot. Why have you decided to use technology for HR in this case?`,
            answer: `As with most startups, it was a bit of a coincidence. When a young technology business is first
starting out, its primary task is to find a proven business model that will ensure the most success.
Often, the initial success is simply survival. We realised that we could offer unique services and
that, in addition to PowerPoint, we had experience, specific achievements, big names and a
relationship with the market. And since our group’s software house had previously offered
services involving blockchain, artificial intelligence and IoT, we could develop a more complex
product. So we decided to use Carrotspot for a specific segment: HR at the management
leveland to specialised departments in companies – to everyone who understands the
relationship between the company’s success and the well-being of its employees.`,
          },
          {
            question: `This well-being does not merely mean an advanced version of the salary and bonus calculator, is that right?`,
            answer: `Carrotspot plays in a different league. It was established to counterbalance the well-known
processes for motivating employees, which are rather primitive ways to encourage them to work
more for a while and to achieve higher KPIs. Our technology was created to ensure employees
feel so good about their company that their personal interests match the company’s interests.
Why? Because they feel that the company gives them an opportunity to satisfy their various life
goals, not only to make more money. This is when the perfect mix of loyalty, creativity and
efficiency is awakened in employees. Thus, Carrotspot is a platform that searches for tailor-made
incentive programmes for employees. We first built it with our own employees in mind, hence the
tokenisation, which provides immediate rewards and assigns them to specific tasks. At
Carrotspot, a company can use available resources as rewards, such as a parking space, a
conference room for private use, or an extra day off. However, we place huge emphasis on
intangible forms of appreciation.`,
          },
          {
            question: `Who was the first external client that picked up the idea?`,
            answer: `It was Iwona Wencel, HR Executive at Wirtualna Polska Holding (holding of media and e-
commerce companies). Wirtualna Polska was our first test client, and Iwona Wencel devoted
much of her time to show us the entire mechanics of relations in such a complex company. This
has taken our product to a new level. She was also the first person who would read through our
ideas, and it was her who persuaded us to participate in the HR Tech competition in which we
defeated several dozen other startups and started 3 pilot phases from our no. 1 position. She
helped us a lot.`,
          },
          {
            question: `Do you still work together?`,
            answer: `The company decided to choose a different platform, one with sports benefits. That solution was
fully ready for implementation, while we were only “warming up.” For us, the very fact that
someone so high up in a corporation and so well-versed in this topic was kind enough to devote
her time to us was a success. I believe that, one way or another, we will still work together in the
future.`,
          },
          {
            question: `Does your future include areas outside the Polish market?`,
            answer: `We are starting to penetrate international markets. We have had an opportunity to talk to real
financial tycoons such as J.P. Morgan or Citibank. We talk to them about our product and receive
a lot of specific, crucial feedback or tips on how to globalise. After all, the solution must be
universal, but also adapted to cultural and legal differences. Our foreign contacts also tell us we
have lots of potential.`,
          },
          {
            question: `Do you stand out?`,
            answer: `Yes. There are no technologies like ours on the market or in HR departments. They lack
corrective tools that would prevent employee rotation or diagnose HR problems before they
escalate. At Carrotspot, we have gone much further than the local competition. In Poland, most
solutions focus on benefit platforms where you can choose different benefits. But they do not
focus on people. Meanwhile, for us, working with people is part of our DNA. For example, we use
the expert knowledge of scientists from the SWPS University. They help us develop surveys, build
research programmes and diagnose the needs of various companies – the human needs. This is
why I sometimes joke that we are more of an HR company that brilliantly uses digital tools.`,
          },
          {
            question: `Do clients from the financial segment also appreciate this?`,
            answer: `Yes, this a very important segment to us, but also one that is very difficult.`,
          },
          {
            question: `Why is it important?`,
            answer: `Although this may not be apparent at first glance, financial institutions are undergoing an
internally turbulent period – they are affected by large-scale redundancies. This is connected with
the process of digitisation in the finance industry, among other things. It is gaining momentum,
and it is intensified by consolidating back office operations and the institutions themselves. What
is our role in this? We help employers manage crises in the teams affected by changes,
redundancies or restructuring. The positive thing is that people in banks understand well the
benefits of such solutions as Carrotspot – employees are informed and active. They make wise
demands to employers, and they are open to technology.`,
          },
          {
            question: `Why are they a difficult partner then?`,
            answer: `Well, this is about technology. Some of the structures in the banks are slowed down by
technological innovations, as they are linked to the progress of process automation in this sector
and thus future redundancies. Technology is also an obstacle because we work in a cloud –
banks are afraid of clouds. The Polish Financial Supervisory Authority has issued its position on
cloud solutions in relation to client data that do not close the market to the cloud at all, but
rather impose specific requirements. Meanwhile, our product does not concern client data at all,
as these are only internal banking processes. Therefore, the position of the Polish Financial
Supervisory Authority does not even concern this topic. Yet, we are subject to many prudence-
related procedures, which means long months of working on a contract.`,
          },
          {
            question: `Yet you still work with them, so do the benefits outweigh the costs?`,
            answer: `Due to my previous job, I am well aware of the specific nature of banks and I understand the
related official requirements (necessary due to the system itself) and high technological level.
Financial institutions expect solutions at a high level (including with reference to UX) similar to
the one they offer to millions of customers. After all, technologically, Polish banks are the world’s
elite and innovation leaders. They build accelerators, use blockchain in practice, and build
regulatory sandboxes taken from national regulations where they can use different solutions and
check their safety and stability. We are both on the same page in terms of blockchain.`,
          },
          {
            question: `Do you have any tips to bankers on how to improve their relationships with startups?`,
            answer: `First of all, banks have to educate and dedicate people who innovate the inside of the
organisations from the outside. This must happen in order to make things easier for startups and
benefit from technologies before they become obsolete or before their creators start working
with the competition. There is also a common purpose: in banks, we often meet hindering or
hostile factions with opposite goals. Our aim as a startup is effective implementation. This
includes, for example, testing our product and having access to the people who will use it, and
potentially modifying the solution after receiving feedback or after regulations changes. So, let’s
not be afraid of changes in a project, even unplanned ones, and be ready to pay the startup for
them – not with a kind word, but in money.`,
          },
          {
            question: `On the other hand, is there something intangible that a startup can give not only to
banks, but also to corporations and big organisations?`,
            answer: `A breath of fresh air that hits these institutions at the core. Startups bring a healthy unrest. They
send out clear messages: “think differently,” “abandon this limiting procedure,” or “maybe you
are not right after all.” At first, it always goes like this: “I will not let them teach me – I have
been doing this my whole life.” A startup invites a corporation to join them on an adventure: it
always works, not only in the banking sector. We need to involve them in our game and our
adventure, not for the sake of the game itself, but for specific results. This will enable us to
maintain the high standard of services and our Polish market leadership in banking or
telecommunication services, and offer them both the intellectual adventure and later participation
in the outcomes. This way, they can enjoy it and be proud of it too.`,
          },
        ],
      },
      {
        title: `With innovations, it is better to apologise than ask permission`,
        subtitle: `Interview with Maja Schaefer`,
        author: `Maja Schaefer`,
        company: `Chatbotize`,
        questions: [
          {
            question: `Usually, before a startup solution wins the approval of a large client, its authors have to work up a good sweat. But in your case, everything went like clockwork.`,
            answer: `Yes, in a sense. Although while working with the Polish financial industry, for example, we are starting to realise that the decision-making process is often a long and bumpy road. Despite that, we started quickly and managed to hit the jackpot right there. In late 2017, when I was developing the software house and providing dedicated software to individual clients, I noticed a growing trend of communication via chat, especially among the Millennials and Generation Z. At the time, I had a meeting with our trusted client, InPost, at which I proposed to automate the processes in the text channels, thus giving the customer service department relief from having to respond to repetitive questions during the intensive pre-Christmas period. They agreed.`,
          },
          {
            question: `Is that when Chatbotize was conceived?`,
            answer: `At that point, it was not a final product, but an implementation project dedicated to the single client. The project turned out to be successful and brought spectacular results to InPost. This encouraged us to create a generic solution, available to a wide range of clients thanks to reduced implementation costs through the use of distribution in the Software as a Service model. As a result, we drastically lowered the entrance threshold for those willing to test the solution.`,
          },
          {
            question: `To what extent is your solution necessary and unique?`,
            answer: `Improved communication in chat channels means introducing automation, but also using this kind of interface to build relationships with potential customers in order to engage them and to make sales. At the same time, automation means giving customer service departments relief from the burden of having to respond to recurring questions. The solution also works well in improving internal communication within a company, becoming the central source of access to useful knowledge. Summing up, on one hand, clients increase their savings thanks to automation and, on the other, they earn money by building long-standing relationships.`,
          },
          {
            question: `Are you on the roll thanks to your first client’s reputation?`,
            answer: `Obviously, implementations related to recognisable brands help us convince new clients, but we are growing because we respond to real needs and the most pressing problems of large organisations. At this point, companies are no longer faced with the question “Should we automate or not?” but rather “How do we automate as efficiently as possible while gaining customer satisfaction?” Our philosophy focuses on ongoing learning. We learn from each other in a team; we learn in accelerators, but most of all, we learn from our clients with whom we continuously work on developing the product and aligning it to their changing needs. Here, I also mean non-Polish clients, e.g. those from CEE, but also from the UK or even Kenya.`,
          },
          {
            question: `This last one sounds very exotic.`,
            answer: `Contrary to appearances, destinations such as Africa and South America are very promising in terms of innovation, especially those related to the mobile sector. Here, I would like to make a comparison between the European and the US financial markets. The Americans are pioneers on the financial market, but they are stuck with checks. Europe, particularly Central and Eastern Europe, has jumped from cash right up to online payments and related innovation. We have simply skipped a stage. The situation is similar in South American or African countries: not many people have computers, but most have smartphones. Therefore, mobile payments or services available through mobile applications are a standard there. Here, instant messengers are growing even twice as fast as on other markets. WhatsApp or Messenger are the leading applications and, therefore, they offer a huge potential for companies looking for channels to reach potential customers.`,
          },
          {
            question: `Yet most of your clients are from Poland.`,
            answer: `Thinking of Poland as a dream market may be dangerous. It is a market large and developed enough to allow for the development of a successful company, so this might limit its growth. When we look at innovative companies that have been successful on a global scale, they often come from very small countries and they think globally from day one. It is true that, today, most of our clients are Polish companies and Polish branches of global corporations. This is a first step for us. These companies provide us with a bridge to the world: to their branches worldwide.`,
          },
          {
            question: `Do your global ambitions mean that you will move out of Poland?`,
            answer: `Poland is a rapidly developing country that is perfect for implementing innovations, particularly for developing innovative solutions. We have access to qualified staff and excellent developers that enable us to build technology much cheaper and better than our competitors abroad. We are fast and flexible. But mathematics don’t lie: we sell our solutions abroad for higher rates, and the icing on the cake is the decision-making process itself, which is noticeably shorter there thanks to a more liberal interpretation of legal regulations. Not to mention the markets that have no idea what GDPR is. Nevertheless, we do not intend to give up our Polish clients, and Poland will certainly be our headquarters and our innovation centre.`,
          },
          {
            question: `How clear are the differences?`,
            answer: `In the context of contract processing, other countries are faster by several dozen percent, even though GDPR and thousands of other regulations are in force there as well. Yet, the mentality of foreign corporations (even in Slovakia or the Czech Republic) is that they prefer to introduce something faster and then possibly polish it up in legal terms. They say it is better to apologise than ask for permission.`,
          },
          {
            question: `So perhaps there is not much of a demand for chats here?`,
            answer: `On the contrary, this is a very real business need on the financial market. According to our analyses, the financial industry needs automation the most, especially in customer services, internal processes or onboarding of new employees. Of the plethora of solutions we offer, it is the hardest to convince banks to contact their customers via the Messenger application. These are sensitive issues that are somewhere around the grey area according to the Polish law. Fortunately, this is already changing. Social media applications (Facebook Messenger, WhatsApp, Instagram) are increasingly becoming better in responding to the needs and concerns of financial institutions. We also continue to strengthen our position and credibility e.g. by participating in the PKO Bank Polski’s startup program.`,
          },
          {
            question: `Still, there are a lot of “buts” here.`,
            answer: `If we can expect a long and costly decision-making process, a high number of meetings and legal analyses, this only means that the client’s implementation costs will have to be higher. For such startups as Chatbotize whose offer is directed at large organisations, including financial ones, strategic partnerships with companies that already have extensive business relations with banks or insurance companies are an excellent solution. This enables us to focus on what we specialise in: delivering value to clients.`,
          },
          {
            question: `Is the implementation process in a bank really such an obstacle course?`,
            answer: `We are focusing on the negative sides here, but if it were so bad, we would not have selected the financial industry as the one we want to focus on the most at Chatbotize. In large organisations, particularly in banking, we are seeing the emergence of innovation departments whose main task is to observe the market (which is referred to as startup scouting), and to select and implement solutions. This is very helpful and definitely speeds up the whole process since such a department becomes our brand ambassador within the corporation.`,
          },
          {
            question: `What else should companies in the financial sector pay attention to during the technological revolution in order to avoid falling behind?`,
            answer: `I am almost certain that banks are grappling in the dark when approaching the youngest customers. We have carried out extensive studies involving this very group and the conclusion was clear: financial institutions did not respond well to this group’s needs. This is certainly a challenge they have to rise to in the coming years in order to win the war for customers from the younger generation who, after all, are growing up and will soon become the group generating most revenue. The second challenge is, of course, to respond to the rising costs of the customer service associated with increasingly higher salaries, but also the digital age we live in. Whether a client will become our ambassador or our enemy often depends on the quality and timing of our response. The speed at which innovations are absorbed from startups may turn out to be crucial in this top-stakes struggle for customers’ hearts and wallets`,
          },
        ],
      },
      {
        title: `Startups will force corporations to sprint`,
        subtitle: `Interview with Krzysztof Sobczak`,
        author: `Krzysztof Sobczak`,
        company: `Emplocity sp. z o.o.`,
        questions: [
          {
            question: `Your job is to create the bots that find jobs for people. Sounds a bit like science fiction.`,
            answer: `Today, an increasing number of people and companies are recognising the work of AI-driven bots. However, when we started around 5 to 6 years ago, people would really gape at us when we mentioned the subject. Currently, our Emplobot finds jobs for people, feeds on proprietary algorithms and has intelligence in natural language processing, among other things. Additionally, it works like a marketplace: it helps the people looking for a job and, at the same time, offers an effective tool to companies to search for ideally matched employees.`,
          },
          {
            question: `When you started, did this kind of solution exist anywhere?`,
            answer: `This topic became hot around two or three years ago, following the development of more accessible and effective technology. However, there was something else at the time we started: my sense that the recruitment process was failing.`,
          },
          {
            question: `You mean dozens of sent CVs and dozens of unsuitable offers?`,
            answer: `Precisely. After all, connecting the right candidates with the right employer quickly and precisely is at the heart of good recruitment. The Artificial Intelligence developed at Emplocity can do this: we eliminate frustrations on the labour market by providing tools to automate the candidate sourcing process. Emplobot also plays a key role in pre-screening.`,
          },
          {
            question: `Are the candidates and recruiters not scared off by this kind of dehumanised recruitment process?`,
            answer: `Let us look at the expansion of communicators, such as WeChat in China or Messenger. Text is becoming the dominant means of communication. People are willing to have text conversations through their smartphone, and while the bot on the other side understands their natural language, they have no qualms about using chat messages as part of the recruitment process. Obviously, I assume that, in the long run, bots offering voice recognition will also enter the HR game.`,
          },
          {
            question: `Who was the first innovator that believed the new wave was coming?`,
            answer: `Budimex started using Emplobot in March 2018. It was a turning point. This was the first tangible proof that what we were doing really made sense. Obviously, before that, we also had the support and trust of our investors, EU grants and our excellent team. But let us not fool ourselves: Emplobot was created to be used by companies and candidates. Therefore, every new client boosted our confidence. Today, we have over a dozen clients. Landmark moments are also when I hear that our bot was somebody’s excellent and effective job search assistant, and when recruiters refer to Emplobot as a “superpower.” This really motivates us.`,
          },
          {
            question: `Did your first client get this “superpower” from day one?`,
            answer: `They were certainly taking a risk. They had to be a kind of godfather to our market debut. At the same time, thanks to their commitment early on, they were able to work out a lot of things with us: solutions tailored to their needs later became the standard of our service. This was beneficial.`,
          },
          {
            question: `Have other companies and corporations followed suit?`,
            answer: `This machinery tends to be self-propelled. The fact that Emplobot works e.g. for a large bank certainly fuels imagination, not only among our competitors. On the other hand, however, sales are still a big challenge for us because, well, reality bites. Today, when asked about innovations, every manager affirmatively nods their head. Yet experience shows that we cannot become complacent and expect that businesses will understand and start to use our solution on their own initiative.`,
          },
          {
            question: `What do you mean?`,
            answer: `Sometimes at meetings in large companies we are not treated very seriously but rather as a toy or a gadget, not as a solution helping to solve complex challenges between the company and its employees. Certainly, we have the patience and we educate the market. We know our value and potential, which is confirmed e.g. by the support of investors or public funding that we were granted. This enables us to treat such challenges as an opportunity, not a problem.`,
          },
          {
            question: `Can you see opportunities for yourself outside of Poland as well?`,
            answer: `This is the reason why we are here in the first place. Currently we only have Polish clients, but I believe this is only the first stage. Our strategy includes plans to enter 5 markets in Western Europe and the United States as early as in 2020. Maybe this will sound immodest, but we are building a global project. We would like to be the world’s best product in our field.`,
          },
          {
            question: `Do you have anything else to prove in Poland?`,
            answer: `Sure, there is still a lot of work to be done and we are not trying to avoid it. But let us look at it from a different perspective: the divisibility of attention. Therefore, we can focus our efforts only on Poland, maximising, trying, achieving successive milestones that allow us to earn and maintain the development of the company on a local scale. In this context, this development means clashing with the local tycoon: the Pracuj group, and further evangelisation of institutions as to the superiority of technology over traditional paperwork. Meanwhile, we have the potential for much more, including outside of Poland. There are plenty of quickly changing, open markets with good margins and high absorption of new technologies. Companies from Scandinavia or the British Isles are even taking the initiative to invite us for talks. Of course, such countries as Germany are very insular, yet at the same time they have potentially large needs that we can meet in the context of the liberalisation of the German labour market for e.g. Ukrainians. Therefore, we are looking for our chance to fit in there.`,
          },
          {
            question: `Are you going to look for big companies beyond the Oder River or on the Isles as well?`,
            answer: `Yes, these are our natural partners, about 90% of our clients. In Poland, our clients come mostly from the financial and insurance sectors, and it is them who are looking for us, not the other way around. The financial industry in Poland is very active in the field of acquiring innovations. It has already completed the stage of declarations and announcements and reached the stage of walking the talk. Banks and insurance companies understand that AI in recruitment is an opportunity. At the same time, they are aware that they are unable to create this level of tools themselves within a reasonable budget and time. For large companies, creating innovations using an internal formula is problematic (unless this is included in their core business). This is when they need ready-made, flexible solutions from such technology companies like us.`,
          },
          {
            question: `If they are so open, I assume this cooperation and implementation is a speedy process, bank after bank?`,
            answer: `It depends. Usually we begin with a face-to-face meeting and it becomes clear quite quickly how fast things will go from then on. There were times when we went out of our way to implement Emplobot in a large institution in just a few days. Obviously, the client’s enthusiasm quickly infects the startup: it gets on a roll and is able to do really great things. However, we often experience a bureaucratic spiral: 3, 4 or even 7 months of meetings, questions, stamps.`,
          },
          {
            question: `Do you know now what the best way to “break into” a corporation is?`,
            answer: `The easiest way is through HR departments; however, sadly, they often lack the power to break through higher. Although I notice that, from quarter to quarter, HR is gaining momentum in corporate hierarchies. In banks, innovation and digital transformation departments are certainly a good starting point. Compared to other industries, it is the finance industry where the awareness and resources to invest in technology are found. This is also clear because e.g. banks give us a lot of valuable feedback, and show understanding and support regarding PR in the media. For us, such deals also increase our credibility in the eyes of other clients. We also appreciate the kind of cooperation that is based on a partnership where we exchange knowledge and ideas, and everyone understands the dynamics of creating innovations.`,
          },
          {
            question: `But speeding up financial processes would be nice, wouldn’t it?`,
            answer: `Absolutely yes – in many institutions. Six months of implementation is an ordinary process for a bank, but it is often a millennium for a startup. Startups exist here and now, and are ready for action as long as they have advanced technology and services. If a startup has a strong and proven technology and organisation, it is ready for really demanding projects with a big partner. Yet, it requires the same level of readiness on the other side. This is not about being lenient and lowering entry standards for startups; it is about preparing for a sprint rather than a relaxed walk. There are so many unique things corporations can do with startups that it is a shame to waste time and energy on “processing the process.” We have to act!`,
          },
        ],
      },
      {
        title: `Banks and startups: a lesson on effective cooperation`,
        subtitle: `Interview with Aleksander Kijek`,
        author: `Aleksander Kijek`,
        company: `Nethone`,
        questions: [
          {
            question: `At Nethone, you claim that the Internet is not a global village, is that right?`,
            answer: `Well, just because we can make a transfer or send email in a second does not mean that Internet users have personal relationships with each other like in a real village. In the virtual world, we can be anonymous or even easily impersonate somebody. However, there are opportunities to fix this imperfection of the global village: the solution is Nethone. It was established to help businesses improve the Internet and rid it of fraudsters. Nethone helps you determine whether a given user is really who they claim to be. We have clients who sell their products or services online, and it is their responsibility to check if they are selling them to someone who has stolen bank card data used in a transaction. We see who is online ‘wearing a balaclava’.`,
          },
          {
            question: `How do you do that?`,
            answer: `Our technology is supported by machine learning. We analyse more than 5,000 variables in less than 2 seconds in order to evaluate users. We can check if a person who logs on is the one they are supposed to be or maybe they are someone who stole the login and the password. We observe the person’s behaviour on the Internet: whether they use the same device, how and at what speed they type in their password, what kind of browser they use, etc. If our models show discrepancies, they will evaluate them appropriately and force additional identification or block a transaction. Financial institutions, and in fact all players operating online, are in great need of such solutions. Companies automate many security processes, but even when they carry out the verification themselves, we provide our partners with a broad context of performed activities and key conclusions necessary to make the final decision: whether they are dealing with a fraudulent transaction or not.`,
          },
          {
            question: `Do you robotise these processes?`,
            answer: `I would not refer to it using this term because people and their ingenuity are still necessary. Certainly, we make the processes safer and more efficient. Contrary to popular belief, machine learning is not very advanced. Yes, it can process very large amounts of data in a very short time, but it is not creative. Therefore, the human factor (data scientists assigned to particular clients) is very important. Understanding the business needs of each client, data scientists model the data in such a way as to practically give the knowledge to machine learning on a plate. Machine learning will not invent anything on its own.`,
          },
          {
            question: `Were you the ones to invent Nethone?`,
            answer: `Yes. Our DNA is closely associated with Daftcode, the largest company in Poland operating in the venture building model, which independently completes all stages of project creation and implementation: from the concept, through the development, to the market launch. A very specific market need has appeared in one of those stages: checking the reliability of people entering into online transactions. This way, somewhat in the background, we started building a payment security solution and we developed it step by step. One day, our solution was mature enough to be offered to an external client. Since the potential was enormous, we established a new company that has been operating on its own since 2016.`,
          },
          {
            question: `Was it difficult to cut your ties from your parent company?`,
            answer: `A decision to become mature is never an easy one. But while preparing for the lone run, we also actively participated in selected foreign acceleration programs organised for example by French Thales, and Plug and Play from Silicon Valley. We approached the problem by focusing on selected industries in which we operated. We decided that, at the beginning, we would strategically concentrate on one very specific industry: frauds in the travel industry. This way, we found our first client – one from abroad. However, despite the training and knowledge, cutting the umbilical cord was a challenge. After all this time, I see that we have chosen the right moment. This process has increased Nethone’s confidence and dynamism.`,
          },
          {
            question: `Was that the plan?`,
            answer: `Yes, as far as online fraud is concerned. We immediately went under the radar of South American companies. This is where there is a lot of fraud in the online travel segment. But, for example among financial institutions, most of our clients are from Poland. We feel strong here because this is where we come from, and there is no similar competitive solution made in Poland on this market. Additionally, the startup culture is really beginning to flourish in Poland, gaining more momentum among large players. The level of IT and of the entire fintech industry in Poland is very high. Recently, we have integrated with a Polish company in just 2 weeks: from the moment when the documentation was developed, through tests, to the go-live! This is an absolute world record.`,
          },
          {
            question: `A perfect solution then?`,
            answer: `Things do not always look rosy. For example, negotiations are a typical Polish thing, but they focus mainly on the price, not the quality, scope or benefits of implementation. This is a bit harmful because if we focus on the price, it is often at the expense of the service level. However, when it comes to network security, it is difficult to make any concessions. Luckily, we always keep an eye on the win-win principle at Nethone, so that both parties are satisfied with the collaboration.`,
          },
          {
            question: `Who do you work with?`,
            answer: `We provide services to large companies from the tourism and e-commerce industry, such as the eSky group operating in nearly 30 countries, the largest Brazilian airline Azul, PLL LOT, and an international multi-brand clothing store. Since early 2018, our company has been actively cooperating with the financial sector, including the ING Group. It also supports leading lending institutions. Testimonials from an airline or a bank lend us credibility as a business partner because this is a clear signal that a large institution has trusted us with such a critical area of its business. It also stirs the imagination of other potential partners. There is no quick return on investment when working with corporations, but what they do is they put their trust in us. And this is an equally important value, especially for a dynamically developing technology company.`,
          },
          {
            question: `Many startups emphasise the formal challenges related to cooperation with banks. Do you feel the same way?`,
            answer: `It is certainly not a quick and easy process. However, for us, cooperation with banks and other financial institutions is important for several reasons. It is not just about funds for development or the image of a reliable partner, but also an opportunity for self-improvement and taking our solution to the next level. In order to meet all the requirements of such a financial institution, we have to do a very specific and difficult job.`,
          },
          {
            question: `Does collecting approvals and signatures take months?`,
            answer: `In principle, banks must tread carefully, and I understand that. But, at the same time, I think they could speed up the processes on their part: they do not have to involve dozens of people if only two can handle this on Nethone’s part. On the other hand, I was surprised by banks’ direct and quite relaxed personal approach to us. The movement towards innovation in banking is always attracting people who are open to new developments and who speak a language similar to ours. Although, of course, the quality bar is never lowered: as a result of cooperation with a larger organisation, a technological startup accepts certain process requirements and adapts internally. This is a good thing, but it takes time.`,
          },
          {
            question: `So, do bankers view Nethone in a positive light?`,
            answer: `Generally speaking, we have learned to win their favour or, to be more precise, their understanding. How? Our technology is not simple. We teach them about it in a series of intensive workshops. It is important that people in a corporation understand our product. This way, we increase the chance for its commercial success. Therefore, it often comes down to locating an evangelist in an organisation who believes in us and understands the value we bring to the corporation. It is the evangelist who guides us through the intricacies of cooperation, and connects us with the right people.`,
          },
          {
            question: `Are evangelists crucial to successful cooperation with banks?`,
            answer: `We need an entire team of evangelists. The bank’s project owner is key to successful cooperation. This is someone who will supervise the entire implementation process and keep an eye on deadlines, but who needs a partner with a strong can-do attitude if new resources to continue the project turn out to be necessary. We also need to identify a group of people who will operationally benefit from the new solution, and we have to pass on the right knowledge to them. Of course, there is still the whole legal sphere. I sometimes dream of a situation in which the banks’ lawyers would also speed up the process on the startup’s side, and start to assume that the companies were equals. Finally, we also need feedback from all of them: the one thing that can never be overestimated.`,
          },
          {
            question: `Is there still much to improve in the financial industry?`,
            answer: `This industry is moving quickly forward in Poland, for example with such infrastructure projects as the Domestic Cloud. This is a perfect solution that is an opportunity to jump-start a startup, which can enter the Cloud to integrate with a bank in a seamless way. And if the Cloud has the Polish Financial Supervisory Authority’s blessing, it means elimination of extensive process burdens and implementation costs for startups. What banks can still learn from startups is process efficiency in implementing technological innovations. And this is in fact what they are doing!`,
          },
        ],
      },
      {
        title: `It’s not all about the idea`,
        subtitle: `Interview with Hubert Guzera`,
        author: `Hubert Guzera`,
        company: `placeme`,
        questions: [
          {
            question: `Is it possible to make money in the digital age by showing how people behave offline?`,
            answer: `Yes, because the vast majority of sales are still made in brick-and-mortar shops, outlets and restaurants. Their owners constantly obsess about improving “conventional” sales methods. This is the reason why Placeme was developed. It is an application that allows us to understand the potential of a place and the behaviour of clients in a particular physical location, at a specific spot on the map. Naturally, we use digital assets extensively, aggregating numerous data sets and using our knowledge, as well as the experience of data scientists. We can process the data in a way that allows us to see, for example, where the people in a particular location have come from, how much time they have spent there, and what other places they have visited as well. When combined with many other sources, the data enable us to create a precise demographic profile of such a person and to map their offline behaviour. Importantly, we do not know their personal details as our data are anonymous, aggregated and largely statistical.`,
          },
          {
            question: `This is why your clients include large corporations and brands. How exactly do they use your application?`,
            answer: `If we take retail chains, for example, for them the right location is the key aspect of any investment. Therefore, they want their shops to be situated in frequently visited spots that offer a sufficient amount of human traffic throughout the day. They also want to know to what extent the traffic may translate into the turnover. Many other companies are starting to think this way, e.g. banks, although the latter are more interested in analysing the profitability of their current brick-and-mortar branches in specific locations. Our solution is also used to optimise investments in billboards or to effectively manage sales representatives’ schedules. To be honest, we are excited that we keep discovering new uses for Placeme together with our clients. The fact that clients can use the tool independently, without involving us, has always been our top priority since the very beginning of designing the application. The app is very simple to use and you do not need to be a statistician to generate surprisingly useful data that are crucial to making important business decisions.`,
          },
          {
            question: `You came up with the idea for the application based on your observations from the time when you were still working in the consulting industry.`,
            answer: `That’s right. As a consultant you have a unique opportunity to see many organisations from within, and learn how they cope with challenges. Also, I noticed that they all faced difficulties in processing or even finding spatial data. When looking for a new flat, even I was wondering how to filter out the offers in order to see only the ones I was interested in and the ones situated next to a shop, a bus stop or a park. I was planning to write a script to make it easier for myself and then I realised that it could be useful to others as well. This was the germ of an idea that ultimately became Placeme. The process of setting up a business took a very long time. It took me a year to decide whether or not this made sense. The idea evolved and I was working on it alongside my regular full-time work.`,
          },
          {
            question: `That is a long time. What ultimately triggered the launch?`,
            answer: `The idea is not the only thing that matters. These days ideas are cheap. My partner and I are bursting with them. The question is: how do we put them into practice? So hiring your first full-time employee or receiving your first big award can only be deemed as breakthroughs. We were finalists in the startup competition of the European Economic Congress in Katowice, although, in fact, we did not have anything in our startup CV at that time. But then the race began: we established the company and finished the product, revenues started to flow in, we got an investor, etc.`,
          },
          {
            question: `So was it an easy ride?`,
            answer: `I would not call it that. It was a mix of daily struggles and a sense of satisfaction. We managed to build a great team, one that really enjoys working together. At the same time, each of us understands that it is the nature of a startup that first we build and invest in it for a long time, and reap profits much later. This isn’t like being a tiler, for example, where you come, do your job and immediately charge your daily fee. It would be easier this way, but this is not how it works.`,
          },
          {
            question: `Part of your plan was your presence not only in Poland, but also in Portugal, right?`,
            answer: `We ended up in Portugal by chance. The turning point when we just started working on Placeme was an email invitation to an accelerator in Lisbon. Initially, we suspected it was a scam. They wanted to show us how to be a startup and an entrepreneur, and pay us EUR 80,000 for 3 months! But Lisbon gave us an amazing boost. Experienced mentors showed us how to obtain our first clients, how to talk about the product, and how to build up our narrative. This is where we heard: “if you're not ashamed when showing your product to the client, this means you're showing it to them too late.” Confronting market expectations early on is really worthwhile. The lessons we learned prepared us for our current journey. Without them, I think we would not be here as a company. And yes, we are also present in Portugal where we have a company, investors and even started sales. However, Poland is our most important market.`,
          },
          {
            question: `Looking at your clients, there is a lot to brag about.`,
            answer: `Our strength is determined by three main client groups: retail chains, FMCG manufacturers, and banks. We work with such companies as Carrefour, Dino, Netto, Unilever, Hortex, ING and PKO Bank Polski. There are also the so-called opportunistic clients that use our application for specific, one-off projects in other industries, e.g. DPD.`,
          },
          {
            question: `Does this lend you a bit of glamour?`,
            answer: `It is great to have such well-known brands in our portfolio, but we have to remember we work with them for the money, not to brag about on social media. Neither do we expect such large corporations to introduce us to the business world through their programs, competitions or accelerators. Above all, these are our clients. We deliver the value directly to them and invoice them for it. We meet their representatives at industry events, or arrange meetings with them on LinkedIn and offer our product. We look for people who understand startup dynamics, e.g. people from innovation departments in corporations. We listen to them, find out what they need and search for, and what challenges they face. This is the fuel that drives us. Thus, we never start our meetings by announcing: “We do this and that for this much money.” We prefer to know the needs first and then develop the tool in the direction set by the market. Obviously, it was not easy to convince the first clients that it was worth taking the risk and paying for the solution, especially in the banking industry.`,
          },
          {
            question: `After this experience, do you have any valuable piece of advice for bank decision-makers?`,
            answer: `In the business relationship between a company and a startup, the corporation cannot be the patron that becomes involved into the process of building the startup. The market is increasingly moving in the direction of surrounding this cooperation by marketing actions or attempting to “train” the companies for its needs. In healthy cooperation, the corporation simply pays the startup for the work performed. There is something unnatural and unhealthy in a patron-like relationship and it harms the development of the startup in the long run. We are here so that, without having to engage its entire machinery, the corporation can quickly (almost like a landing force) test, check and finally implement innovations. And this has its market value.`,
          },
          {
            question: `Is the fact that you do not use the “fintech” term to describe your activity an obstacle?`,
            answer: `Banks are very active in picking out startups. They compete with and observe each other in this field, which is dictated by the FOMO (fear of missing out) phenomenon. They attract more and more enterprises. However, we are a data based solutions company, not a fintech, but that does not take away the banking appetite for our solutions. Although the term “fintech” is often like a magnet for investors and clients, this label is not necessary. Banks are searching more extensive areas of the market every month. They feel increasingly better in the world of innovations offered by small enterprises. They are good at sensing trends concerning not only fintechs.`,
          },
          {
            question: `Is a bank a startup’s friend?`,
            answer: `Increasingly yes, the more so because cooperation with a bank gives a powerful, image-building benefit, which we have experienced e.g. after the announcement of our cooperation with PKO Bank Polski. Although certainly there are costs and obstacles that are difficult to eliminate and overcome in this industry, e.g. related security and IT requirements. Examples? If we wanted to access a banking system for testing purposes, this would consume hundreds of thousands of the client’s funds only to see if it worked at all. Meanwhile, there are sandboxes developing dynamically in other countries where certain things can be tested on trial accounts and data, e.g. 300,000 virtual customers. This means that significant technological barriers have been removed.`,
          },
          {
            question: `Can you bypass them in Poland?`,
            answer: `It is not that obvious. This is largely a matter of restrictive regulations, not the banks’ willingness or reluctance. The Polish financial sector is extremely modern, open to changes, although sometimes certain “innovative” movements of the banks are make-believe, falling within the scope of their marketing budgets, but not being actual investments in technologies. However, banks are also able to respond quickly and genuinely to successes of startups, like with Revolut and the entire subsequent wave of multi-currency cards.`,
          },
          {
            question: `Are banks therefore followers of revolutionaries?`,
            answer: `“Revolution” is too strong a word for a fintech. It is difficult to change the market with one or two products. I would rather say that fintechs “spark riots” that are then used by the rest of the market. They point out gaps in offers. But we must remember that customers on the market crave innovation at all costs. When I was still working in a consulting business, we used to wonder whether or not it was worth opening bank branches with a more friendly café-like atmosphere. We discovered that customers did not want that: taking out a loan in a bank was not like shopping for buns. Customers wanted a solid desk and a consultant in a suit because this gave them the feeling that their funds were safe. This is, in fact, what banks are for: not to show off the innovations, but above all to make our money safe.`,
          },
          {
            question: `Will they ensure Placeme’s growth?`,
            answer: `We value the banking industry, but we have broader horizons. The stage at which we fought for our first clients is over. We are now profitable, so we are looking at a much wider perspective than banking. Honestly? If the financial industry did not exist, we would still manage. On the other hand, it has a huge potential and offers fascinating, unique challenges. Recently, after a meeting at a well-known bank, we were even considering completing a certain project there for free. Well, maybe not for free, but just for the fun of it. This proves how interesting it was!`,
          },
        ],
      },
      {
        title: `Bankers’ mantra: learn, evolve and adapt`,
        subtitle: `Interview with Bartosz Rychlicki`,
        author: `Bartosz Rychlicki`,
        company: `Quantum cx`,
        questions: [
          {
            question: `You say that smiling is important in business. Do companies understand that?`,
            answer: `In face-to-face customer service, every good business strives for perfection. Therefore, we cannot complain about the lack of interest in Quantum CX technology: companies want to improve customer service and we make it possible to them. We encourage kindness in teams and in contacts with customers. Smiling is key for us. Everyone can smile to change their attitude towards another person during a conversation. This is where our technology comes in: we recognise and measure the smile with advanced sensors and software. Next, we reward the employee with a smile score, and the customer – with funds transferred to a charity organisation chosen by the employee. This way, we improve the atmosphere in a workplace and have a positive impact on human relations. A smile can work miracles.`,
          },
          {
            question: `How did you come up with the idea? Did you work in customer service?`,
            answer: `When we started 6 years ago, we did not think directly about smiles. At first, this was about passion for research, combining psychology and technology, and following the mantra that if you can measure something, you can also improve it. This is how we developed various applications: to change habits, then to track moods and finally to measure facial emotions. We started to promote the solution and were fortunate that the Costa Vide restaurant chain from the United States said it would be happy to test the technology. The tests were successful! It turned out that awareness of your facial emotions when talking to a customer had a huge impact on customer service. And it worked both ways.`,
          },
          {
            question: `Did the successful tests help propel you forward?`,
            answer: `We realised that we could develop the technology further in this direction. And if we additionally motivated people to smile and to be positive, this would bring very tangible benefits for every business. Then we carried out in-depth interviews with our potential clients, which showed that all their employees lacked positive commitment to face-to-face services.`,
          },
          {
            question: `It looks like a great way for a startup to enter the market.`,
            answer: `Oh, no, it has never been that simple. Even our current success has its dark side: we have a lot of additional work to do in contacting potential customers or the media. We receive plenty of invitations to conferences, accelerators and tests. And we also have to face the online hate speech because we allegedly force people to smile, which is not true. Fortunately, in large companies, we usually get an opportunity to tell them about the solution and how it actually works. But that all also means that due to lack of time we are forced to skip numerous meetings or events. Although it is a more comfortable situation than the one we were in 3 years ago when our wallets were empty and the whole team was at the end of their tether.`,
          },
          {
            question: `So, it turns out that a well-developed startup is not just about the product, is that right?`,
            answer: `Exactly. Until now, we simply focused on the product and its development, and this was fun. Everyone concentrated on the most important thing. Today, we deal with PR, communication with clients, and plenty of official issues.`,
          },
          {
            question: `Does the product continue to evolve or is it finished?`,
            answer: `We are still developing it. Not so long ago, we used it to check what emotions were evoked by advertisements. Then, we also wanted to measure negative emotions in customer service. This, however, aroused resentment in employees, so we decided to give it up. If it did not work, we had to let it go.`,
          },
          {
            question: `Why have you not been successful in the US yet?`,
            answer: `We visited the US for tests. The technology was still in its infancy. We will be back there soon. Besides, the Polish market has a really huge potential. There is no trend related to startups in the US as they have always been there. Meanwhile, in Poland and in the European Union, everything is starting to favour small innovative companies. Even large organisations invite us to meetings and presentations, which was unthinkable only a few years ago.`,
          },
          {
            question: `Sounds like an American dream on the Vistula River.`,
            answer: `To curb this enthusiasm, I will say that there is an unfriendly, hierarchical system prevailing in companies in Poland. This is almost like a monarchy: one boss asks another boss who then asks another boss. This goes 15 levels deep. Meanwhile, in the US, you meet with a decision-maker who takes risks on behalf of the company and you start acting together. Head of the previously mentioned US restaurant chain managed an organisation with an annual revenue of 500 M US dollars. But that did not stop him from driving us to a shop to get some adhesive tape, stick some sensor cables and start the test.`,
          },
          {
            question: `Does this hierarchy structure refer more to the mental or systemic issues resulting directly from company procedures?`,
            answer: `In Poland, there is a noticeable reluctance to make decisions, a great fear of risks, and not only in banking. In the US, the unwise thing to do is not to spend money, but let it wait idly. Americans think it is better to use it, even if this means a risky investment. In Poland, however, it is the other way around and you can feel it every day. It is not that it is impossible to work here. After all, we already collaborate with two banks who are market leaders in Poland. We are also developing in the segment of closer employee-customer interactions, like in a call centre, and in places where the contact only lasts a short while: at petrol stations or pharmacies.`,
          },
          {
            question: `So, banks don’t really want, but are forced to innovate, is that right?`,
            answer: `On one hand, they strive for innovation, while on the other, they often have their old procedures and are slow to make final decisions. However, they certainly know that they cannot create such solutions as Quantum CX in a reasonable time and at reasonable costs. While a startup is able to deliver a working prototype in 2 to 3 weeks, a bank will only hold a meeting in the same period of time. Thus, we meet and try to live in symbiosis with them: they have the need, the time and the resources, and they offer space for testing and implementation, but they lack the capacity for innovation and creativity. However sometimes, we meet excellent, dynamic people at the bank. In fact, everything is about people.`,
          },
          {
            question: `Are they the link between these two worlds: the big and the small?`,
            answer: `Yes, increasingly often, these are people who understand that a month in a bank is a short period of time, while it seems like an eternity to us. Therefore, we develop our relationships with them and we appreciate them. Once we know there is somebody who manages everything at a large organisation’s side, we can focus on closing the deal.`,
          },
          {
            question: `So, is it worth waiting for the banks?`,
            answer: `It is. The question is whether we can wait a bit less. I must admit that we are doing business, so we are here for the money, not necessarily for the fame. Although collaboration with such a large partner as PKO Bank Polski also means a great opportunity to improve the product in a real environment and hugely helps with marketing, revenue remains the most important thing. We want clients to buy a product, not just to boast about it or hesitate indefinitely. It is excellent if both parties tell each other clearly at the very beginning what and when must happen for the implementation to take place, and if they stick to this agreement.`,
          },
          {
            question: `And get over any fear?`,
            answer: `Perhaps just the fear of investing larger amounts. The monthly cost of delivering coffee beans to a coffee machine in a bank’s office building is probably higher than our solution, but nobody walks around with a coffee cup in hand, wondering how the rotation dropped due to the Colombian Arabica in coffee machines on the 17th floor. But when it comes to spending e.g. PLN 30,000 on our solution, suddenly there are hordes of analysts watching every penny and the effectiveness behind it. It takes a lot of time and it causes a bureaucratic scramble.`,
          },
          {
            question: `And at the same time Revolut and others are leading a revolution.`,
            answer: `The “don't touch it, or we’ll break it” mentality still has its supporters in the finance industry. But the banks will have no choice but to do what they have already started doing, just more effectively - learn, evolve and adapt. Revolut has proven that technological solutions really change this traditional financial market. This is a rapidly growing new competition to large institutions.`,
          },
        ],
      },
      {
        title: `In Poland banks are fintechs`,
        subtitle: `Interview with Michał Pawlik`,
        author: `Michał Pawlik`,
        company: `SMEO SA`,
        questions: [
          {
            question: `Looking at your CVs as SMEO founders, I see veterans conquering the fintech space.`,
            answer: `Agnieszka Gołębiewska, Monika Woźniak and I are all startuppers trained in battles. We have been successful in corporations, banks and private businesses. That is our asset. Previously, we developed two other factoring companies together: Idea Money established in 2011 and taken over by Idea Bank for 170 M Polish zloty, and e-faktoring.pl, a lending and factoring business. However, although SMEO is also a factoring company, it is technologically distinct. We provide online factoring by financing micro-businesses online. We do it online and digitally to the maximum extent possible.`,
          },
          {
            question: `Are machines replacing people?`,
            answer: `I would not say that, but, indeed, there is no human factor in the decision-making process. Machines and machine learning-based engines make decisions and disburse funds. This shows our technological advancement. On the other hand, let us remember that SMEO is a team of experienced specialists. They – not the machines – have the last word. Sometimes, we examine our clients’ applications individually and we make the so-called manual decision.`,
          },
          {
            question: `So, no more offline factoring?`,
            answer: `This is not entirely true, but today the Internet is the channel with the majority of traffic of customers and institutions leans, and where financial products operate. Currently, people are starting to look for financial products on the Internet. We can see that even in the case of the simplest offers, such as consumer loans. By introducing services to micro-businesses, we partner with such companies as Santander Leasing, but also with hundreds of individual people and businesses every day.`,
          },
          {
            question: `This really stirs the imagination. Are you an example of success achieved at the interface between finance and technology?`,
            answer: `We try to avoid such descriptions. A startup is successful when it reaches the level of a mature, profitable company that has earned a return on investment by achieving the right financial results, by selling itself, or by making IPO. Us? We simply live and grow to become a larger company, the so-called scale-up. We have a good investor. We have had success, but not yet with the capital “S.”`,
          },
          {
            question: `Many fintechs do not even live to see the small “s.”`,
            answer: `That is true. Our team’s experience turns out to be crucial: substantive knowledge of how and why we operate. A senior startup means we have both feet on the ground. To an extent, we operate on a reliable market in a well-tried and tested business model, but we have turned the technology up to eleven.`,
          },
          {
            question: `So is SMEO destined for success?`,
            answer: `We have a good sense of timing, and we sense there is an emerging need on a market that is crucial to us: Poland. Often, very innovative startups are ahead of their market, so clients are not ready for them, or they implement their idea for too long, looking for funding, and then they get stuck, someone outstrips them and the world changes. We are only a bit early, so we have great timing – we are building a new category. Factoring in Poland on the micro-business market is in for rapid development. According to our research, only 4% of companies use this product, while just about 10% know the basics of factoring as corporate funding.`,
          },
          {
            question: `This is a promised land.`,
            answer: `For several reasons, Poland is very attractive for such companies as SMEO. In order to be present in Central and Eastern Europe, you have to start here and only then think about expanding to other neighbouring countries. It is also necessary to take into account the nature of the Polish banking system, which is extremely innovative. In fact, Polish banks are fintechs.`,
          },
          {
            question: `But they are both your partners and your competitors, is that right?`,
            answer: `Yes, this is how I see it. In the European context, Polish banks stand out dramatically thanks to their technology, and positively so, yet they are still open to external innovations. You need proof? Such banks and financial institutions as Santander Leasing are involved in the distribution of our product. It is a great partner who is so easy to work with because Santander Group in Poland does not have a microfactoring product. We do, so we have joined forces. And it works – we deliver the targets.`,
          },
          {
            question: `Have you experienced the legendary banking bureaucracy before?`,
            answer: `Yes, but it did not come as a shock to us. I worked in a bank and I knew how they operated. This immense bureaucracy in banks is an inherent part of these institutions. And I would not expect it to suddenly evaporate thanks to startups. Banks cannot be institutions completely devoid of bureaucracy because, after all, they are public trust institutions where people (also those without extensive economic education) keep their money. So, technically, the bank must protect the money and be closely supervised. Additionally, banks have a direct impact on macroeconomics. The collapse of any bank in Poland, even a medium-sized one, would cause huge chaos on the financial market and may cause great social unrest. Therefore, bureaucracy is very understandable or even necessary. You just have to get over it.`,
          },
          {
            question: `And include it in a business plan?`,
            answer: `Correct.`,
          },
          {
            question: `Did your dealings with the bank follow this path?`,
            answer: `It followed the right path. We met at one of the industry conferences in Kraków. I had a presentation there and I was approached by one of the bank’s board members – he was impressed by us. The huge involvement of the board ensured that the project really picked up from the very beginning. They quickly saw this as a business opportunity: in financial terms as well as a breath of fresh air from a startup. They treated us like a partner, not their provider of services or goods, such as toilet paper.`,
          },
          {
            question: `Does that even happen?`,
            answer: `We have had some bad experiences with well-known institutions who said: OK, we will organise a tender, and you put your offer in an Excel file and guarantee these indicators. Otherwise, you can leave now. This is shocking. It shows how unprepared for a partnership these institutions are. Although, perhaps, part of the arrogance is justified. After all, banks in Poland are able to do a lot technologically, and they can do it independently without any fintechs. However, we are looking for places where, regardless of the size differences, we talk like equals – where we are simply another player on their market. In the US or the UK, this viewpoint is a standard.`,
          },
          {
            question: `Maybe this is because you are also competition?`,
            answer: `It is more about failing to see potential synergies. OK, we are lending money to small businesses. Banks lend money to small businesses as well. I have the same customers, so I am the bank’s competitor. Yet, I lend the money in a slightly different way – after all, banks are subject to regulatory supervision, they do not actually have their money, but deposits. I have the money from investors who can burn it in the fireplace if they want to. I can also get my investors to lend it to micro-businesses. This is how we differ from banks. Plus, we have a different philosophy of lending money. We can complement each other perfectly. SMEO can reach clients that a bank cannot, even if they wanted to, because the regulatory straitjacket does not allow them.`,
          },
          {
            question: `Can you really do so much more together?`,
            answer: `Yes. Technologically, Poland is an incredibly innovative market. It can be a good springboard to expand abroad. It is in Poland that many technologies are being implemented for the first time, including contactless payments, while the United States still use checks. However, fintechs will not sow the seeds of revolution in Poland – the banks are too strong in terms of technology. Yet such companies like SMEO do make sense – they are the ones who improve the market with respect to its individual aspects. Fintechs are able to notice trends, cultural changes, social changes, tendencies and new customer needs quicker. Customers tend to choose a good product on the financial market rather than a bank or a fintech. And this is how we like it.`,
          },
        ],
      },
      {
        title: `It takes two to tango`,
        subtitle: `Michal Smida`,
        author: `Michal Smida`,
        company: `Twisto Polska`,
        questions: [
          {
            question: `You say that you deal with payments. However, from the consumers&#39; point of view, this is not so clear-cut, right?`,
            answer: `That’s because Twisto is a completely different method of payment. We are known under
the slogan &quot;buy now, pay later&quot;. Our customers decide when they want to pay for purchases,
either right away or by the 15 th day of the following month. We are also working on
developing other features. When making purchases abroad customers get the best exchange
rate, without fees or commissions, and they don’t have to remember to exchange the
currency beforehand. If they decide to postpone payment, they can do so with one click
through the app. Since recently, Twisto users can also easily divide a bill among several
people, and soon it will be possible to pay for larger purchases in instalments. Importantly -
it all happens through the application, without having to visit any place, through the
telephone or courier. In short - with Twisto, customers can pay however they choose - not
only how they want, but also when they want.`,
          },
          {
            question: `An application and a card from a non-banking company sounds a bit like... Revolut.`,
            answer: `We are often compared to Revolut, although unlike our competitor, we give our customers a
free credit limit, so they don’t have to &quot;put up their own money.&quot; We are also different in
how we conduct business. Unlike Revolut, we are not looking to push out banks, especially
since one of them, ING, is our shareholder. What distinguishes us is the desire to live in
harmony with banks, and at the same time show that when it comes to online payments you
can have an absolutely pro-consumer, but also safe approach. In creating Twisto, we were
inspired by the Swedish company Klarna, which also offers deferred payments, but does not
operate in our region. With Klarna, in the Nordic countries, I can go to an online or stationary
store, buy a fridge and pay for it within 2 weeks without any additional costs. So why
couldn’t this work in CEE? Now this unique solution is also available in our region, and it’s
offered by us.`,
          },
          {
            question: `So you developed the technology and got financing. Did the banks feel the need to respond?`,
            answer: `Of course we were noticed, after all we operate on the same financial market with the
banks. However, the financial specificity of the CEE region is of key importance. When it
comes to us, as a region, versus the West, the percentage of market penetration by credit
cards is strikingly low. Banks are great at selling accounts and mortgages, but they are
getting worse when it comes to instant financing. Non-banking lenders are faster, but they in
turn have a very high interest rate, so they are attractive only to a specific group of
consumers. Banks know that they are not able to be everywhere and always be the first,
they usually do not have the courage and agility of startups, and they naturally focus on
more predictable and profitable business lines. Twisto operates in the current, every day
financing segment and it is here that we provide customers with unique benefits. However,
there is no doubt that banks are closely following the market, and - as we observe in Poland
- they are thinking about new technologies, they invest in them, and at the same time
maintain high confidence among their customers. This is also why this was an interesting
collaboration for us. In fact, every bank is a kind of partner in our business - everyone who
buys through Twisto must finally pay back this liability. And they almost always do it with the
help of a bank. Although we also have cases like, let’s say Ms Jones, who pays for her
purchases by postal order and writes to us: &quot;thank you for existing because I don’t have to
have a bank account to buy online&quot; (laughs).`,
          },
          {
            question: `Do you get along with the bank as an investor? Stereotypically, banks are rather thought
to be rigid corporations, not really willing to cooperate with startups.`,
            answer: `Entering into capital relations with a bank is certainly not an easy decision for a startup. A
young, but ambitious and flexible entity, composed of people from various industries,
collides with the great machinery of an international corporation. However, ING is an
innovative bank and open to a partner-based, honest relationship with fintech. These days
our relations are very good and honest. What binds them is achieving planned results,
processes and support in key moments. We don’t hide the fact that the ING investment has
narrowed our potential list of banking partners, including in Poland. However, at ING, we&#39;ve
also gained something that many companies envy - it&#39;s the trust of the market gained
through a relationship with such a credible investor.`,
          },
          {
            question: `Can banks see the potential of collaborating with startups?`,
            answer: `There are some banks in Poland that have opened their doors to startups. In addition to ING,
this includes mBank and PKO Bank Polski - leading innovators in the country. This is one way
for them to become more flexible. The alternative is to set up daughter companies that will
be able to work with startups easily and directly.`,
          },
          {
            question: `Does this lower the bar for contenders?`,
            answer: `A bit, but let&#39;s be honest - if we are dealing with regulated markets, it&#39;s not worth taking a
shortcut. It is better to adapt to the banking standards. This does not mean that you can&#39;t
show banks how to get to the same place more efficiently and cheaply. In this case, the
collaboration between a bank and a startup gains momentum. Of course, provided that the
bank also tries to enter into this relationship understanding that the startup is not able to
grow by adapting to all the internal regulations of banks. They usually don’t have an army of
corporate experts or staff of lawyers to help them. A solid startup concentrates on a
maximum of 1-2 key projects with large institutions, because trying to kill too many birds
with one stone can quickly deplete it of energy and available resources.`,
          },
          {
            question: `Do banks understand this?`,
            answer: `Increasingly so. Here in the world of beginner fintechs the big guy can do much more, but it
must also be interesting, fast and determined not to miss a chance. Dragging a startup for
several months through the corporate &quot;mill&quot; can kill it by exhausting its strength and
resources. It is also worth looking further throughout the region as there are many
companies from Estonia, Lithuania or Latvia with great solutions. Although, of course, some
solutions that work in the US or Israel are simply impossible in Poland for regulatory reasons,
but this doesn’t mean that there is no place for unique projects with global potential. For the
venture to be successful, however, collaboration within the ecosystem must be beneficial to
both parties. I very often see startups from the financial industry who need the right
corporate partner (e.g. a bank) to reach a wide market and develop quickly. And the bank
needs a startup to innovate faster and more effectively and reach new customer groups. It
takes two to tango.`,
          },
        ],
      },
      {
        title: `Ready-made solutions open corporate doors`,
        subtitle: `Interview with Bogusław Bieda`,
        author: `Bogusław Bieda`,
        company: `Vindicat.pl`,
        questions: [
          {
            question: `How, after 20 years working in corporations, did you do a 180 degree turn and take up a controlling stake in a startup?`,
            answer: `This happened for several reasons. Firstly, I wanted to leave the corporate world and find an interesting project to invest in that would give me the possibility of building a company’s value using my previous experience. Secondly, while analysing the startup market in Poland, I talked to several potential enterprises and really liked the founders of Vindicat: for their interesting project, experience and energy. When I came on board, I thought the product was ready to be sold on the market, but needed an appropriate approach from the viewpoint of its commercialisation. In fact, the main argument in favour of the move was the solution itself. What is Vindicat? It is an application that meets the needs of entrepreneurs and helps them collect money from unpaid invoices. This is the only solution on the market that supports all the pieces of the puzzle related to debt collection. We focus on the SME segment (but also have corporate clients). We automate processes in the legal tech area, providing tools to handle them.`,
          },
          {
            question: `What is your advantage over a lawyer or an accountant that hounds unreliable clients?`,
            answer: `This is a completely different level of automation, efficiency or costs. We provide a comprehensive tool starting with training and prevention, and focus on effectiveness and business class. Still, many entrepreneurs believe that debt collection is an activity on the edge of the law. Vindicat shows the process from the receivables management side, as a system-based, well-thought-out, step-by-step action. We challenge the stereotypical way of thinking about this process as being something embarrassing or inappropriate: in fact, this is about seeking one’s well-deserved money. It is simply part of business as usual. Thanks to us, entrepreneurs can carry out the process themselves. And it is effective.`,
          },
          {
            question: `Because of your previous professional history, are you not tempted to implement this solution in large companies or corporations as well? Or maybe there is no demand for this kind of tool there?`,
            answer: `We know our place and we are currently in the process of building product awareness. This means, however, that we are also present where the big companies are, for instance, we are part of the EYnovation programme. We also participate in acceleration programmes, e.g. Let’s Fintech with PKO Bank Polski. We are also present at two other banks: BNP Paribas and Alior. We follow a twin-track approach. On one hand, we want to draw corporations’ attention to the fact that, thanks to us, they can automate and streamline part of the processes in their legal or debt collection departments. On the other hand, banks have already become platforms that enable us to promote the product on the market, which would otherwise be very expensive. This way, the banks that work with us can offer our service to their clients (entrepreneurs) as an addition to their accounts. Results? We are present in the offer of each of the three banks.`,
          },
          {
            question: `In what way does this translate into your revenue?`,
            answer: `Currently, we are still testing various models of reaching the client via the bank, but it seems that the most effective one is the model implemented with Alior: one in which we know from an accounting system that a client has an unpaid invoice and where we can offer our service to the client. Here, we almost always reach the entrepreneur who needs to manage such an invoice. However, in the case of PKO Bank Polski, we target our offer at particular industries that are extremely prone to liquidity problems and delayed payments. At BNP Paribas, we are testing yet another sales model, offering the product in outlets and, in the future, also through an accounting system.`,
          },
          {
            question: `This is also typical for startups. Was it a hard landing for you after you moved to the startup side of the force?`,
            answer: `I realised that startups are a lot about selling dreams, and their life cycle is sometimes short. Hence, before I decided to join Vindicat, I researched the market, meeting various companies, people and investors. In the end, the key asset turned out to be people. In a corporation, you have the whole supporting structure, whereas in a startup, you stand on the front line with 4 or 5 people who are ready to fight. Therefore, they need to offer competences that are as extensive as possible and ones that complement each other. It is also worth looking for people who are ready to work on something that is like a testing ground. On the bright side, the soft landing was the fact that my future partners had already had a ready-made product, and they were able to finance it. Though, as is common for startups, that didn’t always work according to plan.`,
          },
          {
            question: `Is it hard to rely on investors?`,
            answer: `That is not the point. I am referring to bureaucracy here: the project was financed from EU funds and, at one point after an audit, its founders received an official letter saying they were not implementing the project as planned. In other words, the officials wanted the money back. It was a very stressful period for the company founders. The appeal process took several months, but the ultimate decision was in Vindicat’s favour. Besides, startups have to continuously raise capital, and managing this process is practically their core business. Financing can be a brutal reality check. Luckily, Vindicat founders have both feet on the ground. When doors started to open to us thanks to our relations on the financial market, we were ready to turn dreams into reality and commercialise the project; to walk the talk.`,
          },
          {
            question: `How do you convince clients?`,
            answer: `It is not that difficult if we can show a finished, working product and easily demonstrate the benefits it will bring to a potential partner. Therefore, we are confident and we can approach the subject with our heads held high. This gives us recognition in the eyes of large partners. We put the ready-made solution on the table. Since banks are turning into types of marketplaces with services for entrepreneurs, we would like to be there on one of the top shelves. This is where the banks are going. They are hungry for innovation, feeling the pressure from such players as Revolut or Klarna. We are also innovative. We have some ideas as to where we could still implement our product, and we are currently holding talks with the insurance industry, which I know well because of my previous experience. I am working on making this announcement, together with the corporation, of course. We feel that this is very much a partnership. I understand their language, signals and dynamics. Therefore, even though Vindicat is not a tycoon, nobody says to us: “oh, yet another startup guy wanting to sell us something.” It is worth showing the value of your solutions to corporations and to the entrepreneurs themselves who still have a lot to do in this area. Many fail to supervise payments or manage their receivables.`,
          },
          {
            question: `Do they show you who the boss is and who makes the key decisions?`,
            answer: `Of course, a corporation has its rights and they must be respected. Time kind of slows down there, 1 or 1.5 years of building the relationship with a bank may be shocking for a startup, but it is a standard for the bank. You have to be patient. Although, in some corporations, I was very pleasantly surprised by the teams that wanted to talk to us: by their pace, approach and understanding of the subject. The innovation departments in the banks with which we work are very startup-oriented; they want to test, implement and develop. We can feel that the example comes from the top; that it was decided the innovation department would have a real voice; that it has the ownership of such topics. And this is how we like it. This makes things easier for us. They pave the way for us in institutions. We do not have to do it ourselves or grope in the dark.`,
          },
          {
            question: `You also have good (equity) relations with business angels. Do you use their knowledge and experience or just their money?`,
            answer: `Absolutely everything. In our case, the business angels are Cobin Angels, financed by four private investors. Their contacts, experience and mentoring have helped us avoid many mistakes, and help us find solutions. Occasionally they open doors for us to the offices of top-ranking managers and help us avoid the initial stages of business contacts. Although sometimes we need to find a balance in all this. It is not always worth going to a CEO straight away if a decision is made by lower-level managers anyway. In this case, it is better to contact them directly, without a fellow business angel.`,
          },
          {
            question: `Are recommendations from large brands you work with enough to land a client or investor?`,
            answer: `They help a lot. When we announced our cooperation with PKO Bank Polski, ten investment funds immediately asked us if we wanted money. Similar seeds of optimism have also been sown among decision-makers in corporations. However, cooperation with big brands is not enough. You have to respect your partner and communicate the processes, stages and possible problems to them very clearly. And this should work both ways.`,
          },
          {
            question: `Right. Both ways. Not every fintech or regtech, like you, sees themselves as an addition to banking services. Can fintechs revolutionise the Polish financial sector?`,
            answer: `Probably. However, the question is whether this revolution will be about the created ground-breaking solution being copied or bought by banks for themselves. This will change the whole financial sector, but it will not marginalise the banks. Let us remember that the “old” world of finance holds firm and is aware that it is in their interest to turn threats into opportunities or simply to neutralise them. I am not saying this in a negative context, rather because I am aware of the market reality. Abroad, I often hear praise for the Polish financial market, for its innovation and modern management style. We also have our own ambitious goals outside Poland: our next step are CEE markets. This is where we want to sow our seeds of revolution.`,
          },
        ],
      },
    ],
  },
  startupWishesSection: {
    id: `startup-wishlist`,
    header: {
      main: `Startup wishlist`,
      subtitle: `10 tips from startups to banks on how to work together and improve collaboration`,
    },
    items: [
      {
        title: `Give us a guide`,
        text: `Collaboration with a startup involves many people and processes on your part, so choose a
team that will be the point of contact contributing to improved understanding and
communication. You need effective processes for onboarding the innovation into a large
organisation, whereas we need a guide leading us through the implementation who
understands how a startup works.`,
      },
      {
        title: `Set the rules of the game`,
        text: `Make your expectations and needs clear at the beginning of our journey. Show us the criteria
that will determine who you will choose to work with, what the stages of cooperation will be,
and what must happen for implementation to take place. If we both stick to them, our
collaboration will go smoothly and we will avoid misunderstandings.`,
      },
      {
        title: `Keep healthy boundaries`,
        text: `We do not expect you to get involved in the process of building our company, to teach us
how to do business, or to be our patron. You are our client and our business partner – let’s
keep it that way. A healthy model is: job done = payment, even if this “only” means a PoC.`,
      },
      {
        title: `Meet only to discuss important details`,
        text: `Let us not waste an entire day on unnecessary meetings. Instead, let us establish clearly
who and to what extent must be involved in the collaboration on both sides, and what we
need to agree together. This way, we will avoid months of endless meetings.`,
      },
      {
        title: `Do not flood us with regulations`,
        text: `We know technology and business, but we often need support to meet your internal
procedures and legal regulations. Remember we do not have an army of lawyers on our
side, and our resources are limited – do not leave us alone with this. Instead, help us adapt
to procedures and regulations in our joint project.`,
      },
      {
        title: `Do tests and stop processing endlessly`,
        text: `We have a proven technology and a strong team. We are ready to show you how our
solution works and we expect the same readiness from you. Therefore, prepare for a sprint
rather than a relaxed walk, create a dedicated technological environment and let us show
you what we can do – if not in a go-live, then at least in a sandbox.`,
      },
      {
        title: `Share feedback`,
        text: `We learn most from our clients, so share what you think about our solution. Your feedback
will help us adjust and develop it according to your expectations. Remember that good
feedback has never hurt anyone.`,
      },
      {
        title: `Focus on partnership`,
        text: `There is a clear disparity between you and us, but take us seriously and treat us as partners.
Remember that a startup is not a standard supplier of products or services, but it can bring
additional value and know-how to your business. Therefore, it is worth treating us as your
partners. Such relationship will improve the quality of our collaboration.`,
      },
      {
        title: `Be open to new things`,
        text: `We will not solve new problems by sticking to old patterns, so be open to new solutions and
change your approach from “surely that’s not possible” to “let us try doing it together.” We will
work better if we are on the same page, preferably at all levels of the organisation.`,
      },
      {
        title: `See us as people`,
        text: `We do business together, but we also work together – in the end, everything is about people.
Therefore, do not just look at us from the viewpoint of the solution and the technology we
provide. We also care about direct and good relations between our teams.`,
      },
    ],
  },
  founderTipsSection: {
    id: `founders-tips`,
    header: {
      main: `Founder’s tips`,
      subtitle: `5 tips for startups who want to work with banks`,
    },
    items: [
      {
        title: `Insiders open the door`,
        text: `If you have not worked at a bank (or other corporation), it will be difficult for you to
understand the nature of this environment. Therefore, it is worth expanding your team by
someone who has experience in this sector and knows its ins and outs. Thus, you will better
understand how the bank works from the inside and gain valuable contacts`,
      },
      {
        title: `Don’t take on too much`,
        text: `If you want to sell something to a corporation, focus on 2 to 3 large clients at the same time.
Handling the collaboration right will require a lot of time and effort. Do not take too much on
yourself and be ready (financially and organisationally) for at least a few months of work in
the corporate machinery.`,
      },
      {
        title: `Bank-ready solution`,
        text: `Collaboration with a financial institution is not a relaxed walk. Expectations for the solution
and the team are high. Do your homework and be ready to defend your product/service in
every aspect: its technology, business model or potential risks. Make sure that the
technology is secure and complies with applicable law. Sooner or later, you will be asked
about this.`,
      },
      {
        title: `Angels work miracles in a corporation`,
        text: `Relationships are essential, so find a person or a team in a bank who will believe in your
solution, and will help you “sell” it inside the organisation, supervise the implementation and
meet deadlines. This pairing will make everything easier from the start.`,
      },
      {
        title: `Focus on benefits, not pushy advertising`,
        text: `When selling your solution to a bank, forget about advertising and focus on the specific value
you bring to the organisation and the problems you solve. If the bank sees the value at the
beginning, it will be more likely to move through successive stages of collaboration with you.`,
      },
    ],
  },
  meetStartupsSection: {
    id: `check-startups`,
    header: `<strong>Check 100 startups</strong> that transform Polish banking`,
    button: `See more`,
  },
  reportAuthorsSection: {
    id: `report-authors`,
    header: `Authors`,
  },
};

export default {
  heading: `Materiały dodatkowe`,
  audioText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tristique interdum ante, eu tempus erat mollis eu.`,
  videoText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tristique interdum ante, eu tempus erat mollis eu.`,
  title: `Materiały dodatkowe`,

  spotify: [
    { id: '2eZjO58Qe9og2xmyfw4qwJ', type: 'track' },
    { id: '4IojpVltvdlDvlQ6uC6iVW', type: 'album' },
    { id: '4LXnXUudDudytCjywGd8zG', type: 'playlist' },
    { id: '2eZjO58Qe9og2xmyfw4qwJ', type: 'track' },
    { id: '4IojpVltvdlDvlQ6uC6iVW', type: 'album' },
    { id: '4LXnXUudDudytCjywGd8zG', type: 'playlist' },
    { id: '2eZjO58Qe9og2xmyfw4qwJ', type: 'track' },
    { id: '4IojpVltvdlDvlQ6uC6iVW', type: 'album' },
    { id: '4LXnXUudDudytCjywGd8zG', type: 'playlist' },
    { id: '2eZjO58Qe9og2xmyfw4qwJ', type: 'track' },
    { id: '4IojpVltvdlDvlQ6uC6iVW', type: 'album' },
    { id: '4LXnXUudDudytCjywGd8zG', type: 'playlist' },
    { id: '2eZjO58Qe9og2xmyfw4qwJ', type: 'track' },
    { id: '4IojpVltvdlDvlQ6uC6iVW', type: 'album' },
    { id: '4LXnXUudDudytCjywGd8zG', type: 'playlist' },
  ],

  videos: [
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
    { id: 'HiXY-xghv-Q', title: 'Toss A Coin To Your Witcher (Rock Cover)', text: 'Toss A Coin To Your Witcher rock cover by Violet Orlandi ft Barbie Sailers' },
  ],
};

import flatten from 'flat';

import downloadreport from './downloadreport';
import home from './home';
import header from './header';
import letsfintech from './letsfintech';
import startups from './startups';
import footer from './footer';
import media from './media';

export const messages = {
  title: 'StartUp The Bank',
  description: `Poznaj 100 startupów współpracujących z bankami w Polsce. Dowiedz się czym się zajmują, na czym zarabiają i jak budują relacje z sektorem finansowym.`,
  keywords: ` raport, fintech, bank, startup, innowacje, inwestycje, corporate venture capital, cvc, vc, technologie finansowe`,
  home,
  downloadreport,
  letsfintech,
  startups,
  footer,
  media,
  header,
  404: {
    header: `404 Strona nie istnieje`,
    text: `Przepraszamy. Strona o podanym adresie nie istnieje.`,
    button: `Powrót do strony głównej`,
  },
};

export default flatten(messages);

export default {
  sharePopupHeader: 'Podziel się Raportem',
  nav: [
    `Intro`,
    `Wyniki Badania`,
    `Opinie Ekspertów`,
    `Wywiady ze startupami`,
    `Startupowa lista życzeń`,
    `Founder radzi`,
    `Poznaj 100 startupów`,
    `Autorzy raportu`,
  ],
  welcomeSection: {
    peak: `Startupy, które zmieniają polską bankowość`,
  },
  informationSection: {
    id: `wyniki-badania`,
    header: {
      main: `Czy wiesz, że...`,
      subtitle: `najważniejsze wyniki badania`,
    },
    items: [
      {
        header: `<hlgblue>7</hlgblue><hsmgray>na</hsmgray><hlggray>10</hlggray><hblack>badanych</hblack>`,
        text: `Startupy w&nbsp;Polsce coraz lepiej potrafią i&nbsp;coraz bardziej chcą pracować z&nbsp;dużymi
      instytucjami finansowymi, nawet jeśli nie są klasycznymi fintechami: <strong>już 7 na 10
      badanych przez nas firm zarabia na kontraktach z&nbsp;korporacjami z&nbsp;tej branży</strong>.`,
      },
      {
        header: `<hsmgray>aż</hsmgray><hlgblue>43</hlgblue><hblack>proc.</hblack>`,
        text: `Większość spółek technologicznych w&nbsp;początkowej fazie relacji biznesowej stawia na
      jakość, a&nbsp;nie na ilość: dlatego <strong>aż 43 proc. z&nbsp;nich ma maksymalnie 3</strong> klientów z&nbsp;branży
      finansowej.`,
      },
      {
        header: `<hsmgray>Co</hsmgray><hlgblue>4</hlgblue><hblack>młoda firma</hblack>`,
        text: `Jednocześnie dla <strong>co czwartej</strong> młodej firmy punktem zaczepienia w&nbsp;relacjach z&nbsp;dużym
      partnerem jest <strong>udział w&nbsp;programach akceleracyjnych</strong>. Kluczowe są również teamy ds.
      innowacji, będące partnerami dla 70 proc. zbadanych przez nas firm
      współpracujących z&nbsp;bankami.`,
      },
      {
        header: `<hlgblue>70</hlgblue><hsmgray>proc.</hsmgray>`,
        text: `<strong>Jak się „włamać” do banku?</strong> Najskuteczniejszy sposób to pilotaże i&nbsp;testy. Ponad 70
      proc. pilotaży na linii startup-bank kończy się komercyjnym kontraktem.`,
      },
      {
        header: `<hlgblue>3</hlgblue><hblack>Kluczowe obszary</hblack>`,
        text: `To, że nie trzeba być fintechem, aby zostać partnerem technologicznym banku,
      potwierdzają 3&nbsp;kluczowe obszary wdrożeń polskich startupów: <strong>analiza danych,
      optymalizacja procesów wewnętrznych oraz obsługa klienta</strong>.`,
      },
      {
        header: `<hsmgray>Jakie</hsmgray><hlgblue>?</hlgblue><hblack>technologie</hblack>`,
        text: `Na jakie technologie stawiają startupy? Tu królują <strong>API i&nbsp;mobile</strong>, podium zaś
      uzupełnia wyraźnie mniej popularny blockchain.`,
      },
      {
        header: `<hlgblue>48</hlgblue><hsmgray>proc.</hsmgray><hblack>wskazań</hblack>`,
        text: `Powiew świeżości czuć w modelach rozliczeń z&nbsp;klientami, gdzie zauważalną pozycję
      mają revenue share (<strong>stosowany przez niemal co 4. młodą firmę</strong>) oraz subskrypcja
      (<strong>48 proc. wskazań</strong>).`,
      },
      {
        header: `<hblack>ponad</hblack><hlgblue>40</hlgblue><hsmgray>proc.</hsmgray>`,
        text: `Efekt nowości widać także gdzie indziej – badani przez nas liderzy sceny startupowej,
      współpracujący z&nbsp;branżą finansową, wierzą, że najlepsze dopiero przed nimi. W końcu
      ponad <strong>40 proc. z&nbsp;nich ocenia, że ich rozwiązanie to „must have” dla banków</strong>.`,
      },
      {
        header: `<hlgblue>&amp;</hlgblue><hsmgray>mierzenie się</hsmgray><hblack>z wyzwaniami</hblack>`,
        text: `Z&nbsp;drugiej strony, firmy wskazują, że droga do osiągnięcia wielkości w&nbsp;finansach to
      także mierzenie się z&nbsp;wyzwaniami, wynikającymi z&nbsp;<strong>długiego procesu decyzyjnego i&nbsp;złożonej
      struktury organizacyjnej</strong> banków oraz z&nbsp;barierami wynikającymi z&nbsp;wysokich
      wymagań i&nbsp;skomplikowanych procedur panujących w&nbsp;korporacjach finansowych.`,
      },
      {
        header: `<hlgblue>17</hlgblue><hsmgray>proc.</hsmgray><hblack>firm</hblack>`,
        text: `Dlaczego warto w&nbsp;tę podróż wyruszyć? Bezpośrednie korzyści finansowe wskazuje
      zaledwie 17 proc. firm. Ważniejsze są <strong>korzyści wizerunkowe czy dostęp do bazy
      klientów oraz feedbacku</strong> od profesjonalnego partnera. Wniosek nasuwa się sam:
      polskie startupy w&nbsp;relacji z&nbsp;branżą finansową są już znacznie dojrzalsze, niż
      wskazywałaby na to ich metryka.`,
      },
    ],
  },
  expertsSection: {
    id: `opinie-ekspertow`,
    header: `Co mówią eksperci...`,
    items: [
      {
        title: `Globalna ekspansja to wyzwanie dla polskich startupów`,
        authors: [`Michał Kramarz`],
        company: `Google for Startups`,
        excerpt: `O „bardzo dużym potencjale” Europy Środkowo-Wschodniej mówi się już prawie cały
czas, zaznaczając, że to Polska zajmuje w nim uprzywilejowaną pozycję.`,
        intro: `Na świecie jesteśmy znani jako kraj dysponujący ogromnym talentem i umiejętnościami
technologicznymi. Dowodów nie brakuje: polscy programiści od lat doceniani są za pracę nad
najbardziej zaawansowanymi technologiami – w międzynarodowych firmach lokujących swoje
centra badawcze w Polsce, w lokalnych championach sektora IT, ale także właśnie w startupach.
Jednak w przypadku tych ostatnich talent i umiejętności to tylko niektóre z wielu składowych
sukcesu na globalnych rynkach. Widać, że mamy tu jeszcze wiele do zrobienia. Połączenie
technologii z biznesem, zarządzanie projektami czy umiejętność budowania różnorodnych
zespołów wciąż stanowią wyzwanie dla wielu polskich startupów. Widzimy to na co dzień,
prowadząc w Google inicjatywy wspierające rozwój tego typu firm – właśnie z tego typu
barierami biało-czerwone startupy mierzą się najczęściej.`,
        sections: [
          {
            title: `Recepta na zagranicę`,
            content: `W myśleniu globalnym polskich firm kluczowym czynnikiem jest nie tylko doskonały
technologicznie produkt. To także kwestia rozwoju firmy w oparciu o zróżnicowany zespół, różne perspektywy i doświadczenia – wtedy również nasz produkt będzie dużo bardziej
uniwersalny. Dlatego też np. w społeczności Google for Startups w Warszawie już 35 proc.
członków to kobiety, a 32 proc. osób pochodzi z zagranicy. Wierzymy, że różnorodność ekip
tworzących startupy jest niezbędnym motorem napędowym dla młodych biznesów. Kolejnym
ważnym elementem jest wybór rynków, na których startupy chcą oferować swoje produkty lub
usługi. Nie zawsze wskazanie najbliższych czy najlepiej znanych rynków, takich jak kraje UE
czy Stany Zjednoczone, to idealne rozwiązanie. Warto patrzeć szerzej, np. na znacznie
dynamiczniej rozwijające się rynki w Azji i Ameryce Południowej.

Według badań przeprowadzonych do tego raportu aż 56 proc. startupów znad Wisły oferuje
swoje usługi i produkty na zagranicznych rynkach. W tym kontekście cieszy pojawienie się w
TOP 10 rynków takich krajów, jak: Zjednoczone Emiraty Arabskie czy Singapur. Oby takich
kierunków rozwoju było coraz więcej, ponieważ te rynki oferują najczęściej ogromny potencjał
przy często niższych barierach wejścia.`,
          },
          {
            title: `Koinwestycje w doświadczenie`,
            content: `Wreszcie – finansowanie ekspansji. Pomimo widocznego apetytu polskich startupów na rozwój
za granicą, aż 82 proc. z nich deklaruje, że finansuje się głównie kapitałem pochodzenia
polskiego. To oznacza, że nasz rynek w kontekście inwestycji wciąż jest na wczesnym etapie
rozwoju. Dlaczego? Ponieważ brakuje nam m.in. doświadczenia inwestorów w skalowaniu
biznesów na skalę międzynarodową. Nasz rynek inwestycyjny, a w szczególności jego prywatna
część, jest nadal w trakcie tworzenia swojego portfolio. By wskoczyć – wraz ze startupami – na
globalny poziom, zarówno inwestorzy, jak i przedsiębiorcy powinni zabiegać o koinwestycje
polskich funduszy VC z zagranicznymi. To też m.in. jedna z misji Google for Startups w Polsce.
Jesteśmy przekonani, że tego typu inwestycje dadzą polskiemu rynkowi dostęp do
doświadczenia oraz sieci kontaktów z atrakcyjnych rynków. A to pozwoli jeszcze lepiej
wykorzystać ogromny potencjał technologiczny startupów znad Wisły.`,
          },
        ],
      },
      {
        title: `CVC ruszają z ofensywą w Polsce`,
        authors: [`Radosław Kwiatkowski`],
        company: `PKO VC`,
        excerpt: `„Element związany z finansowaniem to jedna z głównych ról zarządzających
startupami. Pieniądze trzeba cały czas pozyskiwać” – stwierdził jeden z założycieli
startupu z sektora fintech w rozmowie z autorami tego raportu.`,
        intro: `Mimo że własny kapitał to wciąż główne źródło finansowania na starcie, to ta nieodosobniona
opinia pokazuje, jak kluczową rolę w polskim ekosystemie młodych spółek technologicznych
odgrywają inwestorzy, gotowi podjąć duże ryzyko w zamian za perspektywę jeszcze większej
nagrody. Fundusze typu CVC są właśnie w tej grupie i – w co bardzo wierzę – najlepsze mają
dopiero przed sobą. Polski rynek VC/CVC wciąż uczy się dobrych praktyk,
rozpowszechnionych już w Stanach Zjednoczonych czy Europie Zachodniej, nabierając
doświadczenia. Świadczą o tym twarde dane z raportu: 47 proc. startupów deklaruje, że
posiada finansowanie od VC i CVC, ale tylko 29 proc. startupów twierdzi, że największy
udział w jego finansowaniu ma właśnie tego typu inwestor.`,
        sections: [
          {
            title: `Finansowanie od VC i CVC`,
            content: `Jednocześnie świadomy popyt na finansowanie od funduszy VC/CVC nad Wisłą wciąż
rośnie: z rozmów ze startupami wynika, że spółki dostrzegają coraz większą wartość w
dobraniu odpowiedniego partnera przy skalowaniu swojej firmy. Rozumieją doskonale, że
rolą finansowania VC jest nie tylko zapewnienie „paliwa” dla przedsiębiorstw na wczesnym
etapie rozwoju, tj. wtedy gdy potrzeby kapitałowe i potencjał wzrostu są wysokie. Kapitałem
od dobrego inwestora jest także wniesienie tzw. smart money: wsparcia merytorycznego, sieci
kontaktów czy dostępu do aktywów lub technologii. Oczywiście, finansowanie VC w
startupie to też sfera strategiczna i mentalna – wymaga od założycieli obrania drogi
dynamicznego wzrostu wartości kosztem ich udziałów w firmie i założenia potencjalnego
wyjścia z inwestycji razem z inwestorem VC.`,
          },
          {
            title: `Struktura współpracy i inwestycji`,
            content: `Na tym tle ofensywa CVC, których powstaje coraz więcej, jest nieprzypadkowa. CVC to
bowiem przemyślana struktura współpracy i inwestycji dużej korporacji z ekosystemem
startupów, jedna z kilku równoległych ścieżek szybkiego wzrostu (obok np. akceleratorów).
Dobry fundusz CVC dba o to, aby proces inwestycyjny, warunki umowne oraz późniejsza
współpraca, z punktu widzenia startupu, przebiegały na wzór najlepszych praktyk znanych z
funduszy VC. Przykładowo w przypadku grupy PKO Banku Polskiego, jako wydzielona i
niezależna jednostka, jesteśmy w stanie szybko podejmować decyzje inwestycyjne. Stawiamy
również na większą elastyczność co do okresu i wielkości inwestycji, kolejnych rund oraz
pomocy w materializacji synergii z bankiem. Ta ostatnia cecha to kluczowa przewaga CVC –
każdy startup, celujący choćby w sektor finansów, życzyłby sobie uproszczonej ścieżki w
procesie wdrożenia komercyjnego np. w największej grupie bankowej w Polsce. W takim
właśnie modelu PKO VC, wspólnie z Alior Bankiem oraz BNP Paribas oraz dwoma
lokalnymi funduszami VC, zainwestowało w ostatnim czasie w Autentii – polski fintech
oferujący platformę do elektronicznego podpisywania umów i cyfrowego obiegu
dokumentów. To pierwsza tego typu transakcja banków w regionie Europy Środkowo-
Wschodniej, która pokazuje w dodatku zdolność do podejmowania niestandardowych działań
na naszym rynku.`,
          },
          {
            title: `CVC w Polsce`,
            content: `Czego zatem szukają bankowe CVC w Polsce? Chętnie inwestują w projekty strategiczne z
segmentów fintech czy insurtech, mające bezpośrednie przełożenie na potencjalne wdrożenie
w strukturach inwestora. Jednak wzorem klasycznych VC na radarze są również projekty z
innych segmentów, np. marketplace, SaaS czy martech, niemające bezpośredniego potencjału
realizacji synergii z bankiem. Co ważne, w obu przypadkach, oprócz klasycznych
fundamentów takich jak: rynek, produkt, klienci itp., na decyzję inwestycyjną wydatnie
wpływa jeszcze jeden element, zyskujący szybko na znaczeniu. To kompetencje, wizja i
motywacja zespołów – mądre CVC inwestują przede wszystkim w ludzi.`,
          },
        ],
      },
      {
        title: `Rozwiązania chmurowe w polskim ekosystemie startupów`,
        authors: [`Michał Potoczek`],
        company: `Operator Chmury Krajowej`,
        excerpt: `Sektor finansowy, zwłaszcza bankowy, w Polsce jest jedną z najbardziej otwartych na
innowacje branż. Nowe technologie w bankowości mogą być jeszcze dostępniejsze i
powszechniejsze dzięki chmurze obliczeniowej.`,
        intro: `Dotychczas banki korzystały z jej możliwości w umiarkowanym zakresie, ale ten trend
wyraźnie się zmienia. Pomysł powołania Operatora Chmury Krajowej zrodził się przecież
w PKO Banku Polskim i wynikał z analizy jego własnych potrzeb. W coraz bardziej
scyfryzowanym świecie zamiast uczestniczyć w nierównej walce o klienta z
technologicznymi gigantami, lepiej nawiązać z nimi współpracę i sięgnąć po
wykorzystywane przez nie narzędzia, dodatkowo angażując do tego procesu energię i
pomysły finansowych startupów. Z analiz McKinsey’a wynika, że startupy w przeważającej
większości nie rozważają budowania własnej infrastruktury i wdrażania oprogramowania w
klasycznym modelu – to chmura obliczeniowa jest w ich przypadku pierwszym, naturalnym
wyborem. Jak pokazują wyniki tego badania, 92 proc. startupów już teraz deklaruje, że korzysta z rozwiązań chmurowych.`,
        sections: [
          {
            title: `Regulacje a rozwiązania chmurowe`,
            content: `Do niedawna możliwości wykorzystania technologii chmurowych były dla banków mocno
ograniczone, głównie ze względu na obowiązujące regulacje. Sytuacja się jednak
dynamicznie zmienia, a nadzór finansowy otwiera się na rozwiązania oparte na cyfrowej
bankowości. Odczuwalne już na rynku wsparcie kluczowego regulatora może
zdecydowanie przyspieszyć cyfrową transformację sektora. W tym procesie ważną rolę
odgrywa też Chmura Krajowa, która jako jedyna zapewnia pełną zgodność regulacyjną z
wymogami polskiego regulatora. Ta gwarancja pozwala na szybszy i łatwiejszy rozwój
cyfrowych usług w tak drażliwych regulacyjnie sektorach, jak np. bankowość.`,
          },
          {
            title: `Chmura Krajowa`,
            content: `W tym kontekście Chmura Krajowa znalazła swój przepis na połączenie świata korporacji i
startupów, budując dedykowane rozwiązania dla obu grup. Mogą to być np. środowiska
deweloperskie, do wypracowywania poprzez testy nowych rozwiązań przez startupy choćby
dla banków (dzieje się to np. w ramach Let’s fintech with PKO Bank Polski!). Dzięki tego
typu platformom startupy mogą budować i testować oprogramowanie w ramach
funkcjonującej już infrastruktury IT. OChK planuje też premiery dedykowanych
sandboksów – pierwszym tego typu projektem ma być uruchomiona jeszcze w 2020 r.
piaskownica regulacyjna blockchain. Będzie to hostowana w chmurze sieć, która umożliwi
różnym partnerom, w tym startupom, pracę na tej samej platformie, bez konieczności
ponoszenia dużych kosztów na zakup infrastruktury blockchain. Pozwoli ona także na
tworzenie i testowanie usług, systemów lub produktów z wykorzystaniem tej technologii.`,
          },
          {
            title: `Rozwiązania dla startupów`,
            content: `Chmura oznacza zdjęcie z barków młodych firm konieczności posiadania kosztownej
infrastruktury IT, by móc świadczyć usługi np. dla milionów klientów banków, które chcą z
nimi współpracować. Możemy też zapewnić startupom szybki i łatwy dostęp do
najnowocześniejszej technologii, z której korzystają i duże przedsiębiorstwa, i najszybciej
rozwijające się startupy na całym świecie. Oferujemy zarówno infrastrukturę fizyczną, jak i
platformy aplikacyjne, w tym rozwiązania Google Cloud. To zaawansowane technologie,
produkty i narzędzia analityczne oparte na wiodącej infrastrukturze technicznej,
zapewniające moc obliczeniową i usługi przechowywania danych. Mowa m.in. o: Compute
Engine, App Engine, Google Kubernetes Engine, Cloud Bigtable, Cloud Spanner czy
BigQuery. Co jeszcze możemy dać startupom dążącym do szybszego rozwoju? Nie tylko
infrastrukturę, lecz także mentoring czy pakiet regulacyjny. Te komponenty również mogą
okazać się bardzo potrzebne na wyboistej drodze do komercjalizacji pomysłów
biznesowych młodych firm technologicznych.`,
          },
        ],
      },
      {
        title: `Venture Building – wspólny język banków i innowatorów`,
        authors: [`Bartosz Sawicki`],
        company: `The Heart`,
        excerpt: `Korporacje szczycą się swoimi doskonałymi procesami, minimalizując w ten sposób
ryzyko i zwiększając efektywność. I w takie też tryby chciałyby zaprząc startupy, do
których te sztywne ramy często zwyczajnie nie przystają.`,
        intro: `Potwierdzają to liczby: badanie PKO Banku Polskiego ujawnia wiele problemów, z którymi
spotykają się młode firmy, próbując nawiązać współpracę bezpośrednio z wielką korporacją.
Już sam proces decyzyjny jest oceniany jako bardzo długi przez niemal połowę
respondentów. Inne istotne przeszkody w prowadzeniu tego typu projektów to m.in.:
biurokracja, mnożące się bariery prawne, zacofanie technologiczne dużych firm oraz brak
zaufania z ich strony. Czy zatem korporacje powinny zmienić swoje podejście?`,
        sections: [
          {
            title: `Energia i potrzeba`,
            content: `Z punktu widzenia najlepszych na świecie i w Polsce korporacji ta zmiana ma miejsce. Duże
firmy wydzielają w swoich szeregach wyspecjalizowane komórki, będące skrzynką
pierwszego kontaktu w przypadku budowania biznesowych relacji za startupami. Część
dużych graczy po prostu inwestuje kapitał w najciekawsze rozwiązania. Jednak co zrobić,
gdy np. pojedynczy startup nie jest oczekiwanym remedium, gdy potrzebą wielkiej instytucji
finansowej jest złożona usługa, oparta na wieloetapowym procesie, wymagającym kilku
różnych technologii od kilku firm? Tu sprawy się komplikują. W takich przypadkach niemal
niemożliwe jest już przekonanie „wszystkich świętych” w korporacji do zasadności tak
złożonych wdrożeń na własną rękę. Zlecenie tego typu projektów zewnętrznym firmom
informatycznym brzmi kusząco, lecz w praktyce nie jest tak różowo, bo… mało kto jest
gotów podpisać się pod decyzją dużego przedsiębiorstwa. Menedżerowie nie chcą brać na
siebie całej odpowiedzialności za ewentualne niepowodzenie i pieniądze wydane na
inicjatywy o takim charakterze. Stąd też wysiłki działów innowacji oraz innowatorów, by
wdrażać np. takie modele wynagrodzenia, jak udział w przychodach (revenue share) czy
monetyzacja danych. Wskazuje na to już ponad 30 proc. spółek objętych niniejszym
raportem. Wciąż pozostają jednak potrzeba pozyskiwania innowacji szytych na miarę przez duże firmy i niezaspokojony głód startupów szukających potężnych partnerów.`,
          },
          {
            title: `W rytmie pit stopów`,
            content: `Czy istnieją jeszcze jakieś skuteczne alternatywy? Okazuje się, że oba światy mogą znaleźć
punkty styczne nie tylko w zespołach ds. innowacji banków czy działach rozwoju biznesu.
Jedna z możliwości pozwalających dostarczać korporacjom projekty w najlepszej możliwej
cenie, czasie i na najlepszym poziomie bezpieczeństwa to model Venture Building. W takim
schemacie to zewnętrzny partner niejako buduje startup dla korporacji, dostarczając
niezbędne do tego zaplecze metodyczne, zasoby organizacyjne czy relacje z innymi firmami
technologicznymi. Działa niczym ekipa na pit stopach w Formule 1 – prowadząc
profesjonalne działania na rzecz małych, zwinnych zespołów założycielskich. Lada miesiąc
pierwsze projekty tego typu w Polsce zadebiutują w korporacyjnych murach i przy wsparciu
zespołów z korporacji w niedługim czasie mogą stanowić ciekawy kąsek inwestycyjny dla
funduszy typu CVC. Oczywiście nie jest to jedyny słuszny sposób: każdy startup i duża firma
muszą same rozpoznać, która metoda współpracy jest dla nich najlepsza. Ich wspólną dewizą
musi być jednak przekonanie, że zarówno innowatorzy, jak i banki nie mogą okopywać się na
swoich pozycjach, rezygnując z dostępnych narzędzi i mechanizmów spajających obie
strony.`,
          },
        ],
      },
      {
        title: `Głodni innowacji i otwarci na startupy`,
        authors: [`Joanna Misiewicz`, `Przemek Kondraciuk`],
        company: `PKO Bank Polski`,
        excerpt: `FOMO (fear of missing out), czyli obawa przed przegapieniem czegoś istotnego – tak
jeden z badanych startupowców zdiagnozował przyczynę coraz większej otwartości
sektora finansowego na młode spółki technologiczne.`,
        intro: `Z raportu wynika, że coś w tym jest – branża finansowa w Polsce jest głodna innowacji jak
niemal żadna inna. Ten apetyt jest jednak przemyślany i wsparty przez gamę skutecznych
narzędzi. Pokazują to cyfry – startupy znad Wisły doceniają działania banków w zakresie
dedykowanych im rozwiązań. 25 proc. z pracujących z bankami firm wskazuje, że sprzedaje
swoje rozwiązania do instytucji finansowych właśnie poprzez specjalne programy współpracy
ze startupami. Ponadto 70 proc. firm deklaruje, że w finansach po stronie korporacyjnej
najczęściej współpracuje z zespołami ds. innowacji. To już standard dla innowacyjnych
banków. Dlaczego? Ponieważ specyfika działania tych instytucji sprawia, że wdrożenie tam
rozwiązania startupu wiąże się z wieloma wyzwaniami.`,
        sections: [
          {
            title: `Współpraca szyta na miarę`,
            content: `Główny „nasz” – bankowy – grzech to długi proces decyzyjny. Nie bez znaczenia jest także
wysoki stopień uregulowania rynku, a w efekcie – wyśrubowane wymagania stawiane
partnerom zewnętrznym. To wszystko sprawia, że każda współpraca ze startupem to
żonglerka oczekiwaniami biznesowymi, prawnymi czy związanymi z cyberbezpieczeństwem.
Dlatego znaczenia nabiera rola zespołów innowacji, działających dla startupu jako
przewodnik po meandrach bankowości i zakamarkach organizacji, sprawiający, że finalnie
obie strony mówią tym samym językiem. Tacy ludzie to też niejako pierwszy zespół
sprzedażowy startupu w banku. Wdrażanie innowacji wspólnie ze startupami wymaga
bowiem niestandardowego, nowego dla banków podejścia. Zazwyczaj szytego na miarę.
Efekt? Aż 87 proc. startupów współpracujących z instytucjami finansowymi ma za sobą co
najmniej jeden pilotaż w banku. Taką ścieżkę budowy dedykowanego procesu dla startupów
podjęliśmy w PKO Banku Polskim, tworząc program partnerstw pod nazwą Let’s Fintech
with PKO Bank Polski! Bierzemy na siebie rolę przewodnika, tłumacza i biznesowej swatki
dla rozpoczynających z nami współpracę młodych firm technologicznych, pozwalając im
testować i pilotować swoje rozwiązania wspólnie z bankiem.`,
          },
          {
            title: `Startupowe must have`,
            content: `A co myślą i mówią startupy, wchodząc w biznesową interakcję z bankami opanowanymi przez FOMO? Otóż coraz lepiej rozumieją swoją wagę dla sektora finansowego i potrafią się cenić. Ponad 40 proc. firm współpracujących z bankami uważa, że ich rozwiązania to wręcz
must have dla rynku finansowego. Ich zespoły świetnie rozumieją, że banki nie konkurują już
tylko ofertą i tylko między sobą. Napór ze strony GAFA, challengerów czy właśnie startupów
zmienia banki na zewnątrz i od środka. Jednym z przejawów tej metamorfozy są więc
rozwijane partnerstwa ze spółkami technologicznymi. Współpraca ta najczęściej odbywa się
w formule B2B i, jak pokazuje badanie, najczęściej dotyczy takich obszarów, jak: analiza
danych, optymalizacja procesów wewnętrznych czy obsługa klienta. Dużo trudniejszą decyzją
– przynajmniej dla banku – jest nawiązanie strategicznego partnerstwa z takimi firmami. Z
reguły dotyczy ono startupów działających w modelu B2C – dla banku oznacza to więc
niejako rezygnację z bezpośredniej relacji z klientem, szczególnie jeśli współpraca wkracza w
ich podstawową działalność (np. prowadzenie rachunków czy udzielanie pożyczek).`,
          },
          {
            title: `Apetyt rośnie w miarę jedzenia`,
            content: `Jak wynika z deklaracji startupów, w praktyce współpraca dużych z małymi to kręta ścieżka.
Jednak mało kto z niej schodzi – korzyści z takich bankowo-startupowych duetów są jasne dla
obydwu stron. Banki mogą szybciej wdrażać innowacje w obszarach, w których nie mają
doświadczenia. Natomiast „młodzi gniewni” doceniają nie tylko pieniądze – za główną
wartość współpracy z bankami wskazują zdobytą wiarygodność i referencje. W myśl zasady,
że skoro zaufał nam bank, to rynek tym bardziej.`,
          },
        ],
      },
      {
        title: `W stronę dobrego prawa`,
        authors: [`Piotr Brewiński`, `Paweł Widawski`],
        company: `Fintech Poland`,
        excerpt: `Czy prawo może stymulować rozwój startupów finansowych w Polsce? Odpowiedź na to
pytanie nie jest jednoznaczna, choć z reguły przedstawiciele rynku traktują przepisy
jako barierę.`,
        intro: `„Niektóre polskie regulacje są wyjątkowo dziwne i bardzo wymagające, zmniejszają
otwartość rynku na nowe technologie. Dlatego niektóre elementy fintechu, które praktykuje
się w Stanach, w Izraelu czy w Azji, są u nas po prostu niemożliwe, właśnie z racji na
ograniczenia prawne” – podsumowuje jeden z przedstawicieli startupów, z którym
przeprowadzono wywiad na potrzeby niniejszego raportu. Jego słowa potwierdzają inni
reprezentanci branży, wskazując, że ich innowacyjna działalność często nie mieści się w
powszechnie obowiązujących ramach prawnych, a jeśli coś nie jest jasno zdefiniowane, to
wpada do „szarej strefy regulacyjnej”. Potwierdzają to także liczby – 45% startupów objętych
badaniem postrzega regulacje prawne jako jedną z trzech głównych barier prowadzenia ich
działalności.`,
        sections: [
          {
            title: `Regulacje prawne a powodzenie projektu`,
            content: `Częste zmiany zachodzące w warstwie regulacyjnej wprost przekładają się na szanse
powodzenia projektu, a to z kolei bezpośrednio wpływa na ocenę ryzyka przez inwestorów i
możliwość pozyskania od nich kapitału. Przykład? Słynne RODO z jednej strony zaspokaja
istotną potrzebę ochrony danych osobowych konsumentów, ale z drugiej strony wprowadza
ograniczenia nieznane rynkom finansowym spoza Unii Europejskiej. Efekt? To właśnie brak
restrykcyjnych regulacji na polu przetwarzania danych, przy całym ryzyku z tym związanym,
stał się podstawą szybkiego wzrostu innowacji finansowych m.in. w Azji, zapewniając im
istotną przewagę konkurencyjną. Z punktu widzenia projektów realizowanych w Polsce
dochodzi jeszcze kwestia tzw. gold platingu, czyli nakładania na polskich dostawców usług
finansowych wymogów idących dalej, niż ma to miejsce na mocy regulacji europejskich. W
takich przypadkach, jeśli startup dostaje już swoją szansę w korporacji, to konieczność
pogłębionej analizy skutków współpracy i potencjalnego ryzyka prawnego sprawia, że proces
decyzyjny po stronie dużych instytucji finansowych dłuży się – wskazuje na to aż 41%
badanych startupów.`,
          },
          {
            title: `Rozwój rynku`,
            content: `Narzekając na ciasny, regulacyjny gorset, nie można pominąć dowodów na to, że obiektywnie
patrząc, regulacje potrafią stymulować rozwój rynku. Weźmy choćby dyrektywę PSD2, w jej
części dotyczącej otwarcia dostępu do rachunków płatniczych dla podmiotów trzecich.
Przepisy te ze względu na swój otwarty charakter stymulują rozwój rynku poprzez
pobudzenie konkurencyjności. A to przecież najlepsza możliwa pożywka dla startupów.
Efekt? Mimo licznych wyzwań prawnych, z którymi rynek fintech mierzy się na co dzień,
startupy przenikają branżę finansową na wskroś. Wchodzą w bankowość detaliczną, przez
usługi płatnicze, aż po rynek kapitałowy i ubezpieczeń. Banki nauczyły się też
współpracować ze startupami tak, aby możliwie sprawnie oceniać potencjalne ryzyko prawne,
a ich akceleratory stają się dziś ważnymi centrami rozwoju sektora finansowego w warstwie
technologicznej i biznesowej.`,
          },
        ],
      },
    ],
  },
  startupDialogSection: {
    id: `wywiady-ze-startupami`,
    header: `Co mówią startupy...`,
    items: [
      {
        title: `Rewolucja napędza się sama`,
        subtitle: `Wywiad z Tomaszem Plata`,
        author: `Tomasz Plata`,
        company: `Autenti`,
        questions: [
          {
            question: `Autenti to – jak na świat fintechu – niemal dinozaur.`,
            answer: `Powstaliśmy rzeczywiście przed fintechowym boomem w Polsce, już w 2012 r. Założycielem i pomysłodawcą był Grzegorz Wójcik, z którym znamy się od dwóch dekad. To Grzegorz stwierdził – pracując jeszcze w korporacji – że trzeba skutecznie „odpapierzyć” biznes. To był czas, gdy idea paperless święciła triumfy za oceanem, tymczasem w Polsce grzęźliśmy w drukarkach i w fizycznym, tzw. atramentowym, podpisie. Grzegorz wierzył w podpis elektroniczny i we wzrost zaufania do cyfrowych dokumentów. Skrzyknął więc kilku sprawdzonych w biznesie znajomych menedżerów do projektu Autenti. W 2014 r. uruchomiliśmy na rynku naszą platformę w wersji proof-of-concept.`,
          },
          {
            question: `Co stanowi o waszej unikalności?`,
            answer: `Zbudowaliśmy platformę do podpisywania dokumentów online, która działa na styku technologii, przepisów prawa i jeszcze jednego, kluczowego dla nas elementu – innowacji. Nadawca dokumentu wysyła go elektronicznie dzięki Autenti, aby odbiorca, np. konsument, kontrahent biznesowy czy współpracownik, mógł ten dokument odebrać na swoim urządzeniu mobilnym lub komputerze i podpisać. Łatwo, szybko, a przede wszystkim skutecznie. Wszystkie te czynności są elektroniczne. No i odbywają się w zgodzie z naszym firmowym lejtmotywem – podpisywanie ma być tak proste, jak jedno kliknięcie, „one click e-signature”.`,
          },
          {
            question: `W 2012 r. takie zarządzanie dokumentacją brzmiało jak pieśń o przyszłości. Czuliście, że ryzykujecie wiele?`,
            answer: `Tak naprawdę to do 2016 r. byliśmy zaangażowani w Autenti na 50 proc., bo pracowaliśmy też w innych organizacjach, prowadziliśmy biznesy. To był ciągle taki test, eksperyment, mieliśmy wersję testową, robiliśmy wokół niej marketing, edukowaliśmy rynek. Wreszcie w 2016 r. przyszły korzystne dla nas zmiany w prawie na poziomie krajowym i europejskim, dotyczące podpisu elektronicznego. Do tego – nieco nieoczekiwanie – wygraliśmy w 2016 r. The Great Pitch Contest i nagrodę w wysokości 100 tys. USD podczas konferencji Wolves Summit, a chwilę później nasz projekt o wartości kilku milionów złotych dostał potężne dofinansowanie z NCBR. I w jednej chwili postanowiliśmy, że wóz albo przewóz. Zaangażowaliśmy się w Autenti na 100 proc. Z początkiem 2017 r. wrzuciliśmy drugi bieg, zbudowaliśmy zespół, skumulowaliśmy kluczowe kompetencje. Machina ruszyła pełną parą.`,
          },
          {
            question: `Pojawili się klienci?`,
            answer: `Tak, ale zanim do tego doszło, wykonaliśmy masę pracy, m.in. odrobiliśmy swoje lekcje i nawiązaliśmy kontakty dzięki takim akceleratorom, jak PwC Startup Collider czy Google Campus Warsaw Residency. Zaczęliśmy korzystać z ich wiedzy i prezentować swoje rozwiązanie przed kluczowymi menedżerami, np. z rynku finansowego. Wzięliśmy – z sukcesem – udział w hackathonie BNP Paribas. Tam nas zauważono, dzięki czemu po kilku miesiącach dostaliśmy zaproszenie do przetargu na wdrożenie rozwiązania w jednym z departamentów banku. Wygraliśmy go, a uznany gracz z bardzo wymagającego rynku bankowego zwalidował nasz produkt, płacąc za niego i używając go na co dzień.`,
          },
          {
            question: `Koncentrujecie się wokół dużych, korporacyjnych klientów?`,
            answer: `Stoimy na dwóch nogach: firmy z sektora MŚP są dla nas również naturalnym i dobrym klientem. Oczywiście, duży bank to tysiące dokumentów, potencjalne wdrożenia w kolejnych segmentach samego banku czy grupy kapitałowej, do której należy, świetne referencje ściągające inne firmy z branży, a także międzynarodowe perspektywy. Ale jednocześnie takim wdrożeniem trzeba na punktach styku mocno się zaopiekować, przeprocesować je, nierzadko w kilku scenariuszach. Duży klient wymaga też od nas personalizacji. To trwa i kosztuje. Warto więc patrzeć przychylnie także na mniejsze firmy, płacące mniej, ale i gotowe na rozwiązanie w 100 proc. w formule SaaS. Takie, w których proces decyzyjny liczony jest w godzinach, a nie w miesiącach.`,
          },
          {
            question: `Mówiłeś o długim czasie, jaki minął, zanim w 100 proc. zaangażowaliście się w Autenti. Skąd ten dystans na początku?`,
            answer: `W 2014 r. byliśmy gotowi z produktem i rzuciliśmy go szeroko na rynek, rozdając go w testowym okresie nawet za darmo. Usłyszeliśmy wiele „ale”, w tym to jedno główne, zakorzenione w mentalności – „my tego nie potrzebujemy, radzimy sobie po staremu, drukując na papierze”. Ta konfrontacja była więc na początku bolesna, wątpiliśmy, czy rynek jest gotowy na naszą usługę. Jeden z moich rozmówców, chyba chcąc mnie wyprowadzić z równowagi podczas rozmowy o Autenti, posadził mnie w pokoju pełnym drukarek… Zrozumieliśmy, że pomimo naszej pracy włożonej w produkt i dużej ekscytacji wciąż jesteśmy w połowie drogi. Postanowiliśmy jednak spróbować inaczej, znaleźć inny wytrych do firm. Mówienie językiem korzyści i uświadamianie ryzyka związanego z używaniem dokumentów drukowanych to był pierwszy element.`,
          },
          {
            question: `A kolejny?`,
            answer: `Nie była to niska cena; nawyki trudno zwalczyć jedynie ceną. Gdy już przekonaliśmy klienta do idei, trzeba było nam znaleźć jeszcze osobę, która po stronie organizacji zawalczyłaby o to wdrożenie, wniosła je do spółki niejako na swoich plecach. Nauczyliśmy się znajdować takich „ewangelistów zmian” w organizacjach, którzy mieli równolegle do czynienia z operacjami sprzedażowymi, obiegiem umów czy przygotowywaniem dokumentów w firmie. I to był strzał w dziesiątkę. Coraz częściej zdarza się nam dziś, że zaczynamy relacje z dużymi klientami od małych kroków, bezpiecznych dla organizacji, czyli od PoC-a – pilotażowego wdrożenia. Realizujemy go bardzo szybko, w dwa, trzy tygodnie maksymalnie, np. w obszarze B2E (business to employee) z dokumentami HRowymi. Zachęcamy bank, by przetestował rozwiązanie najpierw na swoich pracownikach lub współpracownikach, bo trzeba ich zatrudnić, dać obiegówki, podpisywać wnioski urlopowe, wysłać PIT-y itp.`,
          },
          {
            question: `Zaczynacie od małego kroku, ale tak naprawdę gotowi jesteście od razu na większy?`,
            answer: `Tak, jednak mamy w sobie pokorę. Praca z dużym partnerem uczy nas, że po naszej stronie musimy wymagać od siebie 110 proc., jeśli chcemy przestać nazywać siebie dalej startupem. Tu nie ma miejsca na błędy młodości. Procesowo, podejściem do klienta i zaawansowaniem produktu jesteśmy zatem już dojrzałą, dynamiczną firmą. Jeśli mówimy o sobie per startup to tylko dla korzyści marketingowych, w wyjątkowych sytuacjach. Dla nas jasne jest, że nikt z banku nie podpisze umowy z Autenti, dopóki nie będzie miał pewności, że dajemy odpowiednie gwarancje bezpieczeństwa, dostępności systemu, skuteczności działania. Nasza karta przetargowa to też doświadczenie zawodowe, wiek założycieli, relacje z rynkiem. Nasi klienci, tacy jak Vienna Life, Medicover czy Siemens, nie wzięli się z przypadku.`,
          },
          {
            question: `Z rozmów z innymi fintechami wynika, że potrzebują banków, a jednocześnie skarżą się na ich specyfikę działania, przewlekanie procesów w nieskończoność. Może nie potrafią zyskać sobie przychylności „ewangelisty”?`,
            answer: `„Ewangelista”, powiedzmy w banku, nie oznacza, że wdrożenie zadzieje się w tydzień czy miesiąc. Oznacza, że można w miarę żwawo animować proces, posuwać go do przodu. Widzimy, że banki chcą, potrzebują i szukają innowacji. A jednocześnie są, jak my ich nazywamy, mamutami.`,
          },
          {
            question: `Nie brzmi to jak komplement.`,
            answer: `To nie jest nasycone pejoratywnie. Mamuty, ponieważ są naprawdę duże, stąpają wolno, ostrożnie. Standardowe wdrożenie rozwiązania IT w banku to niemal standardowo pół roku, do roku. Jednak gdy już się uda uzyskać to zaangażowanie, idziemy razem na duże łowy – na rynek mający nawet setki tysięcy, a nierzadko miliony klientów. Choć wcześniej czeka nas jeszcze tzw. grillowanie.`,
          },
          {
            question: `Korporacyjny czyściec?`,
            answer: `Nie ma co przed nim uciekać. Warto przez niego przejść. Zdobycie dużego klienta z branży finansowej to wyczyn, precedens. Z całym szacunkiem dla pozostałych firm działających na rynku –jeśli bank mnie zwalidował, to wiem, że robię dobry biznes i wtedy inni też myślą tak o naszej usłudze. Skoro wszystkie działy prawne, compliance, technologiczne, a nawet KNF (ze swą listą wytycznych) zdecydowały, że w banku nie tylko chcą, ale i mogą korzystać z Autenti, to znaczy, że możemy z otwartą przyłbicą atakować najtrudniejsze twierdze.`,
          },
          {
            question: `Grillowanie nie bywa stratą czasu?`,
            answer: `Mamy cierpliwość i zrozumienie dla tego typu procesów, sami przecież pracowaliśmy w korporacjach. To jest ich styl, a często i wymóg działania. Trzeba pamiętać, by nie koncentrować się tylko na zdobyciu kontraktu z bankiem i na to przekierowywać wszystkie siły i środki. Na korpo się czeka, a to kosztuje. Zatem warto dywersyfikować swoją ofertę, sprzedając również usługi do MŚP. Do tego, na grillowanie, trzeba się przygotować – znać potencjalnego klienta od podszewki. Myśmy nawet podszywali się pod klientów banków, żeby zobaczyć jeszcze przed prezentacją, jak działają u nich różne „papierowe” procesy.`,
          },
          {
            question: `Zatem nie macie w stosunku do banków jako klientów większych wymagań?`,
            answer: `W sferze życzeń – owszem, choć staramy się być realistami. Na pewno brakuje u nich jeszcze większej otwartości na zmianę i traktowania mniejszych firm jak prawdziwych partnerów biznesowych. Innowacje z zewnątrz to przecież dla banków ważny element budowania przewagi konkurencyjnej, optymalizacji i poprawy wizerunku. Na szczęście tu widzimy duży progres w stosunku do tego, co było jeszcze kilkanaście miesięcy temu. Jest w finansach coraz więcej tzw. pozytywnych wariatów, stojących murem za nowymi technologiami. A przecież zazwyczaj fintechom wystarczy ledwie promień zielonego światła od korporacji. Na zasadzie – dobrze, macie trochę przestrzeni dla siebie na start, zróbcie pilotaż, uchylamy drzwi. To na początek wystarczy. Potem rewolucja napędza się już sama i przenosi na kolejne branże.`,
          },
        ],
      },
      {
        title: `Zaproszenie do gry w innowacje`,
        subtitle: `Wywiad z Anną Streżyńską`,
        author: `Anna Streżyńska`,
        company: `Carrotspot`,
        questions: [
          {
            question: `W waszej grupie jest kilka spółek technologicznych, w tym Carrotspot. Dlaczego w jego przypadku zaprzęgliście technologię akurat do HR-u?`,
            answer: `Było w tym – jak to w przypadku startupów bywa – trochę przypadku. Gdy rusza młoda firma technologiczna, to w dużym stopniu jej początkowym zajęciem jest szukanie modelu biznesowego, który się obroni i pozwoli jej odnieść sukces, oby jak największy. Często na początek sukcesem jest już samo przetrwanie. Stwierdziliśmy, że możemy zaoferować nietypowe usługi, mamy doświadczenie, konkretne osiągnięcia, nazwiska i relacje z rynkiem. A skoro nasz software house z grupy oferował wcześniej usługi w blockchainie, w sztucznej inteligencji i w IoT, to mogliśmy robić bardziej złożony produkt. Carrotspot dedykowaliśmy więc konkretnemu wycinkowi: HR-owi na poziomie zarządczym, menedżerom i wyspecjalizowanym działom w firmach. Czyli wszystkim tym, którzy rozumieją związek pomiędzy sukcesem firmy a dobrostanem zatrudnionych w niej pracowników.`,
          },
          {
            question: `Jak rozumiem, ten dobrostan nie oznacza co najwyżej zaawansowanej wersji kalkulatora wynagrodzeń i premii.`,
            answer: `Carrotspot gra w innej lidze. Powstał jako przeciwwaga do znanych wszystkim procesów motywowania pracowników – czyli dość prymitywnego skłaniania ich za pomocą benefitów do tego, żeby przez chwilę więcej pracowali i kręcili wyższe KPI. Powstał po to, by pracownicy rzeczywiście czuli się tak dobrze w firmie, żeby utożsamiać swój interes z interesem firmy. Dlaczego? Bo czują, że firma daje im możliwość zaspokojenia różnych życiowych aspiracji, nie tylko zarobienia większej liczby pieniędzy. Wtedy właśnie w pracownikach powstaje idealna kompozycja lojalności, kreatywności i wydajności. Zatem Carrotspot to platforma szukająca dla pracownika szytych na miarę programów motywacyjnych. Zresztą najpierw budowaliśmy ją z myślą o naszych pracownikach. Stąd też wzięła się np. tokenizacja, pozwalająca na natychmiastowe nagrodzenie i przypisanie nagrody do konkretnego zadania. W Carrotspot firma może sprawić, by nagrodą były dostępne jej zasoby, np.: miejsce parkingowe, sala konferencyjna na prywatny użytek czy ekstra dzień wolny, ale ogromny nacisk stawiamy na docenianie niematerialne.`,
          },
          {
            question: `Kto jako pierwszy, zewnętrzny klient podchwycił ten pomysł?`,
            answer: `Iwona Wencel, wiceprezes Wirtualna Polska Holding ds. HR. To Wirtualna Polska była naszym pierwszym testowym klientem, a Iwona Wencel poświeciła nam mnóstwo czasu, żeby pokazać całą mechanikę relacji w takiej złożonej firmie. To pozwoliło podnieść nasz produkt na nowy poziom. Ona też była pierwszym odbiorcą, czytelnikiem naszych pomysłów, namówiła nas na start w konkursie HR Tech, gdzie przebiliśmy kilkadziesiąt innych startupów i z pierwszego miejsca rozpoczęliśmy trzy pilotaże. Bardzo nam pomogła.`,
          },
          {
            question: `Pracujecie ze sobą do dziś?`,
            answer: `WP zdecydowała się na inną platformę, mającą benefity sportowe. Wybrane rozwiązanie było gotowe do pełnego wdrożenia, my dopiero „wygrzewaliśmy” całość. Dla nas benefitem był już sam fakt, że ktoś tak wysoko postawiony w korporacji i tak dobrze zorientowany w tematyce, a zarazem życzliwy, poświęcił nam tyle czasu. Wierzę, że w taki czy inny sposób będziemy w przyszłości jeszcze współpracować.`,
          },
          {
            question: `Wasza przyszłość to także więcej niż polski rynek?`,
            answer: `Zaczynamy penetrować rynki zagraniczne. Dostaliśmy możliwość rozmowy z wielkimi, naprawdę gigantycznymi korporacjami finansowymi typu J.P. Morgan czy Citi. Dyskutujemy z nimi na temat naszego produktu, dostajemy bardzo dużo konkretnych, kluczowych informacji zwrotnych, wskazówek, w jaki sposób globalizować. Bo on musi być uniwersalny, ale i dopasowany zarówno do różnic kulturowych, jak i prawnych. Z zagranicy słyszymy też, jaki tkwi w nas potencjał.`,
          },
          {
            question: `Wyróżniacie się?`,
            answer: `Tak. Zarówno na rynku, jak i w działach HR brakuje takich technologii jak nasza. Brakuje narzędzi naprawczych i zapobiegających rotacji, diagnozujących problem HR-owy, zanim dojdzie do jego eskalacji. W Carrotspot poszliśmy zdecydowanie dalej niż lokalna konkurencja. W Polsce większość rozwiązań koncentruje się na platformach benefitowych, na których można wybierać różne korzyści. Ale one nie pracują z człowiekiem. Tymczasem dla nas praca z człowiekiem to DNA. Tu korzystamy m.in. z eksperckiej wiedzy naukowców z Uniwersytetu SWPS. Oni pomagają nam konstruować ankiety, budować programy badawcze, diagnozujące potrzeby konkretnych firm. Ludzkie potrzeby. Czasem żartuję więc, że jesteśmy raczej firmą HR-ową, genialnie stosującą narzędzia cyfrowe.`,
          },
          {
            question: `Doceniają to także klienci z segmentu finansowego?`,
            answer: `Tak, to nasz bardzo ważny segment. A zarazem bardzo trudny.`,
          },
          {
            question: `Dlaczego ważny?`,
            answer: `Choć może tego na pierwszy rzut oka nie widać, instytucje finansowe mają wewnętrznie burzliwy okres, dotykają je zwolnienia na dużą skalę. Łączy się to m.in. z procesem cyfryzacji w finansach, nabiera on dynamiki, do tego dochodzą konsolidacja operacji typu back office i konsolidacja samych instytucji. Gdzie tu nasza rola? My pomagamy pracodawcy zapanować nad kryzysami, które dotykają zespoły objęte zmianami, zwolnieniami, restrukturyzacją. Pozytywne jest to, że w bankach ludzie dobrze rozumieją korzyści wynikające z takich narzędzi jak Carrotspot, pracownicy są świadomi i aktywni. Stawiają pracodawcy mądre wymagania. I nie są zamknięci na technologię.`,
          },
          {
            question: `A dlaczego zatem to trudny partner?`,
            answer: `No właśnie – technologia. Część struktur w bankach spowalnia wdrażanie innowacji technologicznych, wiążąc je z postępem automatyzacji procesów w tym sektorze i przyszłymi… zwolnieniami. Technologia też bywa blokadą z innej strony, bo my pracujemy w chmurze. A banki boją się chmury, KNF wydał swoje stanowisko na temat chmury w relacji z danymi klientów, zresztą wcale niezamykające rynku na chmurę, tylko stawiające wymagania. Tymczasem nasz produkt w ogóle nie dotyczy danych klientów, to są tylko wewnętrzne procesy bankowe. Zatem stanowisko KNF w ogóle go nawet nie dotyka. A mimo wszystko podpadamy pod masę procedur, ostrożnościowo, co oznacza długie miesiące pracy nad umową.`,
          },
          {
            question: `A jednak z nimi pracujecie. Korzyści przeważają nad kosztami.`,
            answer: `Z racji moich poprzednich zajęć dobrze znam specyfikę działania banków, ich wymogi formalne – poniekąd systemowo potrzebne –i wysoki poziom technologiczny. Instytucje finansowe oczekują rozwiązań na wysokim poziomie, m.in. UX-owym, nieodstającym od tego, co same proponują milionom klientów. W końcu technologicznie polskie banki to światowa elita, są liderami także pod kątem innowacyjności. Budują akceleratory, korzystają z blockchaina w praktyce, budują piaskownice regulacyjne, wyjęte z regulacji krajowej, gdzie można ćwiczyć różne rozwiązania i sprawdzać ich bezpieczeństwo, stabilność. Tu odbieramy na tych samych, blockchainowych falach.`,
          },
          {
            question: `Jakieś wskazówki dla bankowców, jak pogłębić relacje ze startupami?`,
            answer: `Po pierwsze – ludzie. Banki muszą edukować ludzi wprowadzających do środka innowacje z zewnątrz. Tak, aby prostować ścieżki startupom i samemu móc szybciej korzystać z benefitów technologii, zanim nie staną się one przestarzałe lub ich twórcy nie zakotwiczą się u konkurencji. Do tego dochodzi wspólnota celu: bardzo często spotykamy w bankach „hamulcowych” czy wrogie frakcje o przeciwstawnych celach. Naszym celem, jako startupu, jest skuteczne wdrożenie, a na to składają się np. testowanie produktu i dostęp do ludzi, którzy będą z niego korzystali, możliwości modyfikacji rozwiązania po ich feedbacku czy zmianach w regulacjach. Więc nie bójmy się zmian w projekcie, nawet tych nieplanowanych. A jednocześnie bądźmy gotowi, aby zapłacić za nie startupowi. Nie dobrym słowem, ale w złotówkach.`,
          },
          {
            question: `A w drugą stronę – jest coś niepoliczalnego, co startup może dać nie tylko bankom, lecz także korporacjom, dużym organizacjom?`,
            answer: `Świeżość, wdzierającą się do samego środka tych instytucji. Startupy niosą zdrowy ferment. Wysyłają jasne komunikaty: „Myśl inaczej!”, „Wyjdź z tej ciasnej procedury”, „A może wcale nie masz racji?”. Na początku to jest zawsze takie „a co on mnie będzie uczył, przecież ja w tym siedzę całe życie”. Startup wysyła do korporacji zaproszenie do przygody – to zawsze działa, nie tylko w sektorze bankowym. Trzeba wciągać ludzi w swoją grę i przygodę, ale nie dla samej gry, lecz dla konkretnych efektów, pozwalających zachować wysoki standard usług i nasze polskie liderowanie na rynku np. usług bankowych czy telekomunikacyjnych. Zaproponować im zarówno intelektualną przygodę, jak i późniejszy udział w wyniku. Tak żeby mogli się też nim cieszyć i być z niego dumni.`,
          },
        ],
      },
      {
        title: `W innowacjach lepiej przepraszać, niż prosić`,
        subtitle: `Wywiad z Mają Schaefer`,
        author: `Maja Schaefer`,
        company: `Chatbotize`,
        questions: [
          {
            question: `Zwykle zanim startupowe rozwiązanie zyska aprobatę dużego klienta, jego autorzy muszą się sporo napocić. Tymczasem wam poszło jak z płatka.`,
            answer: `W jakimś sensie tak, choć – pracując np. z branżą finansową w Polsce – dociera do nas, że proces decyzyjny to nierzadko długa i wyboista droga. Mimo to zaczęliśmy szybko i sprawnie. Już pod koniec 2017 r., gdy rozwijałam software house i dostarczałam dedykowane oprogramowanie dla poszczególnych klientów, obserwowałam rosnący trend komunikacji przez czat, szczególnie w przypadku milenialsów i generacji Z. Spotkałam się wówczas z naszym zaufanym klientem, InPostem, aby zaproponować zautomatyzowanie procesów w kanałach tekstowych i tym samym odciążyć dział obsługi klienta z odpowiedzi na powtarzalne pytania w trakcie intensywnego okresu przedświątecznego. Zgodził się.`,
          },
          {
            question: `To wtedy narodził się Chatbotize?`,
            answer: `W tamtym momencie nie był to finalny produkt, a wdrożenie dla jednego klienta. Projekt okazał się sukcesem i przyniósł InPostowi spektakularne rezultaty, co zachęciło nas do stworzenia rozwiązania generycznego, dostępnego dla szerokiego grona klientów dzięki obniżeniu kosztu wdrożenia poprzez zastosowanie dystrybucji w modelu Software as a Service. W ten sposób drastycznie obniżyliśmy próg wejścia dla chętnych do przetestowania takiego rozwiązania.`,
          },
          {
            question: `Na ile jest ono potrzebne i unikalne?`,
            answer: `Usprawnienie komunikacji w kanałach czatowych oznacza wprowadzenie automatyzacji, ale też wykorzystanie tego typu interfejsu do budowania relacji z potencjalnymi klientami, angażowania ich i sprzedaży. Jednocześnie automatyzacja oznacza odciążenie działów obsługi klienta z odpowiadania na powtarzające się pytania. Rozwiązanie świetnie sprawdza się również w usprawnieniu komunikacji wewnętrznej w firmie, stając się centralnym źródłem dostępu do przydatnej wiedzy. Podsumowując, z jednej strony klienci oszczędzają dzięki automatyzacji, z drugiej – zarabiają dzięki budowaniu długofalowych relacji.`,
          },
          {
            question: `Rośniecie na fali tej InPostowej renomy?`,
            answer: `Wdrożenia u rozpoznawalnych marek oczywiście pomagają nam przekonać kolejnych klientów, ale rośniemy dlatego, że odpowiadamy na realne potrzeby i najbardziej palące problemy dużych organizacji. W tym momencie firmy nie stoją już przed pytaniem „Automatyzować, czy nie automatyzować?”, a „Jak zautomatyzować najefektywniej, zyskując przy tym na satysfakcji klienta?”. Nasza filozofia działania polega na nieustannej nauce. Uczymy się od siebie nawzajem w zespole, uczymy się w akceleratorach. Ale najwięcej uczą nas klienci, z którymi nieustannie pracujemy nad rozwojem produktu i dostosowaniem go do ich zmieniających się potrzeb. Mówimy tu także o klientach spoza Polski, m.in. z CEE, ale i z Wielkiej Brytanii czy nawet Kenii.`,
          },
          {
            question: `Ten ostatni kierunek brzmi bardzo egzotycznie.`,
            answer: `Wbrew pozorom destynacje takie jak Afryka czy Ameryka Południowa są dla innowacji bardzo obiecujące. Szczególnie dla tych związanych z szeroko pojętym mobile. Pokuszę się tutaj o porównanie do rynku finansowego  Europa vs Stany Zjednoczone. USA są pionierami rynku finansowego, jednak utknęli na etapie czeków. Europa, z naciskiem na Europę Środkową i Wschodnią, wprost z obrotu gotówką przeskoczyła na płatności internetowe i związane z tym innowacje . Zwyczajnie ominęliśmy pewien etap. Podobna sytuacja ma miejsce w krajach Ameryki Południowej czy Afryki – niewielu obywateli ma komputery, ale większość dysponuje smartfonem. Dlatego płatności mobilne czy usługi dostępne z poziomu aplikacji na telefon są tam oczywistością. Komunikatory internetowe rozwijają się nawet dwa razy szybciej niż na innych rynkach. WhatsApp czy Messenger to wiodące aplikacje, a co za tym idzie – ogromny potencjał dla firm, które szukają kanałów dotarcia do potencjalnych klientów.`,
          },
          {
            question: `Choć najwięcej klientów macie z Polski.`,
            answer: `Myślenie w kategoriach Polski jako wymarzonego rynku jest niebezpieczne. To rynek na tyle duży i zaawansowany, że pozwala rozwinąć dobrze prosperującą firmę, dlatego może ograniczać w rozwoju. Gdy spojrzymy na innowacyjne firmy z globalnym sukcesem, to widzimy, że często pochodzą z bardzo małych krajów i ich myślenie od dnia zero jest skierowane globalnie. To prawda, w tym momencie najwięcej naszych klientów to polskie firmy i polskie oddziały globalnych korporacji. Traktujemy to jako pierwszy krok. Wspomniane firmy stanowią dla nas pomost na świat, czyli do swoich oddziałów na innych szerokościach geograficznych.`,
          },
          {
            question: `Czy wasze globalne ambicje oznaczają zapowiedź wyprowadzki z Polski?`,
            answer: `Polska jest krajem intensywnie rozwijającym się, doskonałym do wdrażania innowacji, ale przede wszystkim opracowywania innowacyjnych rozwiązań. Mamy dostęp do wykwalifikowanej kadry, fantastycznych programistów, dzięki którym możemy budować technologię dużo taniej i lepiej niż nasi konkurenci za granicą. Jesteśmy szybcy i elastyczni. Ale matematyka nie kłamie – za granicą sprzedajemy nasze rozwiązania za wyższe stawki, a wisienką na torcie jest sam proces decyzyjny, który jest zauważalnie krótszy dzięki bardziej liberalnej interpretacji regulacji prawnych. Nie wspominając o rynkach, którym obce jest pojęcie RODO. Mimo to nie zamierzamy rezygnować z polskiego klienta i na pewno naszą główną siedzibą i centrum innowacji będzie Polska.`,
          },
          {
            question: `Jak wyraźne są to różnice?`,
            answer: `W kontekście procesowania umowy to zagranica jest kilkadziesiąt procent szybsza. Choć przecież tam również obowiązują RODO i tysiące innych przepisów. A jednak korporacje za granicą, nawet na Słowacji czy w Czechach, mają taką mentalność, że wolą coś wprowadzić szybciej i potem ewentualnie doszlifowywać to prawnie. Jak sami mówią: wolą przepraszać, niż prosić.`,
          },
          {
            question: `Może nie ma tu takiego zapotrzebowania na chaty?`,
            answer: `Przeciwnie – to jak najbardziej realna potrzeba biznesowa na rynku finansów. Według naszych analiz to właśnie branża finansowa najbardziej potrzebuje automatyzacji, szczególnie w obszarze obsługi klienta, procesów wewnętrznych czy onboardingu nowych pracowników. Z całego spektrum rozwiązań, które oferujemy, najtrudniej przekonać nam banki do kontaktu z klientami przez aplikację Messenger. To delikatne kwestie, które w polskim prawie lewitują poniekąd w szarej strefie. Na szczęście to już się zmienia. Aplikacje z rodziny Facebooka (Messenger, WhatsApp, Instagram) coraz lepiej odpowiadają na potrzeby i wątpliwości instytucji finansowych. My również umacniamy swoją pozycję i wiarygodność, m.in. dzięki udziale w programie dla startupów PKO Banku Polskiego.`,
          },
          {
            question: `Jednak wciąż jest tu wiele „ale”.`,
            answer: `Jeśli czeka nas długi i kosztowny proces decyzyjny, masa spotkań i analiz prawnych, to oznacza to tylko tyle, że koszt wdrożenia dla klienta musi być większy. Dla startupów takich jak Chatbotize, które kierują swoją ofertę to dużych organizacji, w tym finansowych, dobrym rozwiązaniem są strategiczne partnerstwa z firmami mającymi już głębokie relacje biznesowe z bankami czy towarzystwami ubezpieczeniowymi. Wtedy możemy skoncentrować się na tym, w czym się specjalizujemy – dostarczaniu wartości dla klienta.`,
          },
          {
            question: `Naprawdę wdrożenie w banku to taka ścieżka zdrowia?`,
            answer: `Cały czas koncentrujemy się na negatywach, ale gdyby było tak źle, to nie zdecydowalibyśmy, że branża finansowa jest tą, na której najbardziej chcemy się skupiać w Chatbotize. W dużych organizacjach, zwłaszcza w bankowości, obserwujemy powstawanie działów innowacji, których naczelnym zadaniem jest obserwacja rynku, tzw. scouting startupów, dobieranie rozwiązań i ich wdrażanie. To bardzo pomocne i zdecydowanie przyspiesza cały proces, ponieważ taki dział staje się naszym ambasadorem marki wewnątrz korporacji.`,
          },
          {
            question: `Na co jeszcze w czasie technologicznej rewolucji powinny zwrócić uwagę firmy z sektora finansowego, by nie zostać z tyłu?`,
            answer: `Mam poczucie graniczące z pewnością, że banki nieco po omacku podchodzą do grupy najmłodszych klientów. Prowadziliśmy intensywne badania nad tą właśnie grupą i wniosek jest jednoznaczny – instytucje finansowe kiepsko odpowiadają na ich potrzeby. To na pewno wyzwanie, któremu muszą sprostać w najbliższych latach, aby wygrać wojnę o klienta młodego pokolenia, który przecież dorasta i za moment będzie tym przynoszącym największe przychody. Drugim wyzwaniem jest oczywiście odpowiedź na rosnące koszty obsługi klienta, które związane są z coraz wyższymi pensjami, ale też epoką cyfryzacji, w której żyjemy. To często od jakości odpowiedzi i czasu jej udzielenia zależy, czy klient będzie naszym ambasadorem, czy wrogiem. Szybkość absorbcji innowacji od startupów może okazać się kluczowa w tej grze o najwyższą stawkę – serce i portfel klienta.`,
          },
        ],
      },
      {
        title: `Startupy poderwą korporacje do sprintu`,
        subtitle: `Wywiad z Krzysztofem Sobczakiem`,
        author: `Krzysztof Sobczak`,
        company: `Emplocity sp. z o.o.`,
        questions: [
          {
            question: `Twoja praca to tworzenie botów, szukających ludziom… pracy. Brzmi nieco jak science fiction.`,
            answer: `Coraz więcej ludzi i firm docenia dziś efekty pracy botów, napędzanych sztuczną inteligencją. Ale gdy zaczynaliśmy te pięć, sześć lat temu, to nasi rozmówcy robili naprawdę wielkie oczy. Teraz nasz Emplobot szuka ludziom pracy, karmiąc się autorskimi algorytmami i posiadając m.in. inteligencję w zakresie przetwarzania języka naturalnego. Do tego działa niczym marketplace – wspiera osoby szukające pracy i jednocześnie daje efektywne narzędzie firmom poszukującym idealnie dopasowanych pracowników.`,
          },
          {
            question: `Gdy zaczynaliście, takie rozwiązanie gdzieś istniało?`,
            answer: `Tak naprawdę temat zrobił się gorący dwa, trzy lata temu, wraz z postępem technologii, która po prostu stała się osiągalna i skuteczna. W tym czasie, gdy zaczynaliśmy, istniało jednak coś innego – moje poczucie słabości procesowej w rekrutacji.`,
          },
          {
            question: `Wysyłanie dziesiątek CV i dostawanie dziesiątek nietrafionych ofert?`,
            answer: `Dokładnie. Przecież istotą właściwej rekrutacji jest precyzyjne i szybkie połącznie odpowiednich kandydatów z właściwym pracodawcą. Sztuczna inteligencja rozwijana w Empolcity to potrafi: likwidujemy element frustracji na rynku pracy, dostarczając dla niego narzędzia do automatyzacji procesów pozyskiwania kandydatów tzw. sourcingu. Emplobot jest kluczowy także we wstępnej selekcji, nazywanej w branży pre-screeningiem.`,
          },
          {
            question: `Kandydatów ani rekruterów nie odstrasza ten niejako odhumanizowany proces rekrutacyjny?`,
            answer: `Spójrzmy na ofensywę komunikatorów, np. WeChata w Chinach czy Messengera. Tekst staje się dominującym środkiem komunikacji, ludzie są gotowi prowadzić dialogi tekstowe z poziomu smartfona i o ile bot po drugiej stronie rozumie ich język naturalny, o tyle nie mają wielkich oporów, by wykorzystywać wysyłanie wiadomości na czacie jako elementu rekrutacji. Oczywiście, zakładam, że w dłuższej perspektywie do gry w HR-rze wejdą także boty rozpoznające głos.`,
          },
          {
            question: `Kto był tym pierwszym, który uwierzył w to, że nowa fala nadchodzi?`,
            answer: `Budimex zaczął korzystać z Emplobota w marcu 2018 r. To był moment zwrotny, kluczowy. To był pierwszy, wymierny dowód na to, że rzeczywiście to, co robimy, ma sens. Oczywiście, wcześniej mieliśmy też wsparcie i zaufanie od inwestorów, granty unijne oraz świetny zespół. Jednak nie czarujmy się – Emplobot powstał po to, by używały go firmy i kandydaci. Zatem każdy kolejny klient dodawał nam pewności siebie. Dziś jest ich już kilkudziesięciu. Przełomowe są też chwile, gdy słyszę, że nasz bot był czyimś świetnym i skutecznym asystentem przy poszukiwaniach pracy, a także gdy rekruterzy mówią o Emplobocie per supermoc. To nas napędza.`,
          },
          {
            question: `Budimex dostał tę supermoc od pierwszego dnia?`,
            answer: `Na pewno coś ryzykował, musiał podjąć wysiłek bycia niejako ojcem chrzestnym przy naszym rynkowym debiucie. A jednocześnie, dzięki temu zaangażowaniu na początkowym etapie, Budimex mógł wypracować z nami sporo rzeczy, rozwiązań, dopasowanych do jego potrzeb, które potem stały się standardem naszej usługi. To był jego przywilej.`,
          },
          {
            question: `Jego śladem podążają kolejne firmy, koncerny, korporacje?`,
            answer: `Ta machina ma tendencje do samonapędzania się. Na pewno fakt, że Emplobot pracuje np. dla dużego banku, działa na wyobraźnię nie tylko konkurencji. Ale z drugiej strony sprzedaż jest wciąż dla nas dużym wyzwaniem, bo rzeczywistość skrzeczy. Dziś każdy menedżer pytany o innowacje kiwa twierdząco głową. Ale praktyka pokazuje, że nie możemy popadać w samozadowolenie i oczekiwać, że biznes sam nas zrozumie i zacznie używać.`,
          },
          {
            question: `To znaczy?`,
            answer: `Są nieraz takie momenty, że na spotkaniach w dużych firmach jesteśmy traktowani niejako z pobłażaniem, jako zabawka, gadżet, a nie jak rozwiązanie pomagające rozwiązywać skomplikowane wyzwania na linii przedsiębiorstwa – pracownicy. Oczywiście mamy cierpliwość, edukujemy rynek. Znamy swoją wartość i potencjał – dowodem na to jest m.in. wsparcie od inwestorów czy NCBR. To pozwala nam takie wyzwania traktować jako szansę, a nie problem.`,
          },
          {
            question: `Widzicie zatem dla siebie szanse również poza Polską?`,
            answer: `Z taką myślą powstaliśmy i działamy. Obecnie mamy co prawda klientów tylko z Polski, ale wierzę, że to tylko pierwszy etap. W naszej strategii zapisaliśmy plany wejścia na pięć rynków Europy Zachodniej i do Stanów Zjednoczonych – to jeszcze w 2020 r. Zabrzmi nieskromnie, ale tak jest: budujemy projekt globalny. W swoim obszarze chcemy być najlepsi na świecie.`,
          },
          {
            question: `A w Polsce macie jeszcze coś do udowodnienia?`,
            answer: `Jasne, tu jest jeszcze sporo do zrobienia i nie zamierzamy się z tym kryć. Jednak nałóżmy na to inną perspektywę – podzielności uwagi. Możemy zatem koncentrować siły tylko na Polsce, maksymalizując, próbując, zdobywając przyczółki, pozwalające nam zarabiać i utrzymywać rozwój firmy na skalę lokalną. Przy czym tło jest takie, że taki rozwój oznacza skrzyżowanie szabel z tutejszym hegemonem, czyli grupą Pracuj i dalsze uświadamianie instytucji w kwestii wyższości technologii nad tradycyjną papierologią. Tymczasem mamy potencjał na dużo więcej – także poza Polską nie brakuje żywo zmieniających się, otwartych rynków z dobrymi marżami i wysoką absorpcją nowych technologii. Firmy ze Skandynawii czy z Wysp Brytyjskich wręcz same zapraszają nas na rozmowy. Oczywiście, np. Niemcy są bardzo hermetyczne, ale jednocześnie mają potencjalnie duże potrzeby, które możemy zaspokoić w kontekście liberalizacji niemieckiego rynku pracy np. dla Ukraińców. Szukamy więc tam swojej szansy, tak żeby wpisać się w ten trend.`,
          },
          {
            question: `Za Odrą czy na Wyspach też będziecie szukać dużych firm?`,
            answer: `Tak, to nasz naturalny partner, mniej więcej 90 proc. naszych klientów. W Polsce te firmy są najczęściej z sektora finansowego, ubezpieczeniowego. I to nie jest tak, że sami ich szukamy. Branża finansowa w Polsce jest bardzo aktywna na polu pozyskiwania innowacji, przeszła już z fazy deklaracji, ogłaszania, do etapu zamieniania słów w czyny. Banki, ubezpieczalnie widzą, że AI w rekrutacji jest szansą i jednocześnie mają świadomość, że nie są w stanie w rozsądnym budżecie oraz czasie stworzyć samemu narzędzi tej klasy. Dla dużych firm stworzenie innowacji w formule wewnętrznej – jeśli nie jest to w nurcie ich core businessu – to problematyczny proces. Wtedy właśnie są potrzebne gotowe, elastyczne rozwiązania od firm technologicznych, takich jak my.`,
          },
          {
            question: `Skoro są tak otwarte, to rozumiem, że współpraca i wdrożenia idą szybko, bank za bankiem?`,
            answer: `Bywa różnie. Zwykle nasz kontakt zaczyna się od kilku spotkań twarzą w twarz i dość szybko klaruje się, w jakim tempie sprawy się potoczą. Zdarzyło się, że stanęliśmy na głowie, by ledwie w kilka dni podpiąć Emplobota do dużej instytucji. Wiadomo, entuzjazm klienta szybko udziela się startupowi, który jest w stanie zrobić wtedy naprawdę wielkie rzeczy. Ale nierzadko wpadamy w procesową karuzelę, czyli trzy, cztery, a czasem nawet siedem miesięcy spotkań, pytań, pieczątek…`,
          },
          {
            question: `Wiecie już, którędy najskuteczniej jest się „włamać” do korporacji?`,
            answer: `Najłatwiej przez działy HR, które jednak często mają najmniej siły przebicia się wyżej. Choć zauważam, że z kwartału na kwartał HR-y zyskują punkty w korporacyjnych hierarchiach. W bankach dobrym punktem zaczepienia są oczywiście działy innowacji, transformacji cyfrowej. Na tle innych branż to właśnie finanse mają świadomość i zasoby do inwestycji w technologię. Jest to widoczne także w ten sposób, że np. ze strony banków dostajemy dużo wartościowego feedbacku, wyrozumiałości i wparcia w sferze PR w mediach. To także dla nas forma uwiarygodnienia się w oczach innych. Doceniamy również współpracę w formule partnerskiej, gdzie wzajemnie wymieniamy wiedzą, pomysłami i podobnie rozumiemy dynamikę, jaka towarzyszy tworzeniu innowacji.`,
          },
          {
            question: `Choć przydałoby także przyspieszenie procesów w finansach?`,
            answer: `W wielu instytucjach absolutnie tak. Dla banku pół roku wdrożenia to zwykły proces. Dla startupu to często epoka, startupy są tu i teraz, gotowe do działania – o ile mają rozwiniętą technologię i poziom świadczenia usług. Jeśli startup ma mocną, sprawdzoną technologię i organizację, to jest gotowy na naprawdę wymagające projekty z dużym partnerem. Ale zarazem potrzebuje adekwatnej gotowości z drugiej strony. Nie chodzi o taryfę ulgową poprzez obniżanie standardów wejścia dla startupów, a o przygotowanie ścieżki do sprintu zamiast rekreacyjnego spaceru. Jest tyle wyjątkowych rzeczy, które korporacje mogą zrobić wraz ze startupami, że szkoda tracić czas i energię na „procesowanie procesu”. Trzeba działać!`,
          },
        ],
      },
      {
        title: `Bank a startup, czyli lekcja efektywnej współpracy`,
        subtitle: `Wywiad z Aleksandrem Kijkiem`,
        author: `Aleksander Kijek`,
        company: `Nethone`,
        questions: [
          {
            question: `W Nethone twierdzicie, że Internet nie jest globalną wioską?`,
            answer: `Cóż, to, że możemy w sekundę zrobić przelew czy wysłać e-mail, nie oznacza, że internauci mają ze sobą osobiste relacje niczym w realnej wiosce. W wirtualnym świecie możemy być anonimowi, a nawet podszywać się pod czyjąś tożsamość. Są jednak możliwości, by tę niedoskonałość globalnej wioski naprawić – rozwiązaniem jest Nethone. Powstaliśmy po to, by pomóc biznesowi ulepszyć sieć i oczyszczać ją z oszustów. Nethone pomaga decydować, czy dany użytkownik jest tym, za kogo się podaje. Mamy klientów, którzy sprzedają online swoje produkty  bądź usługi, i to na nich ciąży odpowiedzialność sprawdzania, czy przypadkiem nie sprzedają ich komuś, kto wykradł dane karty używanej w transakcji. My widzimy, kto wchodzi do Internetu w kominiarce, a kto nie.`,
          },
          {
            question: `W jaki sposób?`,
            answer: `W naszej technologii wspieramy się uczeniem maszynowym. Analizujemy ponad 5 tys. zmiennych w czasie poniżej dwóch sekund i na tej podstawie oceniamy użytkownika. Jesteśmy w stanie sprawdzić, czy ktoś, kto się loguje, to jest ta właściwa osoba, czy może to ktoś, kto ukradł login i hasło. Patrzymy, jak ta osoba się zachowuje w sieci, czy używa tego samego urządzenia co zwykle, w jaki sposób i w jakim tempie wpisuje hasło, jakiej używa przeglądarki… Jeśli nasze modele wskażą rozbieżności, to odpowiednio je ocenią i wymuszą dodatkową identyfikację albo zablokują taką transakcję. Instytucje finansowe, a także wszyscy gracze operujący online bardzo potrzebują tego typu rozwiązań. Firmy automatyzują wiele procesów bezpieczeństwa, ale nawet gdy samodzielnie przeprowadzają weryfikację, to zapewniamy naszym partnerom szeroki kontekst przeprowadzanych działań oraz kluczowe wnioski, potrzebne do podjęcia ostatecznej decyzji, czy dana transakcja jest oszustwem, czy nie.`,
          },
          {
            question: `Robotyzujecie te procesy?`,
            answer: `Tak bym tego nie ujął, ponieważ człowiek i jego pomysłowość wciąż są niezbędne. Natomiast na pewno czynimy je bezpieczniejszymi i wydajniejszymi. Wbrew obiegowej opinii uczenie maszynowe jest niezbyt zaawansowane. Owszem, jest w stanie przeprocesować bardzo dużą liczbę danych w bardzo krótkim czasie, ale nie jest przy tym kreatywne. Dlatego tak duże znaczenie ma czynnik ludzki w postaci data sciencistów, przypisanych do poszczególnego klienta. To oni, rozumiejąc potrzeby biznesowe klienta, tak modelują dane, aby niemalże na tacy podać tę wiedzę uczeniu maszynowemu. Uczenie maszynowe samo sobie nic nie wymyśli.`,
          },
          {
            question: `Nethone wymyśliliście wy?`,
            answer: `Tak. Nasze DNA ściśle wiąże się z Daftcode, największą w Polsce firmą działającą w modelu venture building, która samodzielnie realizuje wszystkie etapy powstawania i wdrażania projektów – od koncepcji przez development, aż po wprowadzenie danego produktu na rynek. Przy jednym z nich pojawiła się bardzo konkretna potrzeba rynkowa, czyli sprawdzanie wiarygodności osób zawierających transakcje w sieci. W ten sposób, nieco w tle, zaczęliśmy budować rozwiązanie chroniące płatności; rozwijaliśmy je krok po kroku. Pewnego dnia nasze rozwiązanie dojrzało na tyle, że można było je zaoferować zewnętrznemu klientowi. Ponieważ potencjał był olbrzymi, założyliśmy nową firmę, która od 2016 r. działa na własny rachunek.`,
          },
          {
            question: `Trudno było odciąć się od spółki matki?`,
            answer: `Ta decyzja o wejściu w dojrzałość to nigdy nie jest łatwy moment. Ale szukając najlepszego przygotowania do samodzielnego biegu, aktywnie braliśmy udział w wybranych zagranicznych programach akceleracyjnych, organizowanych m.in. przez francuski Thales i Plug and Play z Doliny Krzemowej. Do tematu podeszliśmy punktowo, skoncentrowaliśmy się na wybranych branżach, w których działamy. Stwierdziliśmy, że na początek strategicznie skupimy się na bardzo konkretnej branży, czyli na fraudach w branży podróżniczej. To pozwoliło nam zdobyć pierwszego klienta nie w Polsce, a od razu za granicą. Jednak i tak, pomimo treningu oraz wiedzy, odcięcie pępowiny było wyzwaniem. Po czasie widzę, że wybraliśmy dobry moment. Ten proces dodał Nethone pewności siebie i dynamiki w działaniu.`,
          },
          {
            question: `Taki był plan?`,
            answer: `W temacie fraudu w kanałach online – tak. Od razu trafiliśmy na radar firm z Ameryki Południowej, gdzie w segmencie online travel jest dużo oszustw. Ale np. w przypadku instytucji finansowych najwięcej klientów mamy z Polski. Tu czujemy się mocni, bo jesteśmy przecież „stąd”, a na rynku nie ma analogicznego, konkurencyjnego rozwiązania made in Poland. Do tego kultura startupowa naprawdę zaczyna się w Polsce rozkręcać, zyskując wśród dużych graczy coraz więcej entuzjastów. Poziom IT i całej branży fintech w Polsce jest na bardzo wysokim poziomie. Ostatnio zintegrowaliśmy się z polską firmą w zaledwie dwa tygodnie – od momentu opracowania dokumentacji, przez testy, po przejście na tzw. produkcję! Absolutny rekord świata.`,
          },
          {
            question: `Ideał?`,
            answer: `Nie zawsze jest różowo. Przykładowo polską specyfiką są negocjacje, ale te dotyczące głównie ceny, a nie jakości, zakresu czy korzyści z wdrożenia. To trochę szkodliwe, bo gdy koncentrujemy się na cenie, to niejednokrotnie kosztem rezygnacji z poziomu usługi. Tymczasem w przypadku bezpieczeństwa w sieci trudno iść na kompromisy. Na szczęście w Nethone zawsze pilnujemy zasady win-win, tak więc każda ze stron jest zadowolona ze współpracy.`,
          },
          {
            question: `Z kim zatem pracujecie?`,
            answer: `Obsługujemy duże podmioty z branży turystycznej i e-commerce, takie jak działającą w blisko 30 krajach grupę eSky, największe brazylijskie linie lotnicze Azul, Polskie Linie Lotnicze LOT czy międzynarodowy, multibrandowy butik odzieżowy. Od początku 2018 r. firma aktywnie współpracuje z sektorem finansowym, w tym Grupą ING. Wspiera także wiodące instytucje pożyczkowe. „Glejt” od linii lotniczej czy banku uwiarygadnia nas jako biznesowego partnera, ponieważ daje jasny sygnał, że duża instytucja nam zaufała w tak krytycznym obszarze swej działalności. To działa na wyobraźnię innych potencjalnych partnerów. Korporacje nie dają nam szybkiego zwrotu z inwestycji, ale dają nam zaufanie. A to równie istotna wartość, szczególnie dla dynamicznie rozwijającej się firmy technologicznej.`,
          },
          {
            question: `Wielu startupowców podkreśla formalne wyzwania związane z pracą z bankami. Macie podobne odczucia?`,
            answer: `To na pewno nie jest szybki i łatwy proces. Jednak dla nas współpraca z bankami i innymi instytucjami finansowymi jest istotna z kilku względów. To nie tylko środki finansowe przeznaczone na rozwój i wizerunek wiarygodnego partnera, lecz także okazja do samodoskonalenia się, wchodzenia na wyższy poziom z naszym rozwiązaniem. Żeby spełnić wszystkie wymagania takiej instytucji finansowej, trzeba wykonać bardzo konkretną, niełatwą pracę.`,
          },
          {
            question: `Litania akceptów i podpisów zabiera długie miesiące?`,
            answer: `Banki z założenia muszą działać ostrożnie i rozumiem to. Ale jednocześnie widzę przestrzeń do akceleracji procesów po ich stronie – nie muszą angażować kilkudziesięciu osób, skoro w Nethone robią to zaledwie dwie. Z drugiej strony, zaskoczeniem było dla mnie bezpośrednie, dość luźne personalne podejście do nas w bankach. Ruch ku innowacji w bankowości zawsze przyciąga osoby otwarte na nowości, mówiące podobnym językiem, co my. Choć oczywiście jakościowa poprzeczka absolutnie nie jest obniżana – technologiczny startup w wyniku współpracy z większą organizacją bierze na siebie pewne procesowe wymagania, układa się wewnętrznie. To jest dobre, ale to też zajmuje czas.`,
          },
          {
            question: `Czyli bankowcy są wam z założenia przychylni?`,
            answer: `Generalnie nauczyliśmy się zaskarbiać ich przychylność, a precyzyjniej mówiąc – zrozumienie. W jaki sposób? Nasza technologia nie jest prosta. Przekazujemy wiedzę o niej podczas intensywnej serii warsztatów. To ważne, żeby osoby po stronie korporacji rozumiały nasz produkt, wówczas zwiększamy szansę na jego komercyjny sukces. Zatem często kwestia udanej współpracy sprowadza się do zlokalizowania w organizacji takiego anioła, który w nas wierzy i rozumie wartość, jaką wnosimy do korporacji. To on przeprowadza nas przez meandry współpracy i łączy z odpowiednimi osobami.`,
          },
          {
            question: `Aniołowie to nieodłączny element idealnej współpracy z bankami?`,
            answer: `Tu wręcz przydatny jest cały zespół. Tzw. project owner po stronie banku to podstawa owocnej współpracy. Ktoś, kto będzie cały proces wdrożenia nadzorował i pilnował terminów. Do pary potrzebuje jeszcze kogoś z mocną siłą przebicia, w razie gdyby pojawiła się potrzeba nowych zasobów do kontunuowania projektu. Musimy także zidentyfikować grupę osób, które operacyjnie będą korzystać z nowego rozwiązania i przekazać im właściwą wiedzę. Oczywiście, pozostaje też cała sfera prawna. Marzy mi się sytuacja, w której prawnicy banku akcelerowaliby także kwestie po stronie startupu i zakładali równość podmiotów. A na końcu feedback od nich wszystkich – sprawa nie do przecenienia.`,
          },
          {
            question: `Technologicznie w finansach nie ma wiele do poprawy?`,
            answer: `Branża finansowa w Polsce prze mocno do przodu – np. infrastrukturalne projekty takie jak Chmura Krajowa. To rozwiązanie idealne, które daje możliwość szybkiego rozpoczęcia działalności dla startupów. Wchodzą one na Chmurę, aby w bezkolizyjny sposób móc integrować się z bankiem. Gdy taka Chmura ma jeszcze błogosławieństwo KNF, oznacza to zdjęcie ze startupów wielu obciążeń procesowych i kosztów przy wdrożeniach. To, czego banki mogą wciąż nauczyć się od startupów, to sprawność procesowa przy wdrażaniu technologicznych nowinek. I coraz częściej tego się właśnie uczą!`,
          },
        ],
      },
      {
        title: `Nie sam pomysł się liczy`,
        subtitle: `Wywiad z Hubertem Guzerą`,
        author: `Hubert Guzera`,
        company: `placeme`,
        questions: [
          {
            question: `Czy w erze cyfrowej da się zarabiać na pokazywaniu zachowań ludzi w świecie offline?`,
            answer: `Tak, bo wciąż zdecydowana większość sprzedaży dokonuje się w fizycznych sklepach, punktach, restauracjach. Ich właściciele cały czas myślą, jak udoskonalić tradycyjny sposób sprzedaży. Dlatego powstało Placeme, aplikacja pozwalająca zrozumieć potencjał lokalizacji i zachowań klientów w danym fizycznym miejscu – pod konkretnym adresem na mapie. Oczywiście, korzystamy obficie z cyfrowych dobrodziejstw, agregując wiele zbiorów danych i nakładając na to naszą wiedzę oraz doświadczenie data scientists. Dzięki temu przetwarzamy te dane tak, że pozwalają one np. zobaczyć, skąd ludzie przychodzą do danej lokalizacji, ile czasu tam spędzają, w jakich jeszcze miejscach bywają. Gdy połączymy to z wieloma innymi źródłami danych, jesteśmy w stanie stworzyć precyzyjny profil demograficzny takiej osoby i odwzorować jej model zachować offline. Choć, co ważne, nie znamy jej dokładnych personaliów, nasze dane są anonimowe i zagregowane, czyli oddają w dużej mierze obraz statystyczny.`,
          },
          {
            question: `W ten sposób wśród waszych klientów są wielkie korporacje i marki. W jaki dokładnie sposób wykorzystują waszą aplikację?`,
            answer: `Weźmy choćby sieci handlowe – dla nich trafiona lokalizacja to kluczowy aspekt inwestycji. Dlatego zależy im na tym, żeby sklepy powstawały w często odwiedzanych miejscach, tam, gdzie jest odpowiednie natężenie ruchu ludzi w ciągu dnia. Jednocześnie szukają one informacji o tym, na ile ten ruch ma potencjał przełożenia się na odpowiednie obroty. Podobnie zaczynają myśleć np. banki, choć te chętniej analizują opłacalność utrzymania swoich obecnych oddziałów w konkretnych lokalizacjach. Nasze rozwiązanie jest też wykorzystywane do optymalizacji inwestycji w billboardy czy efektywnego dysponowania czasem przedstawicieli handlowych. Tak szczerze – cieszy nas to, że wspólnie z klientami co rusz odkrywamy nowe, potencjalne zastosowania Placeme. To, że klienci mogą korzystać z narzędzia samodzielnie, bez naszego udziału, było dla nas od początku priorytetem przy projektowaniu aplikacji. Jest ona bardzo prosta w użyciu i nie trzeba być statystykiem, by móc z niej „wycisnąć” zaskakująco użyteczne dane, kluczowe przy podejmowaniu ważnych decyzji biznesowych.`,
          },
          {
            question: `Pomysł na nią wziął się z twoich obserwacji z czasów, gdy jeszcze pracowałeś w branży konsultingowej.`,
            answer: `Tak, praca doradcy daje możliwość zobaczenia od środka wielu organizacji i tego, jak radzą sobie z wyzwaniami. Jednocześnie zauważyłem, że każda z nich miała trudności związane z przetwarzaniem, a nawet z dotarciem do danych przestrzennych. Nawet ja, chcąc zmienić mieszkanie, zastanawiałem się, jak odfiltrować oferty tak, by oglądać tylko te, które mnie interesują, takie, obok których są sklep, przystanek czy park. Planowałem napisać pod to skrypt, który by mi to ułatwił i zdałem sobie sprawę, że może się to przydać także innym. To był zalążek pomysłu, który finalnie zamienił się w Placeme. Sam proces zakładania firmy trwał bardzo długo: przez bity rok patrzyłem, czy to ma sens. Idea ewoluowała i łączyłem ją z normalną etatową pracą.`,
          },
          {
            question: `Długo. Kiedy nastąpił ten kluczowy moment startu?`,
            answer: `Sam pomysł to jeszcze nic, pomysły dzisiaj są tanie. Ze wspólnikiem potrafimy nimi strzelać jak z karabinu. Pytanie, jak są wprowadzane w życie. Dlatego w kategorii przełomu mieści się dopiero zatrudnienie pierwszego pracownika na etat czy pierwsze duże wyróżnienie – na Europejskim Kongresie Gospodarczym w Katowicach, gdzie zostaliśmy finalistą konkursu dla startupów, choć de facto jeszcze nic nie mieliśmy w naszym firmowym CV. Potem już zaczął się bieg – założyliśmy docelową spółkę, dopięliśmy produkt, pojawiły się przychody, inwestor…`,
          },
          {
            question: `Jazda z górki?`,
            answer: `Tak bym tego nie nazwał. To mieszanka: codzienna walka i satysfakcja. Udało się zbudować świetny zespół, ekipę, która po prostu ma frajdę z pracy ze sobą. Jednocześnie każdy z nas rozumie, że w specyfice startupu leży to, że najpierw się długo buduje i inwestuje, profity przychodzą dużo później. W startupie nie ma miejsca na system pracy np. glazurnika – przychodzę, zrobię robotę i od razu kasuję dniówkę. Tak to nie działa, a byłoby prościej.`,
          },
          {
            question: `Częścią waszej mentalności była obecność nie tylko w Polsce, lecz także w Portugalii?`,
            answer: `Do Portugalii trafiliśmy dość szczęśliwym przypadkiem. Punktem zwrotnym w początkach Placeme był e-mail z zaproszeniem do akceleratora w Lizbonie. Początkowo podejrzewaliśmy, że to jakiś scam – chcą nam pokazać, jak być startupem oraz przedsiębiorcą i jeszcze do tego zapłacić 80 tys. EUR za trzy miesiące?! Lizbona dała nam niesamowitego kopa, doświadczeni mentorzy pokazali nam, jak pozyskać pierwszych klientów, jak opowiadać o produkcie, jak budować swoją narrację. Tam usłyszeliśmy, że „jeżeli nie wstydzisz się, gdy pokazujesz produkt klientowi, to znaczy, że… za późno mu go pokazujesz”. Warto wcześnie zderzyć się z oczekiwaniami rynku. Te lekcje przygotowały nas na obecną podróż. Bez tego, myślę, już by nas nie było. A tak jesteśmy również w Portugalii, mamy tam spółkę, inwestorów, a nawet zaczynamy sprzedawać. To jednak Polska jest naszym najważniejszym rynkiem.`,
          },
          {
            question: `Patrząc po waszych klientach, jest się tu czym chwalić.`,
            answer: `O naszej sile stanowią trzy główne grupy klientów – sieci handlowe, producenci FMCG oraz banki. Dlatego pracujemy m.in. z: Carrefourem, Dino, Netto, Unileverem, Horteksem czy ING oraz PKO Bankiem Polskim. Są też klienci tzw. oportunistyczni, używający naszej aplikacji przy konkretnych, jednorazowych projektach w innych branżach, np. DPD.`,
          },
          {
            question: `Ogrzewacie się w tym blasku?`,
            answer: `Dobrze mieć znane marki w portfolio, ale musimy pamiętać, że przede wszystkim pracujemy z nimi dla pieniędzy, nie żeby chwalić się w mediach społecznościowych. Nie oczekujemy też, że wielkie korporacje wprowadzą nas w świat biznesu poprzez swoje programy, konkursy i akceleratory. To są przede wszystkim nasi klienci, to my im bezpośrednio dostarczamy wartość i wystawiamy za to fakturę. Spotykamy ich przedstawicieli na imprezach branżowych albo umawiamy się z nimi na LinkedIn i oferujemy nasz produkt. Szukamy ludzi rozumiejących dynamikę startupów, np. osób z działów innowacji w korporacjach. Słuchamy, czego potrzebują i szukają, jakie wyzwania stoją przed nimi. To jest paliwo, które nas napędza, dlatego nigdy nie zaczynamy spotkań od powiedzenia „robimy to i tamto za tyle pieniędzy”. Wolimy poznać potrzeby i potem rozwijać narzędzie w kierunku, który wyznacza rynek. Oczywiście, nie było łatwo przekonać pierwszych klientów, że warto zaryzykować i płacić, szczególnie w branży bankowej.`,
          },
          {
            question: `Po tych doświadczeniach masz jakąś złotą radę dla decydentów z banków?`,
            answer: `Biznesowa relacja firma – startup nie powinna odbywać się na takiej zasadzie, że korporacja staje się patronem i włącza się w proces budowy startupu. Rynek coraz częściej idzie w kierunku obudowywania tej współpracy w akcje marketingowe lub próby „wychowywania” sobie firm. Zdrowy model współpracy ze startupem jest wówczas, gdy korporacja po prostu płaci za wykonaną pracę. W relacji patronackiej jest coś nienaturalnego, niezdrowego i na dłuższą metę szkodzi rozwojowi startupu. Jesteśmy po to, by korporacja mogła – nie angażując całej swojej machiny – w szybki, niemal desantowy sposób testować, sprawdzać i finalnie wdrażać innowacje. I to ma swoją rynkową wartość.`,
          },
          {
            question: `To, że nie mówicie o sobie per fintech, nie jest przeszkodą?`,
            answer: `Banki są bardzo aktywne w wyławianiu startupów, rywalizują ze sobą na tym polu, obserwują się, zgodnie ze zjawiskiem FOMO (fear of missing out – red.). Wciągają do siebie kolejne firmy. Ale my jesteśmy spółką od data based solutions, a nie fintechem, co i tak nie zmniejsza bankowego apetytu na nasze rozwiązania. Choć nazwanie się fintechem działa często jak magnes na inwestorów i klientów, to ta łatka nie jest konieczna. Banki z miesiąca na miesiąc szukają coraz szerzej na rynku, coraz lepiej potrafią odnaleźć się w świecie innowacji spod znaku małych firm, dobrze czują trendy nie tylko w fintechu.`,
          },
          {
            question: `Bank przyjacielem startupu?`,
            answer: `W coraz większym stopniu tak, tym bardziej, że współpraca z bankiem daje potężną wizerunkową korzyść. Odczuliśmy to m.in. po ogłoszeniu współpracy z PKO Bankiem Polskim. Choć oczywiście są koszty, bariery, które trudno w tej branży przeskoczyć, jak np. kwestie bezpieczeństwa i wymagań IT, jakie się z tym wiążą. Przykład? Jeśli chcielibyśmy wpiąć się testowo w systemy banku, musielibyśmy z klientem spalić setki tysięcy złotych tylko po to, by sprawdzić, czy to w ogóle działa. Tymczasem w innych krajach dynamicznie rozwijają się sandboksy, gdzie można pewne rzeczy testować na próbnych kontach i danych np. 300 tys. wirtualnych klientów. To oznacza zniesienie istotnych barier technologicznych.`,
          },
          {
            question: `W Polsce nie da się ich ominąć?`,
            answer: `Nie jest to tak oczywiste. To w dużym stopniu kwestie restrykcyjnych regulacji, a nie chęci lub niechęci banków. Polski sektor finansowy jest naprawdę nowoczesny, otwarty na zmiany, choć zdarza się, że jakaś część „innowacyjnych” ruchów banków to działania pozorowane, mieszczące się w ramach budżetów marketingowych, a nie realnych inwestycji w technologie. Ale banki mimo to potrafią też realnie, szybko reagować na sukcesy startupów – tak jak w przypadku Revoluta i całej późniejszej fali bankowych kart wielowalutowych.`,
          },
          {
            question: `Banki – naśladowcy rewolucjonistów?`,
            answer: `„Rewolucja” w przypadku fintechu to za mocne słowo. Trudno zmienić rynek jednym czy dwoma produktami. Fintechy wywołują raczej „zamieszki”, wykorzystywane potem przez resztę rynku. Pokazują luki w ofertach. Przy czym nie jest tak, że rynek po stronie klientów łaknie innowacji za wszelką cenę. Gdy jeszcze pracowałem w konsultingu, szukaliśmy kiedyś odpowiedzi na pytanie, czy warto otwierać oddziały bankowe w bardziej przyjaznym klimacie kawiarni. Okazało się, że klienci tego nie chcą – branie kredytu w oddziale to nie wyjście do sklepu po bułki. Klienci chcą masywnego biurka i doradcy w garniturze, bo to daje im poczucie, że ich środki są bezpieczne. I w gruncie rzeczy po to są banki – nie po to, żeby zadziwiać nowinkami, ale po to, żeby w pierwszej kolejności zapewnić bezpieczeństwo naszym pieniądzom.`,
          },
          {
            question: `Zapewnią one też wzrost dla Placeme?`,
            answer: `Cenimy sobie tę branżę, jednak mamy szersze horyzonty. Etap, kiedy walczyliśmy, żeby pozyskać pierwszych klientów, mamy już za sobą, jesteśmy rentowni. Szukamy więc znacznie dalej niż w bankowości. Szczerze? Gdyby branża finansowa nie istniała, to i tak byśmy sobie poradzili. Ale z drugiej strony ma ona wielki potencjał i stawia fascynujące, niestandardowe wyzwania. Ostatnio, po jednym ze spotkań w znanym banku, rozważaliśmy, czy nie zrobić tam pewnego projektu zupełnie za darmo. No może niezupełnie za darmo – dla funu. Taki był ciekawy!`,
          },
        ],
      },
      {
        title: `Mantra dla banków to „uczyć się, ewoluować, adaptować”`,
        subtitle: `Wywiad z Bartoszem Rychlickim`,
        author: `Bartosz Rychlicki`,
        company: `Quantum cx`,
        questions: [
          {
            question: `Uważacie, że w biznesie uśmiech też ma znaczenie. Firmy to rozumieją?`,
            answer: `W obsłudze klienta, takiej twarzą w twarz, każdy dobry biznes dąży do perfekcji. Dlatego nie narzekamy na brak zainteresowania technologią Quantum CX – firmy chcą poprawiać obsługę klienta i my im to umożliwiamy. Motywujemy, namawiamy do życzliwości – i w zespole, i w kontakcie z klientem. Uśmiech jest dla nas kluczem, aktywnością, na którą każdy może się zdobyć, żeby zmienić swoje nastawienie wobec drugiej osoby już w trakcie konwersacji. Tu wkracza nasza technologia – rozpoznajemy i mierzymy ten uśmiech zaawansowanymi sensorami i oprogramowaniem. Następnie za uśmiechy nagradzamy pracownika punktami, a klienta – środkami przekazywanymi na wybrany przez pracownika cel charytatywny. W ten sposób poprawiamy atmosferę w miejscu pracy, wpływamy pozytywnie na relacje międzyludzkie. Uśmiech potrafi zdziałać cuda.`,
          },
          {
            question: `Skąd ten pomysł? Pracowaliście w obsłudze klienta?`,
            answer: `Gdy zaczynaliśmy sześć lat temu, o uśmiechach nie myśleliśmy tak wprost. Na początku były pasja badawcza, łączenia psychologii i technologii, oraz trzymanie się mantry, że skoro możesz coś pomierzyć, to możesz to poprawić. Na tej fali powstały nasze różne aplikacje: do zmiany nawyków, później do monitorowania nastroju i w końcu do pomiaru emocji z twarzy. Zaczęliśmy ją promować i szczęśliwie się złożyło, że sieć restauracji Costa Vide ze Stanów Zjednoczonych zaproponowała nam, żebyśmy przetestowali u nich tę technologię. Testy wypadły pozytywnie! Okazało się, że świetnie na obsługę klienta wpływa świadomość emocji, jakie się ma na twarzy, rozmawiając z nim. To działało też w drugą stronę.`,
          },
          {
            question: `Udany test dodał wam wiatru w żagle?`,
            answer: `Zrozumieliśmy, że możemy dalej rozwijać technologię w tym kierunku. I że jeśli dodatkowo zmotywujemy ludzi do uśmiechu, do tego, co pozytywne, to dla biznesu przyniesie to bardzo wymierne korzyści. Potem zrobiliśmy pogłębione wywiady z naszymi potencjalnymi klientami, podczas których wyszło, że im wszystkim brakuje pozytywnego zaangażowania pracowników przy obsłudze twarzą w twarz.`,
          },
          {
            question: `Wygląda to jak modelowe wejście na rynek dla startupu.`,
            answer: `O nie, to nie jest i nie było takie proste. Nawet nasz obecny sukces ma swoje cienie – mamy dużo dodatkowej pracy w kontakcie z potencjalnymi klientami czy mediami. Przychodzi wiele zaproszeń na konferencje, do akceleratorów, na testy. Do tego doszedł hejt związany z tym, że rzekomo zmuszamy ludzi do uśmiechu, co jest nieprawdą. Na szczęście w dużych firmach najczęściej dostajemy okazję, by opowiedzieć, jak jest w rzeczywistości. Co jednocześnie oznacza, że wiele innych spotkań czy eventów musimy teraz odpuścić z braku czasu. Choć to bardziej komfortowa sytuacja niż ta sprzed trzech lat, gdy mieliśmy pusto w kasie i cała ekipa przez parę miesięcy pracowała na oparach.`,
          },
          {
            question: `Teraz okazuje się, że rozkręcony startup to nie tylko produkt?`,
            answer: `Właśnie. Dotychczas byliśmy po prostu skupieni na produkcie, robiliśmy go i było fajnie. Wszyscy byli skoncentrowani na tej najważniejszej rzeczy. A teraz są m.in. PR, komunikacja z klientami oraz masa formalności.`,
          },
          {
            question: `Produkt się zmienia, czy jest skończony?`,
            answer: `Cały czas go rozwijamy. Jeszcze nie tak dawno używaliśmy go np. do sprawdzania, jakie emocje wywołują w nas reklamy. Potem chcieliśmy mierzyć również emocje negatywne w obsłudze klienta. To budziło niechęć u pracowników, wycofaliśmy się z tego. Skoro nie działa, nie ma się co upierać.`,
          },
          {
            question: `Dlaczego nie zrobiliście jeszcze kariery w Stanach?`,
            answer: `Byliśmy tam na testach, technologia de facto dopiero wówczas powstawała. Wrócimy tam wkrótce. Poza tym polski rynek ma naprawdę duży potencjał. W Stanach nie ma mody na startupy, bo one po prostu są tam od zawsze. Tymczasem w Polsce, w Unii Europejskiej, ta wajcha przechyla się na korzyść małych, innowacyjnych firm. Duże organizacje wręcz zapraszają nas na spotkania, prezentacje. Jeszcze kilka lat temu było to nie do pomyślenia.`,
          },
          {
            question: `Brzmi jak american dream nad Wisłą.`,
            answer: `W ramach studzenia entuzjazmu powiem, że w Polsce odczuwalny jest nieprzyjazny, hierarchiczny system w firmach. To są niemal monarchie: pytanie szefa, który zapyta szefa, który pyta kolejnego szefa. 15 poziomów w głąb. Tymczasem w Stanach spotykasz się z osobą decyzyjną, ona podejmuje ryzyko w imieniu firmy i działacie. Szef tej sieci restauracji w USA zarządzał organizacją mającą przychód 500 mln USD rocznie. I co? Podwoził nas po taśmę klejącą do sklepu, żeby kable od czujników nie dyndały, żeby test ruszył.`,
          },
          {
            question: `Ta hierarchizacja to bardziej kwestie mentalne czy systemowe, takie wprost z procedur firm?`,
            answer: `Nad Wisłą zauważalna jest niechęć do decyzji, duża obawa przed ryzykiem, nie tylko w bankowości. W Stanach najgłupsze, co można zrobić, to nie wydawać pieniędzy, pozwolić im się nudzić. Lepiej zaprząc je choćby w ryzykowne inwestycje. W Polsce jest na odwrót i to czuć na co dzień. Choć da się, przecież pracujemy już z m.in. dwoma bankami, liderami rynku w Polsce. Rozwijamy się też w segmencie krótszych interakcji pracownik – klient, np. w call center, a także w takich miejscach, gdzie ten styk trwa chwilę – stacjach paliw, aptekach.`,
          },
          {
            question: `Banki nie chcą, ale muszą?`,
            answer: `Z jednej strony prą ku innowacjom, a z drugiej mają często swoje skostniałe procedury, wolno podejmują finalne decyzje. Jednak z pewnością wiedzą, że same nie są w stanie w rozsądnym czasie i przy takichże kosztach stworzyć rozwiązań klasy np. Quantum CX. W czasie, gdy startup w dwa, trzy tygodnie skleci jakiś działający prototyp, bank co najwyżej znajdzie czas na spotkanie. No to się spotykamy na zasadzie symbiozy: oni mają potrzebę, czas i zasoby, przestrzeń do testów i wdrożeń, ale nie mają zdolności do innowacji i kreatywności. Przy czym bywa tak, że spotykamy świetnych, dynamicznych ludzi po stronie banku. W gruncie rzeczy cała sprawa rozbija się o ludzi.`,
          },
          {
            question: `Są łącznikiem między tymi dwoma światami – dużych i małych?`,
            answer: `Tak, coraz częściej są to osoby rozumiejące, że miesiąc w banku to mało czasu, a u nas to prawie wieczność. Dlatego budujemy z nimi relacje, doceniamy ich. Mając komfort, że ktoś ogarnia całą otoczkę po stronie dużej organizacji, możemy się skupić na dopięciu dealu.`,
          },
          {
            question: `Czyli warto czekać na banki?`,
            answer: `Warto. Pytanie, czy nie można czekać krócej. Nie ukrywam, że robimy biznes, więc działamy dla pieniędzy, niekoniecznie sławy. Choć współpraca z tak dużym partnerem, jak np. PKO Bank Polski, oznacza również świetne pole do usprawnień produktu w realnym środowisku i odpala marketingową rakietę, to najważniejszy jest przychód. Fakt, że klient kupuje produkt, a nie tylko się nim chwali lub waha się w nieskończoność. Warto, żeby już na początku obie strony powiedziały sobie jasno, co i kiedy musi się wydarzyć, by doszło do wdrożenia. I trzymać się tych założeń.`,
          },
          {
            question: `No i porzucić strach.`,
            answer: `Może bardziej obawę przed zainwestowaniem większych kwot. Pewnie miesięczny koszt dostarczania kawy do ekspresu w biurowcach banku jest wyższy niż nasze rozwiązanie, ale z kawą nikt nie chodzi i się nie zastanawia, jak zmniejszyła się rotacja dzięki kolumbijskiej arabice w ekspresach na 17. piętrze. A jak przychodzi do wydania np. 30 tys. PLN na nasze rozwiązanie, to nagle pojawiają się kohorty analityków oglądających każdy grosz i jego efektywność. To zajmuje mnóstwo czasu i implikuje biurokratyczne przepychanki.`,
          },
          {
            question: `A w tym czasie Revolut i inni sieją rewolucję.`,
            answer: `Mentalność spod hasła „nie ruszajmy, bo zepsujemy” ma wciąż swoich zwolenników w finansach. Ale banki nie będą miały wyjścia – muszą jeszcze sprawniej robić to, co już zaczęły. Uczyć się, ewoluować i adaptować. Wspomniany Revolut udowodnił, że rozwiązania technologiczne realnie zmieniają ten tradycyjny rynek finansowy. Dużym instytucjom szybko rośnie nowa konkurencja.`,
          },
        ],
      },
      {
        title: `W Polsce to banki są fintechami`,
        subtitle: `Wywiad z Michałem Pawlikiem`,
        author: `Michał Pawlik`,
        company: `SMEO SA`,
        questions: [
          {
            question: `Patrząc na wasze CV jako założycieli SMEO, widzę, że weterani podbijają fintech.`,
            answer: `Zarówno ja, jak i Agnieszka Gołębiewska oraz Monika Woźniak jesteśmy startupowcami z gatunku tych zaprawionych w bojach, z sukcesami w korporacjach, bankach, w prywatnym biznesie. To nasz atut. Wcześniej rozwijaliśmy w tym składzie dwie inne firmy faktoringowe: Idea Money, założoną w 2011 r., którą przejął Idea Bank za 170 mln PLN, oraz biznes pożyczkowo-faktoringowy pod marką e-faktoring.pl. Jednak SMEO, choć jest także firmą faktoringową, technologicznie stoi w innym miejscu. Dostarczamy faktoring online, finansując mikroprzedsiębiorców w sieci. Robimy to najbardziej online i najbardziej cyfrowo, jak się da.`,
          },
          {
            question: `Maszyny zamiast ludzi?`,
            answer: `To za dużo powiedziane. W procesie decyzyjnym faktycznie nie występuje czynnik ludzki. O decyzji i wypłacie środków decydują automaty, silniki oparte na machinelearningu. To pokazuje nasze zaawansowanie technologiczne. Z drugiej jednak strony, pamiętajmy, że SMEO tworzą doświadczeni specjaliści. To oni, a nie maszyny, mają ostatnie słowo. Zdarza się, że wnioski naszych klientów rozpatrujemy indywidualnie i wydajemy tzw. decyzję manualną.`,
          },
          {
            question: `Na faktoring w offline nie ma już miejsca?`,
            answer: `Jest, ale to Internet jest dzisiaj tym kanałem, w który przesuwa się ruch klientów, instytucji i funkcjonowanie produktów finansowych. Ludzie dzisiaj zaczynają szukać produktów finansowych w Internecie. Widzimy to już po tych najprostszych ofertach, np. pożyczek konsumenckich. Wprowadzając usługi dla mikrofirm, z jednej strony mamy takich partnerów biznesowych jak Santander Leasing, a z drugiej – masowego klienta, setki osób-firm dziennie.`,
          },
          {
            question: `Takie wolumeny działają na wyobraźnię. Jesteście przykładem sukcesu na styku finansów i technologii?`,
            answer: `Całościowo, na dziś, raczej unikamy takich określeń. Sukces startupu jest w tym momencie, kiedy osiąga on poziom dojrzałej firmy, mającej swoja rentowność. Firmy, która odrobiła zainwestowane pieniądze albo poprzez osiągnięcie odpowiednich wyników finansowych, albo poprzez to, że się sprzedała lub zrobiła IPO. My? Po prostu żyjemy i rośniemy w kierunku większej firmy, tzw. scaleupu. Mamy dobrego inwestora. To są jakieś sukcesy, ale jeszcze nie przez wielkie „s”.`,
          },
          {
            question: `Wiele fintechów nie dożywa nawet tych przez małe „s”.`,
            answer: `Owszem. U nas kluczowe okazuje się doświadczenie zespołu: merytoryka, wiedza, świadomość tego, co i po co się robi. Startup senioralny oznacza, że twardo stąpamy po ziemi. Działamy na, w jakimś stopniu, sprawdzonym rynku, w wypróbowanym modelu biznesowym, ale podkręciliśmy całość technologicznie.`,
          },
          {
            question: `SMEO musi się udać?`,
            answer: `Mamy na pewno wyczucie czasu i rodzącej się potrzeby kluczowego dla nas rynku – polskiego. Często bardzo innowacyjne startupy wyprzedzają swój rynek i klienci nie są na nie gotowi. Albo z kolei zbyt długo wdrażają swój pomysł, szukają finansowania – trwają wtedy w stagnacji, ktoś je wyprzedza, zmienia się świat. A my jesteśmy ledwie odrobinkę za wcześnie, czyli mamy świetny timing, budujemy nową kategorię. Faktoring w Polsce na rynku mikroprzedsiębiorstw czeka szybki rozwój. Według naszych badań tylko 4 proc. przedsiębiorstw korzysta z tego produktu, a zaledwie ok. 10 proc. zna podstawy funkcjonowania faktoringu jako finansowania przedsiębiorstw.`,
          },
          {
            question: `Ziemia obiecana.`,
            answer: `Z kilku względów dla takich firm jak SMEO Polska jest bardzo atrakcyjna. Żeby być w Europie Środkowo-Wschodniej, trzeba zacząć tutaj, a dopiero potem planować ekspansję do innych krajów ościennych. Trzeba też brać pod uwagę tę specyfikę polskiego systemu bankowego, który jest niezwykle innowacyjny. Tak naprawdę to banki są fintechami w Polsce.`,
          },
          {
            question: `Ale to wasi partnerzy i konkurenci zarazem?`,
            answer: `Tak to widzę. Na tle europejskim polskie banki wyróżniają się dramatycznie swoją technologią. Wyróżniają in plus, co nie zamyka ich na innowacje z zewnątrz. Dowód? Przy dystrybucji naszego produktu obecne są m.in. banki czy instytucje finansowe, np. Santander Leasing. To świetny partner, któremu jest o tyle łatwo współpracować, że grupa Santander w Polsce nie ma produktu mikrofaktoringu. A my go mamy, więc połączyliśmy siły. I to działa, osiągamy założone cele.`,
          },
          {
            question: `Wcześniej doświadczyliście tej legendarnej bankowej biurokracji?`,
            answer: `Tak, ale nie była ona dla nas szokiem, pracowałem w banku, znam świetnie zasady ich działania. Ta kosmiczna biurokracja w bankach to immanentny element tych instytucji. I nie oczekiwałbym, że dzięki inspiracji startupów nagle ona wyparuje. Banki nie mogą być instytucjami pozbawionymi biurokracji, ponieważ to instytucje zaufania publicznego, którym ludzie (także bez głębokiej edukacji ekonomicznej) powierzają pieniądze. Zatem systemowo bank musi stać na straży tych pieniędzy i być ściśle nadzorowany. Do tego banki mają bezpośredni wpływ na makroekonomię. Upadek jakiegokolwiek banku w Polsce, nawet średniej wielkości, spowoduje ogromny chaos na rynku finansowym i może wywołać duże niepokoje społeczne. Dlatego ta biurokracja jest w znacznym stopniu zrozumiała, potrzebna. Trzeba się po prostu z tym pogodzić.`,
          },
          { question: `Założyć w biznesplanie?`, answer: `Tak.` },
          {
            question: `Wasz deal z bankiem szedł taką ścieżką?`,
            answer: `Szedł właściwą ścieżką, poznaliśmy się na jednej z branżowych konferencji w Krakowie, miałem tam prezentację i wyłapał mnie jeden z członków zarządu banku, był pod wrażeniem. Duże zaangażowanie zarządu od samego początku spowodowało, że projekt ruszył z kopyta. Oni szybko dostrzegli w tym szansę biznesową: po pierwsze finansową, a po drugie – powiew świeżości, jaki daje współpraca ze startupem. Potraktowali nas jak partnera, a nie jak dostawcę usług czy towarów do banku, np. papieru toaletowego.`,
          },
          {
            question: `To się zdarza?`,
            answer: `Miewamy złe doświadczenia z uznanymi instytucjami, na zasadzie – dobra, rozpiszemy przetarg, wpakujcie się w tabelkę, zagwarantujcie wskaźniki. A jak nie, to adieu. To szokujące, pokazuje nieprzygotowanie tych instytucji do partnerstwa. Choć może częściowo ta arogancja jest uzasadniona, w końcu banki w Polsce technologicznie, samodzielnie, bez fintechu są w stanie zrobić wiele. My jednak szukamy miejsc, gdzie – bez względu na różnice wielkości – rozmawiamy jak równy z równym, gdzie jesteśmy po prostu graczem z ich rynku. W Stanach czy w Wielkiej Brytanii takie podejście to standard.`,
          },
          {
            question: `Może chodzi też o konkurencję z waszej strony?`,
            answer: `Raczej o niedostrzeganie potencjalnych synergii. W porządku, my pożyczamy pieniądze małym firmom. Banki pożyczają pieniądze małym firmom. Ja mam tego samego klienta, co oni, ja jestem dla nich konkurentem. Ale… ja mogę pożyczać trochę inaczej te pieniądze, oni muszą inaczej, bo mają regulatora, mają nie swoje pieniądze, tylko pieniądze depozytariuszy. Ja mam pieniądze od inwestorów, którzy mogą je spalić w kominku albo to ja mogę skłonić moich inwestorów, żeby je pożyczyli mikrofirmom. Tym się różnimy od banku. I przez to ja mam inną filozofię pożyczania pieniędzy. Możemy się doskonale uzupełniać. SMEO może dotrzeć do klientów, do których bank, nawet gdyby chciał, to nie może. Bo systemowy gorset mu na to nie pozwala.`,
          },
          {
            question: `Razem możecie dużo więcej?`,
            answer: `Na pewno, technologicznie to niesamowicie innowacyjny rynek w Polsce. To może być dobra trampolina do ekspansji za granicą. To w Polsce wiele technologii jest wdrażanych po raz pierwszy , np. płatności bezdotykowe, podczas gdy w Stanach dalej płaci się czekami. Fintechy nie wywołają jednak w Polsce rewolucji, banki są za mocne technologicznie. Ale firmy takie jak SMEO mają jak najbardziej sens, to one podrasowują ten rynek, poprawiają go w pojedynczych aspektach. Fintechy potrafią szybciej wyczuć trend, zmiany kulturowe, społeczne, mody, nową potrzebę klienta. Klienci raczej wybierają dobry produkt na rynku finansowym, a nie bank lub fintech. W to nam graj.`,
          },
        ],
      },
      {
        title: `Tu do tanga trzeba dwojga`,
        subtitle: `Wywiad z Michalem Smida`,
        author: `Michal Smida`,
        company: `Twisto Polska`,
        questions: [
          {
            question: `Mówicie o sobie, że zajmujecie się płatnościami. Jednak z punktu widzenia konsumentów to już nie takie jednoznaczne, prawda?`,
            answer: `Bo Twisto to zupełnie inny wymiar płatności... Jesteśmy znani z hasła „kup teraz, zapłać później” – nasz klient sam decyduje, kiedy chce zapłacić za zakupy. Czy od razu, czy do 15. dnia następnego miesiąca. Rozwijamy też inne funkcje. Kupując za granicą, klient korzysta z najlepszego kursu, bez opłat i prowizji, i nie musi pamiętać o wcześniejszej wymianie waluty. Jeśli zdecyduje, że chce przesunąć płatność na później, zrobi to z łatwością jednym kliknięciem w aplikacji. Od niedawna użytkownicy Twisto mogą także korzystać z łatwego podziału rachunku na kilka osób, a wkrótce możliwe będzie także rozłożenie większego wydatku na raty. Co ważne – to wszystko dzieje się między klientem i aplikacją, bez wizyty w oddziale, telefonów czy kuriera. W skrócie – z Twisto klient może płacić po swojemu, nie tylko jak chce, ale też kiedy chce.`,
          },
          {
            question: `Aplikacja i karta w przypadku niebankowej firmy brzmi nieco jak… Revolut.`,
            answer: `Często jesteśmy porównywani do Revoluta, choć w przeciwieństwie do naszego konkurenta dajemy klientowi możliwość korzystania z bezpłatnego limitu kredytowego, dzięki czemu nie musi on „wykładać własnych pieniędzy”. Różni nas również idea prowadzenia biznesu. Nie chcemy, niczym Revolut, wypierać banków, zwłaszcza że jeden z nich, ING, jest naszym akcjonariuszem. Wyróżnia nas chęć życia w synergii z bankami, a jednocześnie pokazania, że w płatnościach w sieci, i nie tylko, można mieć absolutnie prokonsumenckie, ale i bezpieczne podejście. Tworząc Twisto, inspirowaliśmy się szwedzką Klarną, która również oferuje płatności odroczone, ale nie działa w naszym regionie. Z Klarną, w krajach nordyckich, mogę wejść do sklepu internetowego czy stacjonarnego, kupić lodówkę i zapłacić za nią w ciągu 2 tygodni bez żadnych dodatkowych kosztów. Dlaczego więc taki model nie miałby zadziałać w CEE? Dziś my także oferujemy unikalne rozwiązanie i to w naszym regionie.`,
          },
          {
            question: `Zrobiliście zatem technologię, zdobyliście finansowanie. Banki nie poczuły się wywołane do tablicy?`,
            answer: `Oczywiście, zostaliśmy zauważeni, w końcu funkcjonujemy z bankami na tym samym rynku finansowym. Kluczowa jest jednak specyfika finansowa regionu CEE. W zestawieniu my – jako region – kontra Zachód uderzająco niski jest odsetek penetracji rynku kartami kredytowymi. Banki są świetne w sprzedawaniu kont i kredytów hipotecznych, ale na polu finansowania instant idzie im gorzej. Szybsi są pożyczkodawcy pozabankowi. Ci mają z kolei bardzo wysokie oprocentowanie, więc są atrakcyjni tylko dla określonej grupy konsumentów. Banki wiedzą, że nie są w stanie wszędzie i zawsze być pierwsze, nie mają zwykle tej odwagi i zwinności co startupy, do tego koncentrują się – co naturalne – na bardziej przewidywalnych i dochodowych liniach biznesowych. Twisto działa w segmencie bieżącego, codziennego finansowania i to właśnie tu dostarczamy klientowi unikalną wartość. Jednak nie ulega wątpliwości, że banki bacznie śledzą rynek, a do tego – jak obserwujemy w Polsce – myślą o nowych technologiach, inwestują w nie, a jednocześnie utrzymują wysokie zaufanie wśród swoich klientów. Właśnie dlatego dla nas również był to ciekawy kierunek współpracy. Tak naprawdę każdy bank jest niejako partnerem w naszym biznesie – każdy, kto kupuje z Twisto, musi finalnie spłacić to zobowiązanie. I niemal zawsze robi to z pomocą banku. Choć mamy i takie przypadki jak pani Janina, która płaci za swoje zakupy przekazem pocztowym i pisze nam: „Dziękuję, że jesteście, bo nie muszę mieć konta w banku, żeby kupować w Internecie” (śmiech).`,
          },
          {
            question: `Dogadujecie się z bankiem jako inwestorem? Stereotypowo banki kojarzone są raczej jako sztywne korporacje, niespecjalnie stworzone do kooperacji ze startupami. `,
            answer: `Z pewnością wejście w kapitałowe relacje z bankiem to dla startupu niełatwa decyzja. Młody, ale ambitny, elastyczny organizm, złożony z ludzi z różnych branż, zderza się z wielką machiną międzynarodowej korporacji. Jednak ING to bank innowacyjny i otwarty na partnerską, uczciwą relację z fintechem. Dziś nasze relacje są bardzo dobre i szczere. Ich spoiwem jest osiąganie założonych wyników, procesów i wsparcie w kluczowych momentach. Nie ukrywamy, że inwestycja ING zawęziła nam potencjalną listę bankowych partnerów, także w Polsce. Jednak stawiając na ING, zyskaliśmy też w Twisto coś, czego zazdrości nam wiele firm –zaufanie rynku, jakie daje taki wiarygodny inwestor. `,
          },
          {
            question: `Banki dostrzegają swój potencjał na polu współpracy ze startupami?`,
            answer: `Są przykłady banków w Polsce, które otworzyły swoje drzwi przed startupami. Poza ING to m.in. mBank i PKO Bank Polski – czołówka innowatorów nad Wisłą. W ten sposób te wielkie organizmy szukają opcji na elastyczność. Alternatywą jest zakładanie spółek-córek, które będą w stanie pracować ze startupami w sposób zwinny i bezpośredni.`,
          },
          {
            question: `To takie obniżenie poprzeczki dla pretendentów?`,
            answer: `Trochę tak, jednak bądźmy szczerzy – jeśli dotykamy rynków regulowanych, to nie warto iść na skróty. Warto dostosowywać się do standardów bankowych. Co nie znaczy, że nie można pokazać bankom, jak do tego samego miejsca dojść efektywniej i taniej. W takiej sytuacji współpraca na linii bank – startup nabiera rumieńców. Oczywiście pod warunkiem, że także bank stara się wejść w tę relację, rozumiejąc, że startup nie jest w stanie się rozwijać, będąc dopasowanym do wszystkich wewnętrznych regulacji banków. W startupach nie ma zwykle armii korporacyjnych ekspertów i sztabu prawników. Solidny startup koncentruje swoje siły na maksymalnie jednym, dwóch kluczowych projektach z dużymi instytucjami, bo próba złapania zbyt wielu srok za ogon może szybko pochłonąć jego energię i uszczuplić dostępne zasoby.`,
          },
          {
            question: `Banki to rozumieją?`,
            answer: `Coraz częściej tak. Tu – w świecie początkujących fintechów – duży może więcej, ale i musi być ciekawy, szybki, zdecydowany, by nie przegapić szansy. Przeciąganie startupu przez kilkumiesięczny korporacyjny „młyn” to najlepszy przepis na jego śmierć z wycieńczenia i braku środków. Do tego warto szukać szeroko w całym regionie; jest sporo firm z Estonii, Litwy, Łotwy, mających świetne rozwiązania. Choć oczywiście niektóre rozwiązania, wdrażane w Stanach czy w Izraelu, w Polsce są – z powodów regulacyjnych – po prostu niemożliwe. Co nie znaczy, że nie ma tu miejsca na projekty absolutnie wyjątkowe, z globalnym potencjałem. Warunkiem sukcesu jest jednak założenie, że współdziałanie w ramach ekosystemu jest korzystne dla każdej ze stron. Widzę wyraźnie, że bardzo często startup z branży finansowej potrzebuje właściwego partnera korporacyjnego (np. banku), aby dotrzeć do szerokiego rynku i szybko się rozwijać. Bank zaś potrzebuje startupu, aby szybciej i efektywniej wprowadzać innowacje oraz docierać do nowych grup klientów. Do tego tanga trzeba dwojga.`,
          },
        ],
      },
      {
        title: `Gotowe rozwiązanie to wytrych do korporacji`,
        subtitle: `Wywiad z Bogusławem Biedą`,
        author: `Bogusław Bieda`,
        company: `Vindicat.pl`,
        questions: [
          {
            question: `Jak to się stało, że po 20 latach kariery w korporacjach zrobiłeś zwrot o 180 stopni i objąłeś pakiet kontrolny w startupie?`,
            answer: `Złożyło się na to kilka elementów. Po pierwsze, chciałem odejść z korporacji i poszukać ciekawego projektu do inwestycji z możliwością budowania wartości firmy, wykorzystując moje dotychczasowe doświadczenia. Po drugie, analizując rynek startupów w Polsce, rozmawiałem z kilkoma potencjalnymi partnerami i założyciele Vindicat bardzo spodobali mi się pod kątem ciekawego projektu, mieli doświadczenie i energię. Gdy wszedłem na pokład, uznałem, że produkt jest gotowy do sprzedaży na rynku, ale wymaga odpowiedniego podejścia z punktu widzenia komercjalizowania tego rozwiązania. Bo właśnie tym głównym argumentem „za” było samo rozwiązanie. Bo czym w istocie jest Vindicat? To aplikacja odpowiadająca na potrzeby przedsiębiorców, pomagająca im odzyskiwać pieniądze z niezapłaconych faktur. To jedyne rozwiązanie na rynku wspierające wszystkie elementy układanki związane z windykacją. Koncentrujemy się na segmencie małych i średnich firm (ale klientami są również korporacje): automatyzujemy procesy z obszaru legal tech, z obszarów prawnych i dajemy narzędzia do ich obsługi.`,
          },
          {
            question: `Co stanowi o waszej przewadze nad prawnikiem czy nękającą nierzetelnych klientów księgową?`,
            answer: `To zupełnie inny poziom, biorąc pod uwagę automatyzację, skuteczność czy koszty. My dajemy kompleksowe narzędzie, zaczynamy od szkolenia i prewencji, stawiamy na skuteczność i biznesową klasę. Wielu przedsiębiorców wciąż uważa, że windykacja to działanie na granicy prawa. Vindicat pokazuje ten proces od strony zarządzania należnościami jako systemowe, przemyślane działanie krok po kroku. Przełamujemy myślenie o tym, że jest to coś wstydliwego, niewłaściwego – to zabieganie o należne środki. To po prostu element business as usual. Dzięki nam przedsiębiorca może ten proces przeprowadzić sam. I to skutecznie.`,
          },
          {
            question: `Z racji na twoją wcześniejszą historię zawodową nie kusi cię wdrożenie tego także do dużych firm, korporacji? Czy może tam nie ma popytu na tego typu narzędzia?`,
            answer: `Znamy swoje miejsce w szeregu i wiemy, że teraz jesteśmy na etapie budowania świadomości produktu. To oznacza naszą obecność wszędzie tam, gdzie są również duże firmy, np. jesteśmy w programie EYnovation. Do tego bierzemy udział w programach akceleracyjnych takich jak np. Let’s Fintech with PKO Bank Polski. Jesteśmy też w dwóch innych bankach, w BNP Paribas i Aliorze. Tu działamy dwutorowo – z jednej strony chcemy zwrócić uwagę korporacji, że dzięki nam mogą automatyzować i usprawniać część procesów w działach prawnych lub windykacji, z drugiej – banki są już platformą pozwalającą nam promować produkt na rynku, co przecież w pojedynkę byłoby bardzo kosztowne. W ten sposób współpracujące z nami banki mogą swoim klientom – przedsiębiorcom oferować naszą usługę jako dodatek do konta. Efekt? Jesteśmy obecni w ofercie każdego z trzech banków. `,
          },
          {
            question: `Jak to przekłada się na wasze przychody?`,
            answer: `Obecnie wciąż testujemy różne modele dotarcia do klienta via bank, ale wydaje się, że najskuteczniejszy jest model wdrożony z Aliorem, gdzie wiemy, że klient ma niezapłaconą fakturę z systemu księgowego i możemy takiemu klientowi zaproponować naszą usługę. Tu trafiamy w niemal 100 proc. do przedsiębiorcy, który ma potrzebę zarządzenia taką fakturą. Za to w PKO Banku Polskim kierujemy swoją ofertę do poszczególnych branż, wyjątkowo narażonych na problemy płynnościowe i opóźnione płatności. W BNP Paribas testujemy jeszcze inny model sprzedaży, oferowania produktu w placówkach, a w przyszłości – również poprzez system księgowy.`,
          },
          {
            question: `Taką branżą są też startupy. Dla ciebie nie było to twarde lądowanie, po tym jak przeszedłeś na startupową stronę mocy?`,
            answer: `Zdawałem sobie sprawę, że w startupach jest wiele sprzedawania marzeń, a ich cykl życia bywa krótki. Dlatego zanim zdecydowałem się wejść w Vindicat, rozglądałem się po rynku, poznawałem firmy, ludzi, inwestorów. Koniec końców, kluczowym aktywem są ludzie. W korporacji masz całą wspomagającą strukturę, w startupie stajesz na pierwszej linii frontu wraz z czterema, pięcioma osobami, gotowymi do walki. Zatem ich kompetencje muszą być możliwie szerokie i powinny się uzupełniać. A do tego warto szukać osób gotowych do pracy w warunkach nieco poligonowych. Plusem, takim miękkim lądowaniem, było to, że moi przyszli wspólnicy mieli gotowy produkt, a do tego potrafili go sfinansować. Choć z tym ostatnim, jak to w startupach, bywało różnie.`,
          },
          {
            question: `Ciężko polegać na inwestorach?`,
            answer: `Nie o to chodzi. To kwestia biurokracji, ponieważ projekt był finansowany ze środków Unii Europejskiej i w pewnym momencie, po kontroli, założyciele dostali pismo od urzędników, że nie realizują projektu zgodnie z założeniami. Mówiąc inaczej – oddajcie pieniądze. To był bardzo stresujący okres dla założycieli spółki, procedura odwoławcza trwała kilka miesięcy, na szczęście zakończyła się przyznaniem racji Vindicat. Zresztą z finansowaniem startupów jest tak, że ciągłe pozyskiwanie kapitału i zarządzanie tym procesem to niemalże ich podstawowa działalność. Finanse potrafią brutalnie weryfikować marzenia. Na szczęście założyciele Vindicat twardo stąpają po ziemi. Gdy – m.in. z racji naszych relacji na rynku finansowym – zaczęto nam uchylać drzwi np. do banków, byliśmy gotowi zamieniać marzenia w rzeczywistość, komercjalizować projekt. Przejść od słów do czynów.`,
          },
          {
            question: `W jaki sposób ich przekonujecie?`,
            answer: `To nie jest aż takie trudne, jeśli możemy pokazać skończony, działający produkt i łatwo wykazać, jakie korzyści przyniesie on potencjalnemu partnerowi. Zatem my jesteśmy pewni swego i możemy z otwartą przyłbicą podchodzić do tematu. To daje nam uznanie w oczach dużych partnerów. Kładziemy im gotowe rozwiązanie na stół. Skoro banki zamienią się w tzw. marketplace z usługami dla przedsiębiorców, to my chcemy być tam na jednej z najwyższych półek. Banki prą w tę stronę, łakną innowacji, czując presję np. od takich graczy jak Revolut czy Klarna. Do tego jesteśmy innowacyjni, mamy kilka pomysłów, gdzie moglibyśmy jeszcze wdrożyć nasz produkt i rozmawiamy np. z branża ubezpieczeniową, która jest mi dobrze znana z racji mojego wcześniejszego doświadczenia. Pracuję nad tym, żebyśmy mogli to ogłosić, oczywiście wspólnie z korporacją. Czujemy się w tej relacji bardzo partnersko – rozumiem ich język, sygnały, dynamikę. To sprawia, że choć Vindicat nie jest potentatem, to nikt nie traktuje nas na zasadzie „o, znów przyszedł jakiś startupowiec i chce coś sprzedać”. Warto pokazywać wartość swoich rozwiązań dla korporacji oraz dla samych przedsiębiorców, którzy w tym obszarze mają jeszcze bardzo dużo do zrobienia – wielu nie monitoruje płatności i nie zarządza wierzytelnościami.`,
          },
          {
            question: `Nie dają wam odczuć, kto tu podejmuje kluczowe decyzje?`,
            answer: `Oczywiście, korporacja ma swoje prawa i trzeba je respektować. Tam czas płynie jakby wolniej, rok, półtora budowania relacji z bankiem może być szokujące dla startupu, ale dla banku jest standardem. Trzeba być cierpliwym. Choć w niektórych korporacjach byłem bardzo mile zaskoczony zespołami, które chciały z nami rozmawiać – ich tempem, podejściem, zrozumieniem tematu. Działy innowacji w bankach, z którymi pracujemy, są nastawione bardzo prostartupowo, chcą testować, wdrażać, rozwijać. Czuć, że sygnał idzie z góry, że zdecydowano, że dział innowacji ma realny głos, że jest właścicielem takich tematów. I w to nam graj, to dla nas bardzo duże ułatwienie, oni przecierają nam ścieżki w tych instytucjach, nie musimy wydeptywać ich sami czy szukać po omacku.`,
          },
          {
            question: `Macie też dobre relacje – i to kapitałowe – z aniołami biznesu. Wykorzystujecie ich wiedzę oraz doświadczenie, czy tylko ich pieniądze?`,
            answer: `Absolutnie wszystko. Aniołowie biznesu w naszym przypadku to Cobin Angels, finansowany przez czterech inwestorów prywatnych. Ich kontakty i doświadczenie, mentoring oszczędzają nam wielu potknięć, a do tego prostują ścieżki – czasami otwierają nam drzwi do gabinetów wysokich menedżerów i pozwalają omijać początkowe etapy kontaktów biznesowych. Choć czasem to trzeba wyważyć, bo nie warto od razu iść do prezesa, skoro decyzję i tak podejmują menedżerowie niższego szczebla. Wówczas lepiej rozmawiać z nimi niż z kolegą anioła biznesu.`,
          },
          {
            question: `Czy nie wystarczy wam po prostu rekomendacja wynikająca ze współpracy z dużymi markami?`,
            answer: `To w dużym stopniu pomaga. Gdy ogłosiliśmy współpracę z PKO Bankiem Polskim, z miejsca odezwało się z 10 funduszy inwestycyjnych z pytaniem, czy nie chcemy pieniędzy. Podobny optymizm zasiany został także wśród decydentów w korporacjach. Jednak przy samej współpracy jechanie na „dużym brandzie” nie wystarczy. Trzeba szanować swojego partnera i bardzo jasno komunikować mu procesy, etapy, ewentualne problemy. Zresztą to powinno działać w dwie strony.`,
          },
          {
            question: `Właśnie – w dwie strony. Nie każdy fintech, czy tak jak wy – regtech, widzi siebie jako dodatek do usług bankowych. Czy fintechy mogą zrewolucjonizować polski sektor finansowy?`,
            answer: `Zapewne. Jednak pojawia się pytanie, czy ta rewolucja nie będzie polegać właśnie na tym, że gdy powstanie przełomowe rozwiązanie, to banki je skopiują u siebie lub kupią na własne potrzeby. To zaś zmieni cały sektor finansowy, ale… nie zmarginalizuje banków. Pamiętajmy, że „stary” świat finansów trzyma się mocno i ma świadomość, że w jego interesie jest to, żeby zagrożenia zamieniać w szanse lub po prostu je neutralizować. Nie mówię tego w negatywnym kontekście, to raczej rynkowe realia. Często za granicą słyszę pochwały dla polskiego rynku finansowego, za jego innowacyjność, nowoczesny styl zarządzania. My przy tym mamy swoje, ambitne cele także poza Polską – naszym kolejnym krokiem są rynki CEE. Tam też chcemy zasiewać nasze ziarna rewolucji.`,
          },
        ],
      },
    ],
  },
  startupWishesSection: {
    id: `startupowa-lista-zyczen`,
    header: {
      main: `Startupowa lista życzeń`,
      subtitle: `Czyli 10 rad dla banków od startupów – jak ułożyć i usprawnić współpracę?`,
    },
    items: [
      {
        title: `Dajcie nam przewodnika`,
        text: `Skoro współpraca ze startupem angażuje tyle osób i procesów po waszej stronie, wyznaczcie
      zespół, który będzie interfejsem usprawniającym nasze kontakty. Wy potrzebujecie
      efektywnych procesów włączania innowacji do dużej organizacji, a my – przewodnika, który
      przeprowadzi nas przez wdrożenie i zrozumie specyfikę działania startupu.`,
      },
      {
        title: `Ustalmy reguły gry`,
        text: `Sprecyzujcie jasno swoje oczekiwania i potrzeby już na początku naszej drogi. Powiedzcie
      nam, jakie kryteria zadecydują o tym, że wybierzecie nas do współpracy, jakie będą jej etapy i
      co musi się wydarzyć, aby doszło do wdrożenia. Jeśli obie strony będą trzymać się tych
      założeń, współpraca ułoży się gładko i unikniemy nieporozumień.`,
      },
      {
        title: `Postawmy zdrowe granice`,
        text: `Nie oczekujemy, że zaangażujecie się w proces budowy naszej firmy, nauczycie nas, jak
      prowadzić biznes lub że będziecie naszym „patronem”. Jesteście dla nas klientem, partnerem
      biznesowym i takiej relacji się trzymajmy. Zdrowy model to: wykonana praca = płaca, nawet
      jeśli to „tylko” PoC.`,
      },
      {
        title: `Spotykajmy się po konkrety`,
        text: `Nie traćmy dnia na niepotrzebne spotkania. Ustalmy jasno, kto i w jakim zakresie musi być
      zaangażowany we współpracę po obydwu stronach oraz co musimy wspólnie ustalić.
      Unikniemy w ten sposób miesięcy niekończących się spotkań.`,
      },
      {
        title: `Nie zasypujcie nas przepisami`,
        text: `Znamy się na technologii i biznesie, ale nierzadko potrzebujemy wsparcia, aby sprostać
      waszym procedurom wewnętrznym i regulacjom prawnym. Pamiętajcie, że nie mamy armii
      prawników po swojej stronie, a nasze zasoby w tym zakresie są ograniczone, dlatego nie
      zostawiajcie nas samych i pomóżcie dostosować się do procedur i regulacji w naszym
      wspólnym projekcie.`,
      },
      {
        title: `Testujcie, a&nbsp;nie procesujcie`,
        text: `Mamy sprawdzoną technologię i silny zespół – jesteśmy gotowi, aby pokazać wam, jak działa
      nasze rozwiązanie i takiej gotowości oczekujemy też z waszej strony. Dlatego przygotujcie
      się na sprint, a nie spacer – stwórzcie dedykowane środowisko technologiczne i pozwólcie
      nam pokazać, co potrafimy. Jeśli nie na produkcji, to chociaż w sandboxie.`,
      },
      {
        title: `Dzielcie się feedbackiem`,
        text: `Najwięcej uczymy się od naszych klientów, dlatego powiedzcie nam, co sądzicie o naszym
      rozwiązaniu. Wasz feedback pozwoli nam je dostosować i rozwinąć zgodnie z waszymi
      oczekiwaniami. Pamiętajcie – dobry feedback jeszcze nikomu nie zaszkodził.`,
      },
      {
        title: `Postawcie na partnerstwo`,
        text: `Jest między nami oczywista dysproporcja, ale traktujcie nas poważnie i po partnersku.
      Pamiętajcie, że startup nie jest tradycyjnym dostawcą produktów czy usług, a może wnieść do
      waszego biznesu dodatkową wartość i know-how. Warto zatem, abyście traktowali nas jak
      swojego partnera – taka relacja przełoży się na jakość naszej współpracy.`,
      },
      {
        title: `Otwórzcie głowy na nowe`,
        text: `Nowych problemów nie rozwiążemy, trzymając się starych schematów, otwórzcie się zatem
      na nowe rozwiązania i zmieńcie podejście z „na pewno się nie da” na „OK, spróbujmy”.
      Lepiej będzie nam się pracowało, jeśli będziemy odbierać na tych samych falach, najlepiej na
      wszystkich szczeblach organizacji.`,
      },
      {
        title: `Zobaczcie w&nbsp;nas ludzi`,
        text: `Robimy razem biznes, ale pracujemy wspólnie – ostatecznie wszystko i tak rozbija się o ludzi.
      Dlatego nie patrzcie na nas tylko przez pryzmat rozwiązania i technologii, które dostarczamy.
      Zależy nam też na bezpośrednich i dobrych relacjach między naszymi zespołami.`,
      },
    ],
  },
  founderTipsSection: {
    id: `founder-radzi`,
    header: {
      main: `Founder radzi`,
      subtitle: `Czyli 5 rad dla startupów, które chcą współpracować z bankiem`,
    },
    items: [
      {
        title: `„Insider” otwiera drzwi`,
        text: `Jeśli nie pracowałeś w banku (lub innej korporacji), ciężko będzie ci zrozumieć specyfikę
      tego środowiska. Dlatego warto jest włączyć do zespołu kogoś z doświadczeniem w sektorze,
      kto zna ten świat od podszewki. Nie tylko lepiej zrozumiesz, jak bank działa od środka, lecz
      także zyskasz wartościowe kontakty.`,
      },
      {
        title: `Co za dużo, to niezdrowo`,
        text: `Jeśli chcesz sprzedawać do korporacji, skup się na maksymalnie dwóch, trzech dużych
      klientach jednocześnie. Dobra obsługa takiej współpracy wymaga nakładu czasu i pracy.
      Dlatego nie bierz na siebie zbyt wiele i bądź gotowy, finansowo i organizacyjnie, na co
      najmniej kilkumiesięczny korporacyjny młyn.`,
      },
      {
        title: `Rozwiązanie gotowe na bank`,
        text: `Współpraca z instytucją finansową to nie spacerek – oczekiwania wobec rozwiązania i
      zespołu są wysokie. Dlatego odrób pracę domową i bądź gotowy bronić swojego
      produktu/usługi w każdym aspekcie – technologii, modelu biznesowego czy potencjalnego
      ryzyka. Zadbaj o to, aby technologia była bezpieczna i zgodna z obowiązującym prawem. Na
      pewno zostaniesz o to prędzej czy później odpytany.`,
      },
      {
        title: `Anioł w&nbsp;korporacji czyni cuda`,
        text: `Relacje to podstawa, dlatego znajdź w banku osobę lub zespół, który uwierzy w twoje
      rozwiązanie, pomoże ci „sprzedać” je wewnątrz organizacji, będzie nadzorować wdrożenie i
      dotrzymywać terminów. W takim tandemie będzie ci się łatwiej już od samego początku.`,
      },
      {
        title: `Język korzyści, a&nbsp;nie nachalnej reklamy`,
        text: `Sprzedając swoje rozwiązanie do banku, zapomnij o reklamie, a skup się na konkretnej
      wartości, jaką wnosisz do danej organizacji, i problemach, jakie rozwiązujesz. Jeśli bank
      dostrzeże wartość na początku, chętniej będzie przedzierać się wspólnie z tobą przez kolejne
      etapy współpracy.`,
      },
    ],
  },
  meetStartupsSection: {
    id: 'poznaj-startupy',
    header: `<strong>Poznaj 100 startupów,</strong> które zmieniają polską bankowość`,
    button: `Poznaj startupy`,
  },
  reportAuthorsSection: {
    id: `autorzy-raportu`,
    header: `Autorzy raportu`,
  },
};

import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import { baseButtonStyles } from '../../utils/styles';

const IconButtonBox = styled.button`
  ${baseButtonStyles};
  width: 2.8125rem;
  height: 2.8125rem;

  @media ${breakpoints.md} {
    width: 3.75rem;
    height: 3.75rem;
    font-size: 1.875rem;
  }
`;

export default IconButtonBox;

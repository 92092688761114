import React from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';
import Container from '../atoms/Container';
import breakpoints from '../../utils/breakpoints';
import { PKOH4 } from '../atoms/Headers';
import DownloadReport from '../images/DownloadReport';
import LinkLarge from '../atoms/LinkLarge';
import LinkBold from '../atoms/LinkBold';
import List from '../atoms/List';
import ListItem from '../atoms/ListItem';
import SmallFooter from '../molecules/SmallFooter';
import FooterDivider from '../atoms/FooterDivider';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { getRoute } from '../../utils/router';
import ReportAuthorsButton from '../organisms/ReportAuthorsButton';
import { Link } from 'gatsby';

const StyledFooter = styled.footer`
  background-color: ${colors.blueLight};
  overflow: hidden;
`;

const Grid = styled(Container)`
  display: -ms-grid;
  display: grid;
  color: white;
  padding-top: 2.5rem;
  grid-column-gap: 2rem;
  grid-template-areas:
    'a'
    'b'
    'c'
    'd';

  @media ${breakpoints.md} {
    grid-template-areas:
      'a c'
      'b c'
      'd d';
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 3rem;
  }

  @media ${breakpoints.lg} {
    padding-top: 3.75rem;
    grid-template-areas:
      'a b c'
      'd b c';
    -ms-grid-columns: 1.5fr 1fr 2fr;
    grid-template-columns: 1.5fr 1fr 2fr;
    grid-column-gap: 3rem;
  }
`;

const LargeLinks = styled.div`
  grid-area: a;

  @media ${breakpoints.lg} {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row-span: 1;
  }
`;

const SmallLinks = styled.div`
  grid-area: b;

  @media ${breakpoints.lg} {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-row-span: 2;
  }
`;

const FooterDividerOnlyLarge = styled(FooterDivider)`
  display: none;

  @media ${breakpoints.lg} {
    display: block;
  }
`;

const StyledLinkLarge = styled(LinkLarge)`
  display: inline-block;
  margin: 1.5rem 0;
`;

const StyledSmallFooter = styled(SmallFooter)`
  background: ${colors.black};
  grid-area: d;
  padding: 25px;
  margin-left: -25px;
  margin-right: -25px;

  @media ${breakpoints.md} {
    padding: 25px 30px;
    margin-left: -30px;
    margin-right: -30px;
  }

  @media ${breakpoints.lg} {
    background: none;
    margin: 0;
    color: ${colors.blueLighter};
    padding: 2rem 0 2rem 0;

    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-row-span: 1;
  }
`;

const NavigationItem = styled(ListItem)`
  margin: 1.5rem 0;
`;

const StyledDownloadReport = styled(DownloadReport)`
  margin-left: -15px;
  margin-right: -15px;
  transition: transform 200ms;
  transform-origin: center bottom;

  @media ${breakpoints.md} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const DownloadReportContainer = styled(Link)`
  grid-area: c;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none !important;
  color: white;

  &:hover {
    color: ${colors.blueLighter};
  }

  @media ${breakpoints.md} {
    justify-content: flex-end;
  }

  @media ${breakpoints.lg} {
    justify-content: space-between;

    -ms-grid-row: 1;
    -ms-grid-column: 3;
    -ms-grid-row-span: 2;
  }

  &:hover {
    & > ${StyledDownloadReport} {
      transform: scale(1.04);
    }
  }
`;

const DownloadReportHeader = styled(PKOH4)`
  font-size: 1.875rem;
  margin-top: 1.5rem;
`;

const Footer = ({ onRedirect }) => {
  const intl = useIntl();
  const safeOnRedirect = onRedirect || (() => null);

  return (
    <StyledFooter>
      <Grid>
        <LargeLinks>
          <FooterDividerOnlyLarge />
          {/*<StyledLinkLarge*/}
          {/*  to={getRoute('ComponentMedia', intl)}*/}
          {/*  onClick={safeOnRedirect}*/}
          {/*>*/}
          {/*  <FormattedHTMLMessage id="footer.links.additionalContent" />*/}
          {/*</StyledLinkLarge>*/}
          {/*<FooterDivider />*/}
          <StyledLinkLarge
            to={getRoute('ComponentLetsFintech', intl)}
            onClick={safeOnRedirect}
          >
            <FormattedHTMLMessage id="footer.links.letsFintech" />
          </StyledLinkLarge>
          <FooterDivider />
          <StyledLinkLarge
            to={getRoute('ComponentStartups', intl)}
            onClick={safeOnRedirect}
          >
            <FormattedHTMLMessage id="footer.links.startups" />
          </StyledLinkLarge>
          <FooterDividerOnlyLarge />
        </LargeLinks>
        <SmallLinks>
          <FooterDivider />
          <nav>
            <List>
              <NavigationItem>
                <LinkBold
                  to={getRoute('HomeResultsSection', intl)}
                  onClick={safeOnRedirect}
                >
                  <FormattedHTMLMessage id="footer.links.results" />
                </LinkBold>
              </NavigationItem>
              <NavigationItem>
                <LinkBold
                  to={getRoute('HomeExpertsSection', intl)}
                  onClick={safeOnRedirect}
                >
                  <FormattedHTMLMessage id="footer.links.experts" />
                </LinkBold>
              </NavigationItem>
              <NavigationItem>
                <LinkBold
                  to={getRoute('HomeInterviewsSection', intl)}
                  onClick={safeOnRedirect}
                >
                  <FormattedHTMLMessage id="footer.links.startupsDialog" />
                </LinkBold>
              </NavigationItem>
              <NavigationItem>
                <LinkBold
                  to={getRoute('HomeWishesSection', intl)}
                  onClick={safeOnRedirect}
                >
                  <FormattedHTMLMessage id="footer.links.startupWishes" />
                </LinkBold>
              </NavigationItem>
              <NavigationItem>
                <LinkBold
                  to={getRoute('HomeFounderSection', intl)}
                  onClick={safeOnRedirect}
                >
                  <FormattedHTMLMessage id="footer.links.founderTips" />
                </LinkBold>
              </NavigationItem>
              <NavigationItem>
                {!onRedirect && <ReportAuthorsButton />}
              </NavigationItem>
            </List>
          </nav>
        </SmallLinks>
        <DownloadReportContainer
          to={getRoute('ComponentDownloadReport', intl)}
          onClick={safeOnRedirect}
        >
          <div>
            <FooterDivider />
            <DownloadReportHeader>
              <FormattedHTMLMessage id="footer.links.downloadReport" />
            </DownloadReportHeader>
          </div>

          <StyledDownloadReport />
        </DownloadReportContainer>
        <StyledSmallFooter />
      </Grid>
    </StyledFooter>
  );
};

export default Footer;

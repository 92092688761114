import styled from 'styled-components';
import LinkBold from '../atoms/LinkBold';
import breakpoints from '../../utils/breakpoints';
import React from 'react';
import { useIntl } from 'react-intl';
import { getRoute } from '../../utils/router';
import colors from '../../utils/colors';
import { darken, lighten } from 'polished';

const Button = styled(LinkBold)`
  color: ${colors.red};
  text-transform: capitalize;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1.625rem;
  margin: 0 5px;
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${lighten(0.15, colors.red)};
  }

  &:active {
    color: ${darken(0.15, colors.red)};
  }
`;

const ChangeLanguageButton = props => {
  const intl = useIntl();

  const targetLocale = intl.locale === 'pl' ? 'en' : 'pl';

  return (
    <Button {...props} to={getRoute('ComponentHome', intl, targetLocale)}>
      {targetLocale}
    </Button>
  );
};

export default ChangeLanguageButton;

import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import fonts from '../../utils/fonts';
import colors from '../../utils/colors';
import React, { useState } from 'react';
import ReportAuthorsPopupSection from '../sections/ReportAuthorsPopupSection';
import { FormattedHTMLMessage } from 'react-intl';

const LinkButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  font-family: ${fonts.barlowCondensed};
  font-size: 1.5rem;
  color: ${colors.blueLighter};
  font-weight: 600;
  line-height: 1.15;
  text-decoration: none !important;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;

const ReportAuthorsButton = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <>
      {popupOpen && (
        <ReportAuthorsPopupSection close={() => setPopupOpen(false)} />
      )}
      <LinkButton onClick={() => setPopupOpen(true)}>
        <FormattedHTMLMessage id="footer.links.reportAuthors" />
      </LinkButton>
    </>
  );
};

export default ReportAuthorsButton;

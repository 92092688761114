import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import LinkLarge from '../atoms/LinkLarge';
import List from '../atoms/List';
import ListItem from '../atoms/ListItem';
import colors from '../../utils/colors';
import { getRoute } from '../../utils/router';
import { FormattedMessage, useIntl } from 'react-intl';

const NavigationItem = styled(ListItem)`
  margin: 1.75rem 0;
  display: flex;
  align-items: center;

  @media ${breakpoints.lg} and (max-height: 600px) {
    margin: 1.25rem 0;
  }
`;

const Dot = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${colors.blueLighter};
  border-radius: 50%;
  margin: 0 1rem 0.25rem 0;
  order: 1;
`;

const StyledLinkLarge = styled(LinkLarge)`
  font-weight: 700;
  font-size: 1.625rem;
  order: 2;

  @media ${breakpoints.md} {
    font-size: 2.25rem;
  }

  @media ${breakpoints.lg} {
    font-size: 2rem;
  }

  @media ${breakpoints.xl} and (min-height: 600px) {
    font-size: 2.325rem;
  }

  &.active + ${Dot} {
    background-color: ${colors.red};
  }
`;

const MenuNavigation = ({ close, ...props }) => {
  const intl = useIntl();

  return (
    <nav {...props}>
      <List>
        <NavigationItem>
          <StyledLinkLarge
            activeClassName="active"
            to={getRoute('ComponentHome', intl)}
            onClick={() => setTimeout(() => close(), 0)}
          >
            <FormattedMessage id="header.report" />
          </StyledLinkLarge>
          <Dot />
        </NavigationItem>
        <NavigationItem>
          <StyledLinkLarge
            activeClassName="active"
            to={getRoute('ComponentStartups', intl)}
            onClick={() => setTimeout(() => close(), 0)}
          >
            <FormattedMessage id="header.startups" />
          </StyledLinkLarge>
          <Dot />
        </NavigationItem>
        {/*<NavigationItem>*/}
        {/*  <StyledLinkLarge*/}
        {/*    activeClassName="active"*/}
        {/*    to={getRoute('ComponentMedia', intl)}*/}
        {/*    onClick={() => setTimeout(() => close(), 0)}*/}
        {/*  >*/}
        {/*    <FormattedMessage id="header.extraContent" />*/}
        {/*  </StyledLinkLarge>*/}
        {/*  <Dot />*/}
        {/*</NavigationItem>*/}
        <NavigationItem>
          <StyledLinkLarge
            activeClassName="active"
            to={getRoute('ComponentLetsFintech', intl)}
            onClick={() => setTimeout(() => close(), 0)}
          >
            <FormattedMessage id="header.fintech" />
          </StyledLinkLarge>
          <Dot />
        </NavigationItem>
        <NavigationItem>
          <StyledLinkLarge
            activeClassName="active"
            to={getRoute('ComponentDownloadReport', intl)}
            onClick={() => setTimeout(() => close(), 0)}
          >
            <FormattedMessage id="header.downloadReport" />
          </StyledLinkLarge>
          <Dot />
        </NavigationItem>
      </List>
    </nav>
  );
};

export default MenuNavigation;

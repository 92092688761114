/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { IntlProvider } from 'react-intl';

import Header from './sections/header';
import translations from '../i18n/translations';
import languages from '../i18n/languages';
import Footer from './sections/Footer';
import GlobalStyles from '../utils/globalStyles';
import Head from './Head';
import '../utils/fonts.css';

const defaultLocale = languages.find(_ => _.default).locale;

const withLayout = PageComponent => props => {
  const { locale, pageName, headerType } = props.pageContext;
  const pageLocale = locale || defaultLocale;
  const pageTitle = translations[pageLocale][`${pageName}.title`] || '404';
  const title =
    pageName !== 'home'
      ? `${pageTitle} - ${translations[pageLocale]['title']}`
      : translations[pageLocale]['title'];

  return (
    <IntlProvider locale={pageLocale} messages={translations[pageLocale]}>
      <Head
        title={title}
        description={translations[pageLocale]['description']}
        keywords={translations[pageLocale]['keywords']}
        lang={pageLocale}
      />
      <GlobalStyles />
      <Header type={headerType} />
      <main>
        <PageComponent {...props} />
      </main>
      <Footer />
    </IntlProvider>
  );
};

export default withLayout;

import React from 'react';
import logoChmuraKrajowa from '../../images/logo-chmura-krajowa.svg';

const LogoChmuraKrajowa = props => {
  return (
    <img src={logoChmuraKrajowa} alt="Chmura Krajowa logo" {...props} />
  );
};

export default LogoChmuraKrajowa;

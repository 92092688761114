export default {
  heading: `Startupy`,
  title: `Startupy`,
  www: 'web',
  items: [
    {
      "startup": "AAZZUR",
      "description": "Fintech wspierany przez Techstars, dostarczający bankom, ubezpieczycielom i firmom finansowym rozwiązanie white-label, zapewniające personalizację obsługi klienta w kanałach zdalnych. \n",
      "www": "www.aazzur.com",
      "tags": [
        "E-commerce & programy lojalnościowe",
        "Otwarta bankowość",
        "Analiza danych"
      ]
    },
    {
      "startup": "abyss glass Group",
      "description": "Producent luster interaktywnych, wykorzystywanych jako nośnik interaktywny. Firma projektuje autorskie aplikacje na lustra pozwalające sie wyróżnić klientom ze swoim przekazem. Produkty Abyss Glass są przeznaczone dla różnych branż i sektorów, w tym handlu detalicznego i kreatywnego marketingu.Służą do wyświetlania reklam, filmów oraz pozwalają wchodzić w interakcję z odbiorcą.",
      "www": "www.abyssglass.pl",
      "tags": [
        "Marketing i reklama"
      ]
    },
    {
      "startup": "AI BUSTERS",
      "description": "Silnik do zaawansowanej analizy wiadomości dla działów obsługi klienta (np. e-maile, formularze na WWW) w branży finansowej i ubezpieczeniowej. Używa zaawansowanej analizy języka naturalnego (NLP), usprawniając pracę obsługi klienta w ramach istniejących systemów w organizacji.",
      "www": "https://aibusters.com",
      "tags": [
        "Ubezpieczenia",
        "Obsługa klienta",
        "Analiza danych"
      ]
    },
    {
      "startup": "AI Investments",
      "description": "Platforma optymalizacji portfela inwestycyjnego przy wykorzystaniu AI. Wykorzystuje złożone strategie inwestycyjne i zaawansowane metody predykcyjne. W połączeniu z algorytmami optymalizacyjnymi pozwalają one osiągać bardzo dobre wyniki inwestycyjne.",
      "www": "www.aiinvestments.pl",
      "tags": [
        "Oszczędności & inwestycje"
      ]
    },
    {
      "startup": "Algolytics Technologies",
      "description": "Tworzy własne rozwiązania dla fintechów (m.in. ocena ryzyka, antyfraud, blockchain) oraz narzędzia do marketingu opartego o machine learning ( np. marketing automation, programmatic, kwalifikacja leadów). ",
      "www": "www.algolytics.pl",
      "tags": [
        "E-commerce & programy lojalnościowe",
        "Optymalizacja procesów wewnętrznych",
        "Analiza danych"
      ]
    },
    {
      "startup": "APPLICA.AI",
      "description": "Rewolucjonizuje sposób, w jaki firmy wykorzystują AI do automatyzacji procesów wymagających rozumienia tekstu. Dzięki autorskiej technologii, rozwiązanie Applica jest trenowane do nowych zastosowań w oparciu na kilku przykładach. Wykorzystują je banki, firmy mediowe i kancelarie.",
      "www": "applica.ai",
      "tags": [
        "Optymalizacja procesów wewnętrznych"
      ]
    },
    {
      "startup": "Autenti",
      "description": "Platforma do autoryzacji dokumentów i zawierania umów przez internet. Dzięki Autenti elektroniczne podpisywanie dokumentów możliwe jest na komputerze, tablecie lub smartfonie w dowolnym miejscu i czasie. Działa w zgodzie z przepisami zarówno europejskimi, jak i krajowymi.",
      "www": "www.autenti.com",
      "tags": [
        "Oszczędności & inwestycje",
        "Obsługa klienta",
        "Optymalizacja procesów wewnętrznych"
      ]
    },
    {
      "startup": "Bancovo",
      "description": "Pierwsza w Polsce w pełni cyfrowa platforma kredytowa. Dzięki niej klienci mogą uzyskać dostęp do rzeczywistych i aktualnych ofert wielu banków oraz firm pożyczkowych. Proces wyboru oferty oraz zawierania umowy o pożyczkę jest tu prosty i szybki – trwa nawet 15 minut.",
      "www": "www.bancovo.pl",
      "tags": [
        "Pożyczki i finansowanie"
      ]
    },
    {
      "startup": "banqUP",
      "description": "Pomaga instytucjom finansowym i fintechom, sprawnie integrować swoje rozwiązania z szeroką gamą europejskich API bankowych. Dzięki banqUP można np. weryfikować klienta czy agregować źródła danych finansowych. Firma pracuje nad rozwiązaniem TPP-as-a-service.",
      "www": "www.banqup.com",
      "tags": [
        "Bankowość internetowa",
        "Otwarta bankowość",
        "Analiza danych"
      ]
    },
    {
      "startup": "Beesfund",
      "description": "Pierwsza i największa polska platforma finansowania udziałowego (equity crowdfunding), założona w 2012 r. W ostatnich latach rośnie w tempie 100 proc. rocznie. Na platformie przeprowadzono 61 emisji na łączną kwotę 33,9 mln PLN, zebraną z udziałem ponad 29 tys. zarejestrowanych inwestorów.",
      "www": "https://beesfund.com",
      "tags": [
        "Pożyczki i finansowanie",
        "Marketing i reklama",
        "Usługi prawne"
      ]
    },
    {
      "startup": "Billon",
      "description": "Twórca wydajnej i skalowalnej technologii rozproszonego rejestru dla biznesu. Billon, z pomocą blockchaina, łączy w ramach jednego systemu transakcje walutami narodowymi, zapisywanie dokumentów i zarządzanie tożsamością. A wszystko to zgodnie z obowiązującymi regulacjami.",
      "www": "www.billongroup.com",
      "tags": [
        "Płatności",
        "Rozwiązania oddziałowe",
        "Optymalizacja procesów wewnętrznych",
        "Cyberbezpieczeństwo"
      ]
    },
    {
      "startup": "BillTech",
      "description": "Rozwiązanie łączące firmy cyklicznie wystawiające faktury (np. telekomy, dostawców energii) z klientami indywidualnymi, korzystającymi z aplikacji do dokonywania płatności. Dzięki temu np. bank może w swojej aplikacji udostępnić łatwe płatności do firm, zintegrowanych z API BillTech. ",
      "www": "https://billtech.pl/api/",
      "tags": [
        "Płatności",
        "Zarządzanie finansami osobistymi (digital wallet, PFM)"
      ]
    },
    {
      "startup": "BlockCore (Tokenization Club + bitSecurities)",
      "description": "Centrum rozwiązań dla biznesowej blockchainizacji oraz tokenizacji. Spółka dostarcza infrastrukturę łańcucha bloków, wspiera w pełni cyfrową komunikację firm ze swoimi partnerami i klientami. Zapewnia wszystkie elementy, potrzebne do bezpieczeństwa i ciągłości działalności.",
      "www": "www.blockcore.fund",
      "tags": [
        "Płatności",
        "Pożyczki i finansowanie",
        "Oszczędności & inwestycje",
        "Kryptowaluty & tokeny"
      ]
    },
    {
      "startup": "BSafer sp. z o.o.",
      "description": "Oferuje swoim klientom system ochrony danych osobowych, przeznaczony dla internautów. Informuje ich, gdzie i przez kogo wykorzystywane są ich dane osobowe i w jakim celu. BSafer pozwala zatem osobom prywatnym na zarządzanie i monetyzowanie ich zgód marketingowych.",
      "www": "https://bsafer.pl",
      "tags": [
        "E-commerce & programy lojalnościowe",
        "Cyberbezpieczeństwo",
        "Marketing i reklama"
      ]
    },
    {
      "startup": "Carrotspot",
      "description": "Platforma umożliwiająca budowanie społeczności firmowej, zorientowanej na osiąganie wspólnych celów. Daje możliwość wdrażania programów motywacyjnych w pełni dopasowanych do możliwości firmy oraz potrzeb jej pracowników. Pozwala budować zaangażowanie wśród pracowników.",
      "www": "www.carrotspot.pl",
      "tags": [
        "Optymalizacja procesów wewnętrznych",
        "Zasoby ludzkie"
      ]
    },
    {
      "startup": "Cashap",
      "description": "Darmowa aplikacja B2C zbudowana dla i wraz z Generacją Z (13-19-latkowie). Ma za zadanie sfinansować potrzeby generacji Z, wykorzystując do tego unikalny model płatności oraz edukację z obszaru finansów. Cashap nie wymaga przy tym posiadania konta bankowego.",
      "www": "cashap.pl",
      "tags": [
        "Płatności",
        "Zarządzanie finansami osobistymi (digital wallet, PFM)",
        "E-commerce & programy lojalnościowe"
      ]
    },
    {
      "startup": "CashDirector SA",
      "description": "CashDirector to cyfrowy CFO, oparty o platformę księgową przeznaczony dla małych i średnich przedsiębiorstw. Dostarcza też rozwiązanie white-label dla banków. Poprawia zarządzanie płynnością w firmach i daje bankom możliwość sprzedaży produktów do MŚP. \n",
      "www": "www.cashdirector.com",
      "tags": [
        "Usługi dodane",
        "Bankowość internetowa",
        "Analiza danych"
      ]
    },
    {
      "startup": "Cashy",
      "description": "Platforma oferująca kupującym rabaty za wcześniejsze uregulowanie faktur wobec ich dostawców, bez potrzeby wystawiania faktur korygujących. Cały proces komunikacji pomiędzy kontrahentami i obsługa odbywa się online. Cashy  pozwala więc kupującym wygenerować istotne oszczędności operacyjne. ",
      "www": "www.cashy.pl",
      "tags": [
        "Pożyczki i finansowanie",
        "Oszczędności & inwestycje",
        "Optymalizacja procesów wewnętrznych"
      ]
    },
    {
      "startup": "Chatbotize",
      "description": "Platforma do marketingu konwersacyjnego. Pozwala pozyskiwać potencjalnych klientów, angażować ich i sprzedawać produkty i usługi na kanałach czatowych. Chatbotize umożliwia również redukcję kosztów obsługi klienta poprzez automatyzację odpowiedzi i rozwiązania dla konsultantów. ",
      "www": "https://chatbotize.com",
      "tags": [
        "Zaangażowanie klienta",
        "Obsługa klienta",
        "Marketing i reklama"
      ]
    },
    {
      "startup": "ChatForce",
      "description": "Dedykowane rozwiązania na komunikatorach (np. Messenger, WhatsApp) dla firm. Dzięki rozwiązaniu end-to-end, możliwe jest otwarcie interaktywnego, spersonalizowanego oraz w pełni kontekstowego kanału komunikacji z klientami. ",
      "www": "https://chatforce.io",
      "tags": [
        "Zaangażowanie klienta",
        "Obsługa klienta",
        "Marketing i reklama"
      ]
    },
    {
      "startup": "Cluify",
      "description": "Technologia łącząca sferę online i offline, pozwala na lepsze zrozumienie potrzeb klientów. Oferuje m.in. narzędzia do lepszego zarządzania ekosystemem handlowym, a także dane o zachowaniach klientów offline. Dzięki temu, reklama online trafia do osób, odwiedzających konkretne miejsca.",
      "www": "www.cluify.com",
      "tags": [
        "Zaangażowanie klienta",
        "Marketing i reklama",
        "Analiza danych"
      ]
    },
    {
      "startup": "Coinfirm",
      "description": "Coinfirm jest wiodącym na rynku globalnym dostawcą rozwiązań opartych o technologię blockchain dla klientów z segmentu instytucji finansowych. Jest także pionierem w zakresie technologii stosowanych w procesie AML oraz analizie ryzyka z wykorzystaniem walut cyfrowych.",
      "www": "https://www.coinfirm.com/",
      "tags": [
        "Usługi dodane",
        "Kryptowaluty & tokeny",
        "Analiza danych"
      ]
    },
    {
      "startup": "Coinquista S.A.",
      "description": "Polska giełda kryptowalut, wpisana do rejestru małych instytucji płatniczych prowadzonego przez Komisję Nadzoru Finansowego. Coinquista łączy tradycyjny świat finansów z nowoczesną technologią, udostępniając użytkownikom nieograniczony dostęp do aktywów cyfrowych.",
      "www": "https://coinquista.com",
      "tags": [
        "Płatności",
        "Oszczędności & inwestycje",
        "Kryptowaluty & tokeny"
      ]
    },
    {
      "startup": "Creamfinance",
      "description": "Międzynarodowa grupa finansowa, specjalizująca się w udzielaniu pożyczek przez Internet. Jej misja – „Make money available” – to umożliwienie klientom otrzymania środków łatwo, szybko i z minimum formalności. W 2018 r. grupa udzieliła pożyczek o łącznej wartości 174 mln EUR.",
      "www": "www.creamfinance.com",
      "tags": [
        "Pożyczki i finansowanie"
      ]
    },
    {
      "startup": "Devskiller",
      "description": "System online do weryfikacji umiejętności programistów w procesie rekrutacji oraz wspierajania procesów HR. zasila go autorskie narzędzie metodologiczne, dające m.in. możliwość budowania ścieżki kariery w organizacjach. Pomaga przyciągać talenty programistyczne z rynku. ",
      "www": "www.devskiller.com",
      "tags": [
        "Zasoby ludzkie"
      ]
    },
    {
      "startup": "Digital Fingerprints",
      "description": "System bezpieczeństwa oparty o biometrię behawioralną, chroniącą użytkowników w czasie rzeczywistym. Rozwiązanie przeznaczone m.in. do uwierzytelnienia w aplikacjach bankowych, wykorzystujace indywidualne modele zachowania w interakcji z komputerem (np. poruszanie myszką czy pisanie na klawiaturze). Nie wymaga żadnego dodatkowego sprzętu (np. tokenu) i jest niemożliwa do wykradzenia/przejęcia. ",
      "www": "https://fingerprints.digital/",
      "tags": [
        "Cyberbezpieczeństwo"
      ]
    },
    {
      "startup": "Digital Teammates SA",
      "description": "Dostawca RPA (Robotic Process Automation) w modelu wynajmu robotów. Dzięki własnej technologii i wyspecjalizowanemu zespołowi jest w stanie ocenić potencjał robotyczny, wyprodukować roboty i uruchomić je w 4-6 tygodni. Wnosi nową jakość w procesach back-office w firmach.  ",
      "www": "www.dtmates.com",
      "tags": [
        "Usługi dodane",
        "Rozwiązania oddziałowe",
        "Optymalizacja procesów wewnętrznych"
      ]
    },
    {
      "startup": "Dronn.com",
      "description": "Pomaga instytucjom finansowym w automatyzacji procesów obsługi klienta poprzez ich outsourcing do robotycznego call center. Dzięki pełnej kontroli nad technologią, infrastrukturą i własnemu zespołowi do wdrożeń i obsługi wnosi najwyższą jakość obsługi przy optymalnych kosztach. \n",
      "www": "www.dronn.com",
      "tags": [
        "Obsługa klienta"
      ]
    },
    {
      "startup": "Edward",
      "description": "Mobilny, inteligentny asystent dla działów sprzedaży, który pozwala zautomatyzować ręczne wprowadzanie danych, notowanie i sprawdzanie informacji o relacjach z klientami. Edward może zasilać danymi istniejące już systemy CRM i pobierać z nich informacje przydatne w pracy handlowca.",
      "www": "https://edward.ai/",
      "tags": [
        "Obsługa klienta",
        "Optymalizacja procesów wewnętrznych",
        "Analiza danych"
      ]
    },
    {
      "startup": "Elastic Cloud Solutions",
      "description": "Twórca workai – platformy klasy digital employee experience, która usprawnia komunikację wewnętrzną, zarządzanie wiedzą i zaangażowanie pracowników przy pomocy technologii mobilnych, IoT i AI. Workai oferuje rozwiązania gotowe do wdrożenia w organizacji, możliwe do dostosowania do potrzeb klienta.",
      "www": "www.workai.com",
      "tags": [
        "Rozwiązania oddziałowe",
        "Obsługa klienta",
        "Optymalizacja procesów wewnętrznych"
      ]
    },
    {
      "startup": "Emplocity sp. z o.o.",
      "description": "Twórca Emplobota - chatbota wprowadzjającego AI do procesów rekrutacji. Na podstawie autorskich algorytmów AI/NLP  automatyzuje procesy pozyskiwania kandydatów, ich preselekcji oraz komunikacji w trakcie rekrutacji.",
      "www": "www.emplocity.pl",
      "tags": [
        "Rozwiązania oddziałowe",
        "Optymalizacja procesów wewnętrznych",
        "Zasoby ludzkie"
      ]
    },
    {
      "startup": "ExChord",
      "description": "Aplikacja dla importerów i eksporterów. Jej użytkownicy, niezależnie od lokalizacji, wykorzystywanych dostawców bankowych mają możliwość zarabiania na ryzyku walutowym. ExChord agreguje więc kluczowe informacje i automatyzuje procesy w zarządzaniu ryzykiem walutowym. \n",
      "www": "www.exchord.com",
      "tags": [
        "Optymalizacja procesów wewnętrznych"
      ]
    },
    {
      "startup": "Fandla Faktoring sp. z o.o.",
      "description": "Narzędzie dla przedsiębiorców do finansowania nieprzeterminowanych faktur w PLN i EUR. Każda wystawiona faktura i każda związana z nią transakcja to osobna umowa, przetwarzana szybko i elektronicznie. Bez zbędnych formalności - wystarczy faktura. ",
      "www": "fandla.com",
      "tags": [
        "Pożyczki i finansowanie"
      ]
    },
    {
      "startup": "feeCOMPASS",
      "description": "Inteligentna platforma dostarczająca porównań i argumentów sprzedażowych online i offline dla firm. Wirtualny asystent na bieżąco ustala potrzeby każdego konsumenta, tak aby podpowiedzieć mu najlepsze rozwiązanie spośród oferowanych przez daną firmę produktów.",
      "www": "www.feecompass.com",
      "tags": [
        "Rozwiązania oddziałowe",
        "Obsługa klienta",
        "Analiza danych"
      ]
    },
    {
      "startup": "Finanteq SA",
      "description": "Dostawca oprogramowania i firma konsultingowa skoncentrowana wokół mobilnych finansów. Dostarcza kompletne rozwiązania lub gotowe do wdrożenia komponenty na kanały mobilne m.in. w bankach. Rozwiązania Finanteq pracują w kilkunastu dużych instytucjach finansowych na świecie. ",
      "www": "www.finanteq.com",
      "tags": [
        "E-commerce & programy lojalnościowe",
        "Usługi dodane",
        "Bankowość internetowa"
      ]
    },
    {
      "startup": "Finiata",
      "description": "Narzędzie rozwiązujące kłopoty płynnościowe najmniejszych firm, dla których bankowy kapitał jest niedostępny ze wzgledu na np. zbyt krótką historię działania. Finiata udziela faktoringu i pożyczek przez Internet, posiłkując się autorskimi algorytmami AI i modelami scoringowymi. \n\n",
      "www": "www.finiata.pl",
      "tags": [
        "Pożyczki i finansowanie",
        "Analiza danych"
      ]
    },
    {
      "startup": "Fitqbe",
      "description": "Łączy funkcje grywalizacyjnej platformy wellbeingowej, firmowego portalu społecznościowego i systemu kafeteryjnego. Buduje ponadprzeciętne zaangażowanie oraz silną kulturę organizacji, opartą na docenianiu i wynagradzaniu pracowników w jednej, intuicyjnej platformie.",
      "www": "https://fitqbe.com",
      "tags": [
        "Rozwiązania oddziałowe",
        "Optymalizacja procesów wewnętrznych",
        "Zasoby ludzkie"
      ]
    },
    {
      "startup": "GoRODO.pl",
      "description": "Aplikacja pozwalająca wdrożyć, a następnie utrzymać zgodność z RODO w każdej firmie. Umożliwia przeprowadzenie procedury wdrożenia bez znajomości zagadnień prawnych. W efekcie, przedsiębiorca otrzymuje pełen zestaw dokumentów niezbędnych do utrzymania zgodności z RODO. ",
      "www": "www.gorodo.pl",
      "tags": [
        "Usługi prawne"
      ]
    },
    {
      "startup": "Gratifu",
      "description": "Gratifu to platforma lojalnościowa, łącząca biznes z klientami. Pozwala na głęboką personalizację i szybkie, płynne wdrożenie oraz natychmiastowe nagradzanie klientów. To elastyczne narzędzie do budowania systemu rabatów i nagród, zwiększających lojalność klientów.  \n \n\n",
      "www": "www.gratifu.com",
      "tags": [
        "E-commerce & programy lojalnościowe"
      ]
    },
    {
      "startup": "HomeProfit",
      "description": "Agreguje setki sklepów stacjonarnych i internetowych z kategorii Home&Living. Oferuje stałe zniżki sięgające nawet kilkudziesięciu procent w ponad 400 sklepach z branży wykończenia i wyposażenia wnętrz w całej Polsce. Rabaty dostępne są dla posiadaczy dedykowanej karty lub poprzez aplikację mobilną.",
      "www": "homeprofit.pl",
      "tags": [
        "Oszczędności & inwestycje",
        "E-commerce & programy lojalnościowe",
        "Usługi dodane"
      ]
    },
    {
      "startup": "Hotailors",
      "description": "Narzędzie integrujące wszystkie procesy związane z rezerwacjami i zarządzaniem podróżami. Dzięki dostępowi do najlepszych ofert klienci Hotailors redukują czas i koszty potrzebne na zaplanowanie podróży służbowych. Do tego podróżujący mogą elastycznie planować wyjazdy, a zarządzający uzyskują centralizację procesu i pewność, że rezerwacje są zgodne z regulaminami. ",
      "www": "https://hotailors.com",
      "tags": [
        "Pożyczki i finansowanie",
        "Usługi dodane",
        "Optymalizacja procesów wewnętrznych"
      ]
    },
    {
      "startup": "IC Solutions sp. z o.o.",
      "description": "Twórca Platformy IC Pen, która wykorzystując długopis cyfrowy, umożliwia jednoczesne powstawanie wersji papierowej dokumentów i ich elektronicznych odpowiedników. Pozwala użytkownikowi wybrać urządzenie (długopis cyfrowy, ekran czy urządzenie Wacom) do stworzenia e-dokumentu. Dzięki podpisowi biometrycznemu o cechach grafologicznych składanemu przez IC Pen, dokument elektroniczny ma wartość prawną.",
      "www": "www.icpen.pl, www.icsolutions.pl",
      "tags": [
        "Rozwiązania oddziałowe",
        "Obsługa klienta",
        "Optymalizacja procesów wewnętrznych"
      ]
    },
    {
      "startup": "Identt",
      "description": "Rozwiązanie wspierające proces weryfikacji dokumentów tożsamości oraz posługujących się nimi osób. Automatycznie weryfikuje poprawność, integralność oraz oryginalność dokumentu. Jednocześnie, z pomocą  biometrycznej identyfikacji twarzy, system weryfikuje okaziciela dokumentu. ",
      "www": "www.identt.pl",
      "tags": [
        "Usługi dodane",
        "Optymalizacja procesów wewnętrznych",
        "Cyberbezpieczeństwo"
      ]
    },
    {
      "startup": "Ignibit SA",
      "description": "Software house specjalizujący się w wirtualnej (VR), rozszerzonej (AR) i mieszanej rzeczywistości (MR). Dostarcza rozwiązania  dla partnerów na całym świecie. Jego technologie wykorzystują już takie marki, jak: Cadillac, Volkswagen czy Compensa. ",
      "www": "ignibit.com",
      "tags": [
        "Marketing i reklama"
      ]
    },
    {
      "startup": "Investsuite",
      "description": "Międzynarodowy dostawca rozwiązań w modelu SaaS z zakresu zarządzania majątkiem, napędzanych autorskim algorytmem. InvestSuite to technologia inteligentnego, cyfrowego doradcy finansowego, gotowa do wdrożenia przez brokerów online, banki czy fundusze emerytalne na całym świecie.\n",
      "www": "www.investsuite.com",
      "tags": [
        "Oszczędności & inwestycje"
      ]
    },
    {
      "startup": "isourcetec",
      "description": "Dostawca rozwiązań z dziedziny Robotics Process Automation (RPA). Roboty iSourceTec pozwalają automatyzować powtarzalne, czasochłonne prace wykonywane przez ludzi, bez potrzeby ingerencji w architekturę IT. Znajdują zastosowane m.in. w projektach działów HR, finansowych czy obsługi klienta. ",
      "www": "https://isourcetec.com/",
      "tags": [
        "Optymalizacja procesów wewnętrznych",
        "Zasoby ludzkie"
      ]
    },
    {
      "startup": "Justtag",
      "description": "Platforma oferująca i innowacyjne rozwiązania reklamowe łączące świat online z offline. Justtag rozwija technologię KoalaMetrics analizującą zachowania użytkowników offline, a także JustWIFI – największą w Europie Środkowo-Wschodniej sieć reklamy opartej o wi-fi. ",
      "www": "www.justtag.com",
      "tags": [
        "Marketing i reklama"
      ]
    },
    {
      "startup": "Kontomatik",
      "description": "Świadczy usługi dla banków i innych instytucji finansowych. Rozwiązania Kontomatik umożliwiają dostęp do danych bankowych klientów, które mogą zostać wykorzystane np. do cyfrowej identyfikacji klienta. Do tego, dzięki machine learning, ułatwia on budowanie profili zachowań finansowych klientów",
      "www": "www.kontomatik.com",
      "tags": [
        "Otwarta bankowość",
        "Optymalizacja procesów wewnętrznych",
        "Analiza danych"
      ]
    },
    {
      "startup": "Kredytmarket",
      "description": "Internetowa platforma finansowa, udzielająca online krótkoterminowych kredytów na działalność gospodarczą. Przez 3 lata zasilonych kapitałem zostało ponad 5000 mikroprzedsiębiorców na łączną kwotę ponad 25 milionów złotych. Dzięki decyzji kredytowej w nawet 15 minut, pomaga przedsiębiorcom zdobć finansowanie szybko i na przejrzystych zasadach, przy minimum formalności.",
      "www": "www.kredytmarket.com",
      "tags": [
        "Pożyczki i finansowanie"
      ]
    },
    {
      "startup": "LeaseLink Sp. z o.o.",
      "description": "Fintechowa firma leasingowa, specjalizująca się w finansowaniu zakupów przedsiębiorców dokonywanych w e-sklepach i punktach stacjonarnych. Oferuje aplikację, dzięki której - jako jedyna na świecie - realizuje usługę płatności leasingiem niczym płatność przelewem (pay‑by‑link).",
      "www": "www.leaselink.pl",
      "tags": [
        "Płatności",
        "Pożyczki i finansowanie",
        "E-commerce & programy lojalnościowe"
      ]
    },
    {
      "startup": "LogicAI",
      "description": "Realizuje rozwiązania end-to-end z zakresu AI i Data Science dla takich firm oraz organizacji jak np.: Ministerstwo Zdrowia, Louis Vuitton czy Rolls-Royce. LogicAI zarządza również Kaggle, czyli największą społecznością data scientistów na świecie, wspieraną przez Google’a. ",
      "www": "logicai.io",
      "tags": [
        "Pożyczki i finansowanie",
        "E-commerce & programy lojalnościowe",
        "Zaangażowanie klienta"
      ]
    },
    {
      "startup": "LUNA",
      "description": "Rozwija środowisko programistyczne do wizualizacji, transformacji danych. Luna pozwala na wykorzystanie istniejących zasobów machine learningowych do zobrazowania aktualnych trendów rynkowych. Pozwala to na głębsze zrozumienie procesów przez klientów i lepsze decyzje biznesowe.  ",
      "www": "www.luna-lang.org",
      "tags": [
        "Analiza danych"
      ]
    },
    {
      "startup": "Migam",
      "description": "Narzędzie umożliwiające połączączenia wideo z tłumaczem języka migowego z poziomu przeglądarki internetowej oraz dowolnego urządzenia z kamerą. Migam pozwala usprawnić komunikację pomiędzy głuchymi klientami a słyszącymi pracownikami obsługi klientów.",
      "www": "https://migam.org/",
      "tags": [
        "Usługi dodane",
        "Obsługa klienta"
      ]
    },
    {
      "startup": "MintCloud",
      "description": "Napędzana sztuczną inteligencją, gotowa do użytku platforma marketingowa. Pozwala prowadzić skuteczne kampanie przy ograniczonym budżecie - zbiera i gromadzi dane klientów z wielu źródeł. Na ich podstawie pozwala prowadzić automatyczne, wielopoziomowe kampanie marketingowe. ",
      "www": "biz.mintcloud.me",
      "tags": [
        "E-commerce & programy lojalnościowe",
        "Zaangażowanie klienta",
        "Marketing i reklama"
      ]
    },
    {
      "startup": "My Płacimy sp. z o.o.",
      "description": "Oferuje usługę odroczonej płatności dla mikrofirm, dokonujących zakupów w sieci. My Płacimy wypełnia rynkową lukę i bierze na siebie ryzyko, wyręczając e-handel. E-commerce w tym wypadku niczym nie ryzykuje i nie ponosi kosztów, a mikrobiznes otrzymuje szybki kredyt kupiecki.",
      "www": "www.myplacimy.pl",
      "tags": [
        "Płatności",
        "Pożyczki i finansowanie",
        "E-commerce & programy lojalnościowe"
      ]
    },
    {
      "startup": "NapoleonCat",
      "description": "Narzędzie wspierające zarządzanie komunikacją marki oraz obsługę klienta w mediach społecznościowych. Usprawnia pracę zespołów marketingowych oraz obsługi klienta poprzez zintegrowanie obsługi wielu platform społecznościowych w jednym miejscu i automatyzację wykonywanych zadań. ",
      "www": "https://napoleoncat.com",
      "tags": [
        "Zaangażowanie klienta",
        "Obsługa klienta",
        "Marketing i reklama"
      ]
    },
    {
      "startup": "Neontri",
      "description": "Specjaliści od zaawansowanych technologicznie rozwiązań B2B np. dla banków. Firma dostarcza autorskie rozwiązania z zakresu: bankowości mobilnej, rozwiązań płatnościowych i big data. Pracuje dla największych banków i organizacji płatniczych w Polsce i na świecie.",
      "www": "https://neontri.com",
      "tags": [
        "Płatności",
        "Otwarta bankowość"
      ]
    },
    {
      "startup": "Nethone",
      "description": "Dostawca rozwiązań z obszaru KYU (Know Your Users), pomagających firmom przekształcać cyberzagrożenia w świadome i opłacalne decyzje biznesowe. Działa w obszarze cyberbezpieczeństwa i umożliwia lepsze poznanie użytkowników w kanałach zdalnych, dzięki czemu zapobiega fraudom płatniczym.",
      "www": "www.nethone.com",
      "tags": [
        "Płatności",
        "Cyberbezpieczeństwo",
        "Analiza danych"
      ]
    },
    {
      "startup": "Nextrope",
      "description": "Software house skupiający się na wdrażaniu innowacji w sektorze fintech, ze specjalizacją w rozwoju projektów blockchainowych. Nextrope współpracuje zarówno ze startupami jak i partnerami instytucjonalnymi, wnosząc doświadczenie branżowe w pracy na polu R&D. ",
      "www": "https://nextrope.com/en/",
      "tags": [
        "Kryptowaluty & tokeny"
      ]
    },
    {
      "startup": "NuDelta",
      "description": "System rozpoznający treść z papierowych faktur czy paragonów, oparty o autorskie algorytmy, wykorzystujące uczenie maszynowe oraz analizę semantyczną. Nu Delta jest programem łatwo skalowalnym, gotowym do wdrożeń w różnych obszarach biznesu.  ",
      "www": "https://www.nudelta.pl/",
      "tags": [
        "Bankowość internetowa",
        "Optymalizacja procesów wewnętrznych",
        "Analiza danych"
      ]
    },
    {
      "startup": "PayPo",
      "description": "Spółka działająca na styku płatności i e-handlu. Jej partnerami są największe sklepy e-commerce, którym oferuje rozwiązanie \"buy now - pay later\". Kupujący z PayPo mają 30 dni na zapłatę lub skorzystanie z rat. \n",
      "www": "paypo.pl",
      "tags": [
        "Płatności",
        "Pożyczki i finansowanie",
        "E-commerce & programy lojalnościowe"
      ]
    },
    {
      "startup": "PayUkraine",
      "description": "Europejski serwis przelewów pieniężnych - gotówką w placówce lub z karty na kartę. Pozwala na płynny, bezpieczny i natychmiastowy przepływ gotówki między kontem użytkownika a odbiorcą na Ukrainie. Oferuje także intuicyjną aplikację oraz korzystne kursy wymiany.\n",
      "www": "https://payukraine.com/",
      "tags": [
        "Płatności",
        "Marketing i reklama",
        "Analiza danych"
      ]
    },
    {
      "startup": "Pergamin",
      "description": "Platforma internetowa automatyzująca proces podpisywania umów w jednym, łatwo dostępnym i bezpiecznym miejscu. Umożliwia zdalne podpisywanie umów z kontrahentami poprzez wgranie własnej umowy albo korzystanie z darmowej bazy wzorów. W 5 minut pozwala stworzyć dowolną, gotową do podpisu umowę.",
      "www": "https://pergam.in/",
      "tags": [
        "Optymalizacja procesów wewnętrznych",
        "Usługi prawne",
        "Analiza danych"
      ]
    },
    {
      "startup": "placeme",
      "description": "Umożliwia analizę ścieżki zakupowej konsumentów w przenikających się kanałach sprzedaży online i offline. Pozwala zrozumieć w jaki sposób korzystają oni z usług i tworzyć ich profile. Geoanalityka Placeme daje zaś możliwość wyboru np. najlepszej lokalizacji pod nowy sklep. ",
      "www": "https://placeme.pl/",
      "tags": [
        "Analiza danych"
      ]
    },
    {
      "startup": "Protect.me",
      "description": "Platforma informatyczna do dystrybucji i obsługi ubezpieczeń w sieci, mająca gotowy model biznesowy, prawny oraz podatkowy. Oferuje zestaw produktów ubezpieczeniowych od renomowanych ubezpieczycieli, skierowanych zarówno do partnerów biznesowych, jak i na rynek konsumencki. ",
      "www": "www.protectme.pl",
      "tags": [
        "Ubezpieczenia"
      ]
    },
    {
      "startup": "ProtectAuth",
      "description": "Rozwija technologię komunikacji pomiędzy systemem banku a smartfonem klienta. W ten sposób pomaga instytucjom finansowym zwiększyć bezpieczeństwo transakcji internetowych, zapewniając niezmienność i niezaprzeczalność transakcji, dzięki zastosowaniu technologii blockchain.",
      "www": "www.protectauth.com",
      "tags": [
        "Bankowość internetowa",
        "Cyberbezpieczeństwo"
      ]
    },
    {
      "startup": "Proxi.cloud",
      "description": "Dostarcza markom narzędzia pozwalające łączyć tradycyjne i cyfrowe punkty styku z konsumentem. Proxi.cloud wykorzystując mobile jako furtkę do świata offline umożliwia prowadzenie spersonalizowanej komunikacji oraz dostarcza decydentom cenne informacje dotyczące przepływu konsumentów i lojalności wobec marki.",
      "www": "https://proxi.cloud",
      "tags": [
        "Zaangażowanie klienta",
        "Marketing i reklama",
        "Analiza danych"
      ]
    },
    {
      "startup": "PublBox",
      "description": "Platforma SaaS do automatyzacji marketingu w mediach społecznościowych. Pozwala firmom na budowanie strategii marketingowych w social mediach w oparciu o gotowe rozwiązania graficzne, plany kampanii, narzędzia do publikacji z kanałów RSS i zarządzania skrzynką wiadomości. ",
      "www": "https://publbox.com/pl/",
      "tags": [
        "Usługi dodane",
        "Obsługa klienta",
        "Marketing i reklama"
      ]
    },
    {
      "startup": "Quantum cx",
      "description": "Rozwiązanie podnoszące jakość obsługi poprzez zwiększenie liczby uśmiechów pracowników i klientów m.in. w branży finansowej. Quantum CX buduje nawyki i motywuje do życzliwości, ucząc pozytywnej postawy. Wykrywane uśmiechy przeliczane są na śroski pienieżne i wpłacane na cele charytatywne.",
      "www": "https://quantum.cx/",
      "tags": [
        "Rozwiązania oddziałowe",
        "Zaangażowanie klienta",
        "Obsługa klienta"
      ]
    },
    {
      "startup": "Rachuneo - operatorem jest Polskie Aplikacje Transakcyjne",
      "description": "Platforma online dająca możliwość porównywania ofert dostawy energii elektrycznej i gazu dla domu lub firmy oraz podpisania umowy via internet. Pozwala też na analizę obecnego rachunku i sprawdzenie korzystniejszych ofert dostępnych na rynku.",
      "www": "www.rachuneo.pl",
      "tags": [
        "Usługi dodane"
      ]
    },
    {
      "startup": "Ramp Network",
      "description": "Firma rozwija platformę umożliwiającą zdecentralizowaną, bezpośrednią wymianę walut fiducjarnych na kryptoaktywa przy użyciu technologii open banking oraz smart kontraktów. Jego flagowy produkt to aplikacja Ramp Instant, ułatwiająca klientom proces onboardingu.",
      "www": "www.ramp.network",
      "tags": [
        "Płatności",
        "Kryptowaluty & tokeny"
      ]
    },
    {
      "startup": "Runvido Sp. z o.o.",
      "description": "Narzędzie wykorzystujace technologię rzeczywistości rozszerzonej (AR), by przemawiać obrazem. Pomaga firmom komunikować się z klientem za pomocą tradycyjnych materiałów drukowanych czy opakowań produktów, na których producent może zapisać dodatkowe treści w postaci filmu, odtwarzanego w aplikacji.  ",
      "www": "www.runvido.com",
      "tags": [
        "Usługi dodane",
        "Zaangażowanie klienta",
        "Marketing i reklama"
      ]
    },
    {
      "startup": "SCFO",
      "description": "Kwestionuje obecny status quo w rozliczeniach między przedsiębiorstwami. Supply Chain Finance Optimization (SCFO) oferuje więc innowacyjne rozwiązanie w dziedzinie optymalizacji finansowej, umożliwiające zamianę zobowiązań w dodatkowy zysk, a należności - w lepszą płynność.",
      "www": "www.scfo.pl",
      "tags": [
        "Płatności",
        "Oszczędności & inwestycje",
        "Usługi dodane"
      ]
    },
    {
      "startup": "SentiOne",
      "description": "Oferuje m.in. SentiOne Listening - narzędzie do monitorowania wypowiedzi internautów, zbierające w jednym miejscu opinie i artykuły z całego internetu, m.in. z mediów społecznościowych i blogów. Rozwija też platformę do obsługi klientów w wielu kanałach komunikacyjnych.",
      "www": "sentione.com",
      "tags": [
        "Obsługa klienta",
        "Optymalizacja procesów wewnętrznych",
        "Analiza danych"
      ]
    },
    {
      "startup": "ShareHire",
      "description": "Dostawca programów poleceń rekrutacyjnych. Przygotowuje dedykowane portale do pozyskiwania poleceń i kompleksowo zajmuje się ich obsługą. Oferuje sprawdzone rozwiązania prawno-podatkowe, komunikacyjne oraz te związane z wypłatą nagród finansowych lub rzeczowych. ",
      "www": "sharehire.pl",
      "tags": [
        "Optymalizacja procesów wewnętrznych",
        "Zasoby ludzkie"
      ]
    },
    {
      "startup": "Sherlock Waste",
      "description": "Platforma wspierająca firmy w budowaniu zaangażowania i podnoszeniu satysfakcji pracowników. Pomaga tworzyć nowoczesne organizacje z kulturą otwartości, duchem innowacyjności i pracy zespołowej. Sherlock Waste angażuje pracowników we wspólne doskonalenie firmy – tak, by codzienne problemy i wyzwania zmieniać w innowacyjne pomysły.",
      "www": "pl.sherlockwaste.com",
      "tags": [
        "Zaangażowanie klienta",
        "Optymalizacja procesów wewnętrznych",
        "Zasoby ludzkie"
      ]
    },
    {
      "startup": "SMEO SA",
      "description": "Spółka świadczy usługi faktoringu online. Daje mikro i małym przedsiębiorcom szybki dostęp do kapitału obrotowego, finansując ich faktury przychodowe i monitorując wierzytelności. Wykorzystuje big data, AI oraz algorytmy scoringowe do pogłębionej analizy zdolności kredytowej.",
      "www": "www.smeo.pl",
      "tags": [
        "Pożyczki i finansowanie"
      ]
    },
    {
      "startup": "Solfy",
      "description": "Platforma ratalna dla banków i retailu, zwiększająca ich obroty i zyski. System dzieli każdą płatność na równe części, pozwalając konsumentom płacić comiesięczne raty, bez odsetek. Solfy oferuje również  fizyczną kartę płatniczą i aplikację mobilną.",
      "www": "Solfy.com",
      "tags": [
        "Płatności",
        "Pożyczki i finansowanie",
        "E-commerce & programy lojalnościowe"
      ]
    },
    {
      "startup": "Sportbonus",
      "description": "Unikatowy program, budujący zaangażowanie wśród konsumentów, kochających sport. Karta płatnicza jako identyfikator uczestnika programu sprawia, że w łatwy sposób możemy poprzez codzienne zakupy wspierać ukochany klub, cel, organizację. Partnerami są sieci sklepów w całej Polsce.",
      "www": "sportbonus.pl",
      "tags": [
        "E-commerce & programy lojalnościowe",
        "Zaangażowanie klienta",
        "Analiza danych"
      ]
    },
    {
      "startup": "SupplierPlus",
      "description": "Platforma do finansowania dostawców. Pozwala korporacjom zapewnić dźwignię finansową ich dostawcom, jednocześnie poprawiając płynność tych ostatnich. Narzędzie, działające jako odwrócony faktoring, minimalizuje też ryzyko banków, finansujących dostawców z segmentu MŚP.\n ",
      "www": "www.supplierplus.com",
      "tags": [
        "Pożyczki i finansowanie"
      ]
    },
    {
      "startup": "Surf4Tax",
      "description": "Firma, rozwijająca bezpłatną aplikację do komunikacji z instytucjami finansowymi (np. bankami), dzięki której biura rachunkowe mogą przekazywać klientom oferty produktów finansowych. To nowy kanał dystrybucji ofert finansowania i dodatkowe zyski z prowizji dla biur rachunkowych. ",
      "www": "www.surf4tax.com",
      "tags": [
        "Oszczędności & inwestycje"
      ]
    },
    {
      "startup": "Sygnet.ai",
      "description": "Łączy świat offline z online, budując zdecentralizowany system e-tożsamości, który będzie traktował użytkownika podmiotowo, dając mu możliwość zarabiania na własnych danych. Sygnet.ai dostarczy też usługi do zarządzania danymi dla sprzedawców online i offline. \n \n",
      "www": "https://sygnet.ai/",
      "tags": [
        "Zarządzanie finansami osobistymi (digital wallet, PFM)",
        "E-commerce & programy lojalnościowe",
        "Analiza danych"
      ]
    },
    {
      "startup": "Synerise SA",
      "description": "Platforma wykorzystująca dane i AI, pomagające małym i dużym firmom lepiej reagować na potrzeby ich klientów. Rozwiązania Synerise to m.in.: automatyzacja, business intelligence, personalizacja komunikacji, a także zarządzanie polityką cenową w czasie rzeczywistym. ",
      "www": "https://synerise.com",
      "tags": [
        "E-commerce & programy lojalnościowe",
        "Zaangażowanie klienta",
        "Marketing i reklama"
      ]
    },
    {
      "startup": "Tensorflight",
      "description": "Rozwiązanie do automatycznej inspekcji nieruchomości na podstawie zdjęć, zasilane przez AI. Software posiłkuje się systemami wizji komputerowej i deep learningu do precyzyjnego identyfikowania oraz segmentowania nieruchomości. Służy  m.in. branży ubezpieczeniowej. \n\n",
      "www": "https://tensorflight.com",
      "tags": [
        "Ubezpieczenia",
        "Analiza danych"
      ]
    },
    {
      "startup": "Transparent Data",
      "description": "RegTech, pozyskujący, wzbogacający i integrujący dane rejestrowe o przedsiębiorstwach w czasie rzeczywistym. Dostarcza je klientom z kilkudziesięciu źródeł za pośrednictwem API, baz danych czy platform www. Tym samym przyspiesza i automatyzuje procesy oceny ryzyka.\n\n",
      "www": "https://transparentdata.pl/",
      "tags": [
        "Rozwiązania oddziałowe",
        "Optymalizacja procesów wewnętrznych",
        "Analiza danych"
      ]
    },
    {
      "startup": "Trefix",
      "description": "Aplikacja online dla klientów MŚP do zarządzania ryzykiem walutowym w transakcjach zagranicznych, kontrolowania bieżących różnic walutowych czy agregacji danych z wewnątrz i zewnątrz przedsiębiorstwa. Wprowadza elementy automatyzujące i optymalizujące te procesy. ",
      "www": "https://trefix.co/",
      "tags": [
        "Rozwiązania oddziałowe"
      ]
    },
    {
      "startup": "TuPrawnik",
      "description": "Serwis typu marketplace, dedykowany osobom poszukującym profesjonalnego doradztwa prawnego. Klient opisuje swój problem prawny, otrzymuje zindywidualizowane oferty z bazy ponad 300 najlepszych prawników z całej Polski. TuPrawnik oferuje też szybkie konsultacje prawne w stałej cenie.",
      "www": "www.tuprawnik.pl",
      "tags": [
        "Usługi dodane",
        "Obsługa klienta",
        "Usługi prawne"
      ]
    },
    {
      "startup": "Tutorial Online",
      "description": "Rozwiązanie zapewniające wsparcie użytkownikom aplikacji webowych oraz stron www. Zastępuje w biznesie tradycyjne, nieefektywne rozwiązania w zakresie transferu wiedzy (np. e-learning), wspiera on-boarding. Monitoruje przeglądarkę i przejmuje rolę doświadczonego pracownika, wspierającego początkującego. Pokazuje mu co kliknąć i dostarcza niezbędnych informacji.",
      "www": "https://www.tutorialonline.pl/",
      "tags": [
        "Rozwiązania oddziałowe",
        "Obsługa klienta",
        "Optymalizacja procesów wewnętrznych"
      ]
    },
    {
      "startup": "Twisto Polska",
      "description": "Spółka wprowadzająca na rynek innowacyjne rozwiązania z pogranicza płatności, e-commerce oraz consumer finance. Dostarcza aplikację do zarządzania finansami z bezpłatną linią debetową na 45 dni i kartę wielowalutową. ",
      "www": "www.twisto.pl",
      "tags": [
        "Płatności",
        "Zarządzanie finansami osobistymi (digital wallet, PFM)",
        "Pożyczki i finansowanie"
      ]
    },
    {
      "startup": "Upswot",
      "description": "Pozwala branży finansowej wejść na nowy poziom relacji marketingowych z sektorem małych i średnich firm (MŚP). Daje dostęp do kluczowych danych w firmach, pozwalając np. bankom, w czasie rzeczywistym, wysyłać oferty szyte na miarę dla każdego z przedstawicieli MŚP. \n",
      "www": "https://upswot.com/",
      "tags": [
        "Bankowość internetowa",
        "Zaangażowanie klienta",
        "Analiza danych"
      ]
    },
    {
      "startup": "Vindicat.pl",
      "description": "System pozwalający firmom na skuteczne i nowoczesne odzyskiwanie pieniędzy od kontrahentów. Daje dostęp do działań związanych z wyegzekwowaniem należności od dłużnika i automatycznego wygenerowania np.: wezwania do zapłaty, pozwu do e-sądu czy publikacji na e-giełdzie długów.",
      "www": "www.vindicat.pl",
      "tags": [
        "Płatności",
        "Usługi dodane",
        "Usługi prawne"
      ]
    },
    {
      "startup": "Vintom sp. z o.o.",
      "description": "Platforma personalizowanego wideo, pozwalająca markom tworzyć komunikację z klientami w oparciu na filmach, zwiększająca ich zaangażowanie. Dostarcza unikalne doświadczenie obsługi dla każdego klienta. Przynosi rezultaty biznesowe w procesach związanych ze sprzedażą, retencją, onboardingiem czy windykacją.",
      "www": "https://www.vintom.com/",
      "tags": [
        "Zaangażowanie klienta",
        "Obsługa klienta",
        "Marketing i reklama"
      ]
    },
    {
      "startup": "VoiceLab.AI",
      "description": "Technologia do automatycznego rozpoznawania mowy, biometrii głosu oraz analizy języka naturalnego. Wykorzystywana jest do automatyzacji procesów sprzedaży i obsługi klienta. W przypadku klientów indywidualnych, spółka oferuje rozpoznawanie mowy do tworzenia dokumentów. ",
      "www": "voicelab.ai",
      "tags": [
        "Bankowość internetowa",
        "Obsługa klienta",
        "Analiza danych"
      ]
    },
    {
      "startup": "Wandlee",
      "description": "Spółka spcjalizująca się we wdrażaniu chatbotów w korporacjach - zaczynała od obsługi klienta i sprzedaży, a obecnie skupia się na obszarze HR (m.in. onboarding i rekrutacja). Tworzy rozwiązania dla największych firm na świecie, a z chatbotów Wandlee korzysta już 2 mln osób dziennie.",
      "www": "https://wandlee.com/",
      "tags": [
        "Optymalizacja procesów wewnętrznych",
        "Zasoby ludzkie",
        "Analiza danych"
      ]
    },
    {
      "startup": "Waywer",
      "description": "Platforma automatyzująca procesy wymagające komunikacji z klientami, partnerami czy pracownikami przy użyciu interaktywnych treści. Dopasowuje się do odbiorcy i pozwala budować różnego rodzaju procesy komunikacyjne - m.in. sprzedaży real-time czy marketingu masowego.",
      "www": "waywer.com",
      "tags": [
        "Zaangażowanie klienta",
        "Zasoby ludzkie",
        "Marketing i reklama"
      ]
    },
    {
      "startup": "WorkBee",
      "description": "Platforma umożliwiająca zarządzanie zdalnymi projektami biznesowymi w modelu sharing economy. Zapewnia procownikom dowolność wyboru miejsca, czasu i stawek. ",
      "www": "www.workbee.pl",
      "tags": [
        "Zaangażowanie klienta",
        "Obsługa klienta",
        "Zasoby ludzkie"
      ]
    },
    {
      "startup": "XOXO WiFi",
      "description": "Twórca mobilnego routera, zapewniającego w prosty sposób dostęp do szybkiego i bezpiecznego internetu w ponad 140 krajach. Pomaga uniknąć kosztownych opłat roamingowych, a unikalna technologia łączy użytkownika z najsilniejszym, lokalnym operatorem w danym miejscu. ",
      "www": "www.xoxowifi.com",
      "tags": [
        "Usługi dodane",
        "Cyberbezpieczeństwo"
      ]
    },
    {
      "startup": "ZenCard sp. z o.o.",
      "description": "Oferuje narzędzie do marketingu transakcyjnego i budowania lojalności. Umożliwia wirtualizację i konsolidację kart lojalnościowych na dowolnej karcie płatniczej. Technologia ZenCard rozpoznaje konsumenta m.in. po identyfikatorze karty płatniczej i działa w czasie rzeczywistym. ",
      "www": "https://zencard.pl/",
      "tags": [
        "E-commerce & programy lojalnościowe",
        "Zaangażowanie klienta",
        "Analiza danych"
      ]
    },
    {
      "startup": "ZweryfikujFirmę.pl",
      "description": "W pełni automatyczna platforma do oceny bieżącej sytuacji finansowej przedsiębiorstw na podstawie jednolitych plików kontrolnych (JPK). Firma oferuje produkty przeznaczone zarówno dla przedsiębiorców (m.in. weryfikacja kontrahenta), jak i instytucji finansowych czy biegłych rewidentów.",
      "www": "ZweryfikujFirmę.pl",
      "tags": [
        "Pożyczki i finansowanie",
        "Obsługa klienta",
        "Analiza danych"
      ]
    },
    {
      "startup": "2take.it",
      "description": "Globalny program lojalnościowy typu payback, rozwijany w oparciu na technologii czytania danych ze zdjęć paragonów. Równolegle 2take.it pracuje nad technologią pozyskiwania danych o kupionych produktach przy użyciu płatności mobilnych.",
      "www": "2take.it ",
      "tags": [
        "Płatności",
        "Zaangażowanie klienta",
        "Analiza danych"
      ]
    }
  ],

  tags: [
    'Obsługa klienta',
    'Optymalizacja procesów wewnętrznych',
    'Analiza danych',
    'Zaangażowanie klienta',
    'Marketing i reklama',
    'Usługi prawne',
    'Pożyczki i finansowanie',
    'Bankowość internetowa',
    'Otwarta bankowość',
    'Rozwiązania oddziałowe',
    'E-commerce & programy lojalnościowe',
    'Płatności',
    'Oszczędności & inwestycje',
    'Usługi dodane',
    'Cyberbezpieczeństwo',
    'Zasoby ludzkie',
    'Ubezpieczenia',
    'Zarządzanie finansami osobistymi (digital wallet, PFM)',
    'Kryptowaluty & tokeny',
  ],
};

export default {
  heading: `Startups`,
  title: `Startups`,
  www: 'web',
  items: [
    {
      "startup": "AAZZUR",
      "description": "Fintech supported by Techstars, which provides banks, insurers and financial companies with a white-label solution ensuring personalised customer service in remote channels.",
      "www": "www.aazzur.com",
      "tags": [
        "E-commerce & loyalty programs",
        "Open Banking",
        "Data analysis"
      ]
    },
    {
      "startup": "abyss glass Group",
      "description": "Manufacturer of interactive mirrors used as an interactive medium. The company designs its own mirror applications providing customers with a unique message. Abyss Glass products are designed for a variety of industries and sectors, including retail and creative marketing. They are used to display advertisements, videos and allow you to interact with the recipient.",
      "www": "www.abyssglass.pl",
      "tags": [
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "AI BUSTERS",
      "description": "An engine for advanced message analysis for customer service departments (e.g. e-mails, online forms) in the financial and insurance industry. Uses advanced natural language processing (NLP), streamlining customer service work within existing systems in the organisation.",
      "www": "https://aibusters.com",
      "tags": [
        "Insurance",
        "Customer service",
        "Data analysis"
      ]
    },
    {
      "startup": "AI Investments",
      "description": "Investment portfolio optimisation platform using AI. Uses complex investment strategies and advanced predictive methods. Combined with optimisation algorithms, they help achieve very good investment results.",
      "www": "www.aiinvestments.pl",
      "tags": [
        "Savings & investments"
      ]
    },
    {
      "startup": "Algolytics Technologies",
      "description": "Creates own solutions for fintechs (including risk assessment, anti-fraud, blockchain) and tools for marketing based on machine learning (e.g. marketing automation, programmatic, lead qualification).",
      "www": "www.algolytics.pl",
      "tags": [
        "E-commerce & loyalty programs",
        "Optimisation of internal processes",
        "Data analysis"
      ]
    },
    {
      "startup": "APPLICA.AI",
      "description": "It revolutionises the way companies use AI to automate processes that require text comprehension. Thanks to proprietary technology, the Applica solution is tested in new applications based on several examples. They are used by banks, media companies and law firms.",
      "www": "applica.ai",
      "tags": [
        "Optimisation of internal processes"
      ]
    },
    {
      "startup": "Autenti",
      "description": "A platform for authorising documents and concluding contracts online. Thanks to Autenti, documents may be signed electronically through a computer, tablet or smartphone at any place and time. It works in accordance with both European and national regulations.",
      "www": "www.autenti.com",
      "tags": [
        "Savings & investments",
        "Customer service",
        "Optimisation of internal processes"
      ]
    },
    {
      "startup": "Bancovo",
      "description": "The first fully digital credit platform in Poland. Thanks to it, clients can get access to real and current offers of many banks and loan companies. The process of choosing an offer and concluding a loan agreement is simple and quick - it takes up to 15 minutes.",
      "www": "www.bancovo.pl",
      "tags": [
        "Loans and financing"
      ]
    },
    {
      "startup": "banqUP",
      "description": "It helps financial institutions and fintechs efficiently integrate their solutions with a wide range of European banking APIs. With banqUP, you can e.g. verify the customer or aggregate sources of financial data. The company is working on a TPP-as-a-service solution.",
      "www": "www.banqup.com",
      "tags": [
        "Internet banking",
        "Open Banking",
        "Data analysis"
      ]
    },
    {
      "startup": "Beesfund",
      "description": "The first and largest Polish equity crowdfunding platform, founded in 2012. In recent years, it has been growing at a rate of 100 percent annually. 61 projects have been carried out on the platform for a total amount of PLN 33.9 million, collected with the participation of over 29,000 registered investors.",
      "www": "https://beesfund.com",
      "tags": [
        "Loans and financing",
        "Marketing and Advertising",
        "Legal services"
      ]
    },
    {
      "startup": "Billon",
      "description": "Creator of efficient and scalable distributed ledger technology for business. Billon, with the help of a blockchain, combines national currency transactions, document saving and identity management within one system. And all this in accordance with applicable regulations.",
      "www": "www.billongroup.com",
      "tags": [
        "Payments",
        "Branch solutions",
        "Optimisation of internal processes",
        "Cybersecurity"
      ]
    },
    {
      "startup": "BillTech",
      "description": "A solution that connects companies periodically issuing invoices (e.g. telecoms, energy suppliers) with individual clients using the application to make payments. Thanks to this, e.g. the bank can provide easy payments to companies in its application",
      "www": "https://billtech.pl/api/",
      "tags": [
        "Payments",
        "Personal finance management (digital wallet, PFM)"
      ]
    },
    {
      "startup": "BlockCore (Tokenization Club + bitSecurities)",
      "description": "Solution centre for business blockchainization and tokenization. The company provides blockchain infrastructure, fully supports digital communication of companies with its partners and clients. It provides all the elements needed for business security and continuity.",
      "www": "www.blockcore.fund",
      "tags": [
        "Payments",
        "Loans and financing",
        "Savings & investments",
        "Cryptocurrencies & Tokens"
      ]
    },
    {
      "startup": "BSafer sp. z o.o.",
      "description": "Offers its clients a personal data protection system designed for Internet users. It informs them where and by whom their personal data is used and for what purpose. Therefore, BSafer allows private individuals to manage and monetize their marketing consents.",
      "www": "https://bsafer.pl",
      "tags": [
        "E-commerce & loyalty programs",
        "Cyber​​security",
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "Carrotspot",
      "description": "A platform enabling building a corporate community oriented towards achieving common goals. It gives the opportunity to implement incentive programs fully tailored to the company's capabilities and the needs of its employees. It allows you to build employee commitment.",
      "www": "www.carrotspot.pl",
      "tags": [
        "Optimisation of internal processes",
        "Human Resources"
      ]
    },
    {
      "startup": "Cashap",
      "description": "Free B2C app built for and together with Generation Z (13-19-year-olds). Its task is to finance the needs of the Z generation, using a unique payment model and education in the area of ​​finance. Cashap does not require a bank account.",
      "www": "cashap.pl",
      "tags": [
        "Payments",
        "Personal finance management (digital wallet, PFM)",
        "E-commerce & loyalty programs"
      ]
    },
    {
      "startup": "CashDirector SA",
      "description": "CashDirector is a digital CFO based on an accounting platform designed for small and medium enterprises. It also provides a white-label solution for banks. It improves liquidity management in companies and gives banks the opportunity to sell products to SMEs.",
      "www": "www.cashdirector.com",
      "tags": [
        "Value-added services",
        "Internet banking",
        "Data analysis"
      ]
    },
    {
      "startup": "Cashy",
      "description": "A platform that offers discounts to buyers for paying invoices to their suppliers in advance, without requiring correction invoices. The whole process of communication between contractors and service takes place online. Therefore, Cashy allows buyers to generate significant operational savings.",
      "www": "www.cashy.pl",
      "tags": [
        "Loans and financing",
        "Savings & investments",
        "Optimisation of internal processes"
      ]
    },
    {
      "startup": "Chatbotize",
      "description": "Conversational marketing platform. It allows you to attract potential customers, engage them and sell products and services on chat channels. Chatbotize also allows you to reduce customer service costs by automating responses and solutions for consultants.",
      "www": "https://chatbotize.com",
      "tags": [
        "Customer engagement",
        "Customer service",
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "ChatForce",
      "description": "Dedicated messenger solutions (e.g. Messenger, WhatsApp) for companies. Thanks to the end-to-end solution, it is possible to open an interactive, personalised and fully contextual channel of communication with clients.",
      "www": "https://chatforce.io",
      "tags": [
        "Customer engagement",
        "Customer service",
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "Cluify",
      "description": "Technology that connects the online and offline world allowing a better understanding of customer needs. It offers, among others tools for better management of the commercial ecosystem, as well as offline customer behaviour data. Thanks to this, online advertising reaches people visiting specific places.",
      "www": "www.cluify.com",
      "tags": [
        "Customer engagement",
        "Marketing and Advertising",
        "Data analysis"
      ]
    },
    {
      "startup": "Coinfirm",
      "description": "Coinfirm is a leading global provider of solutions based on blockchain technology for clients from the financial institutions segment. It is also a pioneer in the field of technologies used in the AML process and risk analysis using digital currencies.",
      "www": "https://www.coinfirm.com/",
      "tags": [
        "Value-added services",
        "Cryptocurrencies & Tokens",
        "Data analysis"
      ]
    },
    {
      "startup": "Coinquista S.A.",
      "description": "Polish cryptocurrency exchange, entered in the register of small payment institutions kept by the Polish Financial Supervision Authority. Coinquista combines the traditional world of finance with modern technology, providing users with unlimited access to digital assets.",
      "www": "https://coinquista.com",
      "tags": [
        "Payments",
        "Savings & investments",
        "Cryptocurrencies & Tokens"
      ]
    },
    {
      "startup": "Creamfinance",
      "description": "An international financial group specialising in providing loans online. Its mission - \"Make money available\" - is to allow clients to receive funds easily, quickly and with minimum formalities. In 2018, the group granted loans totaling EUR 174 million.",
      "www": "www.creamfinance.com",
      "tags": [
        "Loans and financing"
      ]
    },
    {
      "startup": "Devskiller",
      "description": "Online system for verifying programmers' skills in the recruitment process and supporting HR processes. It is powered by a proprietary methodological tool, including an opportunity to build a career path in organisations. It helps to attract programming talents from the market.",
      "www": "www.devskiller.com",
      "tags": [
        "Human Resources"
      ]
    },
    {
      "startup": "Digital Fingerprints",
      "description": "A security system based on behavioural biometry that protects users in real time. The solution is intended for authentication in banking applications, using individual models of behaviour in interaction with the computer (e.g. moving the mouse or typing). It does not require any additional equipment (e.g. token) and is impossible to steal/take over.",
      "www": "https://fingerprints.digital/",
      "tags": [
        "Cybersecurity"
      ]
    },
    {
      "startup": "Digital Teammates SA",
      "description": "Supplier of RPA (Robotic Process Automation) through renting robots. Thanks to its own technology and a specialised team, it is able to assess robotic potential, produce robots and launch them in 4-6 weeks. It brings new quality to back-office processes in companies.",
      "www": "www.dtmates.com",
      "tags": [
        "Value-added services",
        "Branch solutions",
        "Optimisation of internal processes"
      ]
    },
    {
      "startup": "Dronn.com",
      "description": "Helps financial institutions automate customer service processes by outsourcing them to a robotic call centre. Thanks to full control over technology, infrastructure and own team for implementation and service, it ensures the highest quality of service at optimal costs.",
      "www": "www.dronn.com",
      "tags": [
        "Customer service"
      ]
    },
    {
      "startup": "Edward",
      "description": "A mobile, intelligent assistant for sales departments that allows you to automate manual data entry, recording and checking information about customer relationships. Edward can supply existing CRM systems with data and download information useful in the work of a sales representative.",
      "www": "https://edward.ai/",
      "tags": [
        "Customer service",
        "Optimisation of internal processes",
        "Data analysis"
      ]
    },
    {
      "startup": "Elastic Cloud Solutions",
      "description": "Creator of Workai - a digital employee experience platform that improves internal communication, knowledge management and employee engagement using mobile, IoT and AI technologies. Workai offers solutions ready to be implemented in the organisation, customisable to the client's needs.",
      "www": "www.workai.com",
      "tags": [
        "Branch solutions",
        "Customer service",
        "Optimisation of internal processes"
      ]
    },
    {
      "startup": "Emplocity sp. z o.o.",
      "description": "Creator of Emplobot - a chatbot that introduces AI to recruitment processes. Based on proprietary AI/NLP algorithms, it automates the processes of obtaining candidates, their preselection and communication during recruitment.",
      "www": "www.emplocity.pl",
      "tags": [
        "Branch solutions",
        "Optimisation of internal processes",
        "Human Resources"
      ]
    },
    {
      "startup": "ExChord",
      "description": "Application for importers and exporters. Its users, regardless of location and bank providers used have the opportunity to earn on currency risk. Thus, ExChord aggregates key information and automates processes in currency risk management.",
      "www": "www.exchord.com",
      "tags": [
        "Optimisation of internal processes"
      ]
    },
    {
      "startup": "Fandla Faktoring sp. z o.o.",
      "description": "A tool for entrepreneurs to finance unpaid invoices in PLN and EUR. Each invoice issued and each related transaction is a separate contract, processed quickly and electronically. No unnecessary formalities – just an invoice.",
      "www": "fandla.com",
      "tags": [
        "Loans and financing"
      ]
    },
    {
      "startup": "feeCOMPASS",
      "description": "An intelligent platform that provides comparisons and online and offline sales arguments for companies. The virtual assistant constantly determines the needs of each consumer in order to give him the best solution among the products offered by a given company.",
      "www": "www.feecompass.com",
      "tags": [
        "Branch solutions",
        "Customer service",
        "Data analysis"
      ]
    },
    {
      "startup": "Finanteq SA",
      "description": "Software supplier and consulting company focused on mobile finance. Provides complete solutions or ready-to-implement components for mobile channels, including in banks. Finanteq solutions are used in several large financial institutions around the world.",
      "www": "www.finanteq.com",
      "tags": [
        "E-commerce & loyalty programs",
        "Value-added services",
        "Internet banking"
      ]
    },
    {
      "startup": "Finiata",
      "description": "A tool that solves the liquidity problems of the smallest companies for which bank capital is unavailable due to, for example, a short history of operations. Finiata provides factoring and loans via the Internet, using proprietary AI algorithms and scoring models.",
      "www": "www.finiata.pl",
      "tags": [
        "Loans and financing",
        "Data analysis"
      ]
    },
    {
      "startup": "Fitqbe",
      "description": "It combines the functions of a gamification wellbeing platform, company social network and cafeteria system. It builds above-average commitment and a strong organisational culture, based on the appreciation and remuneration of employees on one intuitive platform.",
      "www": "https://fitqbe.com",
      "tags": [
        "Branch solutions",
        "Optimization of internal processes",
        "Human Resources"
      ]
    },
    {
      "startup": "GoRODO.pl",
      "description": "An application that allows you to implement and then maintain compliance with the GDPR in every company. It allows you to carry out the implementation procedure without knowledge of legal issues. As a result, the entrepreneur receives a full set of documents necessary to maintain compliance with the GDPR.",
      "www": "www.gorodo.pl",
      "tags": [
        "Legal services"
      ]
    },
    {
      "startup": "Gratifu",
      "description": "Gratifu is a loyalty platform that connects business with customers. It allows for deep personalisation, as well as timely, smooth implementation and immediate rewarding of customers. It is a flexible tool for building a system of discounts and rewards, increasing customer loyalty.",
      "www": "www.gratifu.com",
      "tags": [
        "E-commerce & loyalty programs"
      ]
    },
    {
      "startup": "HomeProfit",
      "description": "Aggregates hundreds of stationary and online stores in the Home & Living category. It offers permanent discounts of up to several dozen percent in over 400 stores in the finishing and interior design industry throughout Poland. Discounts are available to holders of a dedicated card or via a mobile application.",
      "www": "homeprofit.pl",
      "tags": [
        "Savings & investments",
        "E-commerce & loyalty programs",
        "Value-added services"
      ]
    },
    {
      "startup": "Hotailors",
      "description": "A tool that integrates all processes related to reservations and travel management. Thanks to access to the best offers, Hotailors customers reduce the time and costs needed to plan business trips. In addition, travelers can flexibly plan trips, while managers get a centralized process and confidence that reservations are in line with regulations.",
      "www": "https://hotailors.com",
      "tags": [
        "Loans and financing",
        "Value-added services",
        "Optimisation of internal processes"
      ]
    },
    {
      "startup": "IC Solutions sp. z o.o.",
      "description": "Creator of the IC Pen Platform, which using a digital pen, allows the simultaneous creation of a paper version of documents and their electronic counterparts. It allows the user to choose a device (digital pen, screen or Wacom device) to create an e-document. Thanks to the biometric signature with graphological features by IC Pen, the electronic document has legal value. ",
      "www": "www.icpen.pl, www.icsolutions.pl",
      "tags": [
        "Branch solutions",
        "Customer service",
        "Optimisation of internal processes"
      ]
    },
    {
      "startup": "Identt",
      "description": "A solution supporting the process of verification of identity documents and the persons using them. Automatically verifies the correctness, integrity and originality of the document. At the same time, with the help of biometric face identification, the system verifies the document's bearer.",
      "www": "www.identt.pl",
      "tags": [
        "Value-added services",
        "Optimisation of internal processes",
        "Cybersecurity"
      ]
    },
    {
      "startup": "Ignibit SA",
      "description": "Software house specialising in virtual (VR), augmented (AR) and mixed reality (MR). Provides solutions for partners around the world. Its technologies are already used by such brands as: Cadillac, Volkswagen and Compensa.",
      "www": "ignibit.com",
      "tags": [
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "Investsuite",
      "description": "An international provider of solutions in the SaaS model in the field of wealth management, driven by an original algorithm. InvestSuite is an intelligent, digital financial advisor technology, ready to be implemented by online brokers, banks or pension funds around the world.",
      "www": "www.investsuite.com",
      "tags": [
        "Savings & investments"
      ]
    },
    {
      "startup": "isourcetec",
      "description": "Provider of solutions in the field of Robotics Process Automation (RPA). ISourceTec robots allow you to automate repetitive, time-consuming tasks performed by people, without the need to interfere with IT architecture. They are used, among others in HR, financial and customer service projects.",
      "www": "https://isourcetec.com/",
      "tags": [
        "Optimisation of internal processes",
        "Human Resources"
      ]
    },
    {
      "startup": "Justtag",
      "description": "A platform offering innovative advertising solutions combining the online and offline world. Justtag is developing the KoalaMetrics technology analysing offline user behaviour, as well as JustWIFI - the largest Wi-Fi advertising network in Central and Eastern Europe.",
      "www": "www.justtag.com",
      "tags": [
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "Kontomatik",
      "description": "Provides services for banks and other financial institutions. Kontomatik solutions allow access to clients' banking data, which can be used e.g. for the digital identification of a client. In addition, thanks to machine learning, it facilitates building customer financial behaviour profiles.",
      "www": "www.kontomatik.com",
      "tags": [
        "Open banking",
        "Optimisation of internal processes",
        "Data analysis"
      ]
    },
    {
      "startup": "Kredytmarket",
      "description": "An online financial platform that provides online short-term business loans. Over 3 years, over 5,000 micro-entrepreneurs have received capital for a total amount of over PLN 25 million. Thanks to a credit decision in up to 15 minutes, it helps entrepreneurs get financing quickly and on a transparent basis, with minimum formalities.",
      "www": "www.kredytmarket.com",
      "tags": [
        "Loans and financing"
      ]
    },
    {
      "startup": "LeaseLink Sp. z o.o.",
      "description": "A Fintech leasing company specialising in financing purchases for businesses made in e-stores and stationary outlets. It offers an application thanks to which it is the only one in the world that treats leasing payments as payments by transfer (pay by link).",
      "www": "www.leaselink.pl",
      "tags": [
        "Payments",
        "Loans and financing",
        "E-commerce & loyalty programs"
      ]
    },
    {
      "startup": "LogicAI",
      "description": "Implements end-to-end solutions in the field of AI and Data Science for companies and organisations such as: Ministry of Health, Louis Vuitton or Rolls-Royce. LogicAI also manages Kaggle, the largest data scientist community in the world, supported by Google.",
      "www": "logicai.io",
      "tags": [
        "Loans and financing",
        "E-commerce & loyalty programs",
        "Customer engagement"
      ]
    },
    {
      "startup": "LUNA",
      "description": "The company is developing a programming environment for data visualisation and transformation. Luna allows you to use existing machine learning resources to depict current market trends. This allows customers to better understand processes and make better business decisions.",
      "www": "www.luna-lang.org",
      "tags": [
        "Data analysis"
      ]
    },
    {
      "startup": "Migam",
      "description": "A tool for connecting video with a sign language interpreter on a web browser and any device with a camera. Migam allows you to improve communication between deaf customers and hearing customer service employees.",
      "www": "https://migam.org/",
      "tags": [
        "Value-added services",
        "Customer service"
      ]
    },
    {
      "startup": "MintCloud",
      "description": "Powered by artificial intelligence, this is a ready-to-use marketing platform. It allows you to run effective campaigns with a limited budget collecting customer data from many sources, based on which it allows you to run automatic, multi-level marketing campaigns.",
      "www": "biz.mintcloud.me",
      "tags": [
        "E-commerce & loyalty programs",
        "Customer engagement",
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "My Płacimy sp. z o.o.",
      "description": "Offers a deferred payment service for micro-businesses making purchases online. We Pay fills the market gap and takes over risks by taking over e-commerce services. In this case, e-commerce does not risk anything or incur costs, and micro-businesses receive a quick trade credit.",
      "www": "www.myplacimy.pl",
      "tags": [
        "Payments",
        "Loans and financing",
        "E-commerce & loyalty programs"
      ]
    },
    {
      "startup": "NapoleonCat",
      "description": "A tool supporting brand communication management and customer service on social media. Improves the work of marketing teams and customer service by integrating support for multiple social platforms in one place and automating tasks.",
      "www": "https://napoleoncat.com",
      "tags": [
        "Customer engagement",
        "Customer service",
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "Neontri",
      "description": "Specialists in technologically advanced B2B solutions, e.g. for banks. The company provides proprietary solutions in the field of mobile banking, payment solutions and big data. It is used in major banks and payment organisations in Poland and around the world.",
      "www": "https://neontri.com",
      "tags": [
        "Payments",
        "Open Banking"
      ]
    },
    {
      "startup": "Nethone",
      "description": "Provider of solutions in the area of ​​KYU (Know Your Users), helping companies transform cyber threats into conscious and profitable business decisions. It works in the area of ​​cyber security and allows better understanding of users in remote channels, thus preventing payment fraud.",
      "www": "www.nethone.com",
      "tags": [
        "Payments",
        "Cybersecurity",
        "Data analysis"
      ]
    },
    {
      "startup": "Nextrope",
      "description": "Software house focused on implementing innovations in the fintech sector, specialising in the development of blockchain projects. Nextrope works with both startups and institutional partners, bringing industry experience to the field of R&D.",
      "www": "https://nextrope.com/en/",
      "tags": [
        "Cryptocurrencies & Tokens"
      ]
    },
    {
      "startup": "NuDelta",
      "description": "A system that recognises content from paper invoices or receipts, based on proprietary algorithms, using machine learning and semantic analysis. Nu Delta is an easily scalable program, ready for implementation in various business areas.",
      "www": "https://www.nudelta.pl/",
      "tags": [
        "Internet banking",
        "Optimisation of internal processes",
        "Data analysis"
      ]
    },
    {
      "startup": "PayPo",
      "description": "A company operating at the interface of payments and e-commerce. Its partners are the largest e-commerce stores to which it offers a \"buy now - pay later\" solution. PayPo buyers have 30 days to pay or use the instalment option.",
      "www": "paypo.pl",
      "tags": [
        "Payments",
        "Loans and financing",
        "E-commerce & loyalty programs"
      ]
    },
    {
      "startup": "PayUkraine",
      "description": "European money transfer service - in cash at the outlet or from card to card. It allows for a smooth, secure and immediate cash flow between the user's account and the recipient in Ukraine. It also offers an intuitive application and favourable exchange rates.",
      "www": "https://payukraine.com/",
      "tags": [
        "Payments",
        "Marketing and Advertising",
        "Data analysis"
      ]
    },
    {
      "startup": "Pergamin",
      "description": "An internet platform that automates the process of signing contracts in one, easily accessible and secure place. It enables remote signing of contracts with contractors by uploading your own contract or using a free template database. In 5 minutes it allows you to create any contract ready for signature.",
      "www": "https://pergam.in/",
      "tags": [
        "Optimisation of internal processes",
        "Legal services",
        "Data analysis"
      ]
    },
    {
      "startup": "placeme",
      "description": "Enables the analysis of consumer purchasing paths in the overlapping online and offline sales channels. Helps understand how they use services and create their profiles. Placeme Geoanalyst gives you the opportunity to choose, e.g. the best location for a new store.",
      "www": "https://placeme.pl/",
      "tags": [
        "Data analysis"
      ]
    },
    {
      "startup": "Protect.me",
      "description": "IT platform for distributing and servicing insurance online, with a ready business, legal and tax model. It offers a set of insurance products from reputable insurers, addressed to both business partners and the consumer market.",
      "www": "www.protectme.pl",
      "tags": [
        "Insurance"
      ]
    },
    {
      "startup": "ProtectAuth",
      "description": "Develops communication technology between the bank's system and the client's smartphone. In this way, it helps financial institutions to increase the security of online transactions, ensuring immutability and non-repudiation of transactions, thanks to the use of blockchain technology.",
      "www": "www.protectauth.com",
      "tags": [
        "Internet banking",
        "Cybersecurity"
      ]
    },
    {
      "startup": "Proxi.cloud",
      "description": "Provides brands with tools to combine traditional and digital points of contact with the consumer. Proxi.cloud, using mobile as a gateway to the offline world, enables personalised communication and provides decision-makers with valuable information regarding consumer flow and brand loyalty.",
      "www": "https://proxi.cloud",
      "tags": [
        "Customer engagement",
        "Marketing and Advertising",
        "Data analysis"
      ]
    },
    {
      "startup": "PublBox",
      "description": "SaaS platform for automation of marketing in social media. It allows companies to build marketing strategies on social media based on ready-made graphic solutions, campaign plans, tools for RSS feed publishing and managing the inbox.",
      "www": "https://publbox.com/pl/",
      "tags": [
        "Value-added services",
        "Customer service",
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "Quantum cx",
      "description": "A solution that improves the quality of service by increasing the number of employee and customers smile, e.g. in the financial industry. Quantum CX builds habits and motivates kindness, teaching a positive attitude. Detected smiles are converted into money, which is given to charity.",
      "www": "https://quantum.cx/",
      "tags": [
        "Branch solutions",
        "Customer engagement",
        "Customer service"
      ]
    },
    {
      "startup": "Rachuneo - operatorem jest Polskie Aplikacje Transakcyjne",
      "description": "An online platform that lets you compare electricity and gas supply offers for your home or business, and sign a contract via the Internet. It also allows you to analyse your current invoice and check for more favourable offers available on the market.",
      "www": "www.rachuneo.pl",
      "tags": [
        "Value-added services"
      ]
    },
    {
      "startup": "Ramp Network",
      "description": "The company is developing a platform enabling decentralised, direct exchange of fiat currency into cryptocurrency using open banking technology and smart contracts. Its flagship product is the Ramp Instant app, which simplifies the onboarding process for clients.",
      "www": "www.ramp.network",
      "tags": [
        "Payments",
        "Cryptocurrencies & Tokens"
      ]
    },
    {
      "startup": "Runvido Sp. z o.o.",
      "description": "A tool that uses augmented reality (AR) technology to communicate through an image. It helps companies communicate with the customer using traditional printed materials or product packaging, to which the producer can add additional content in the form of a video that plays in the app.",
      "www": "www.runvido.com",
      "tags": [
        "Value-added services",
        "Customer engagement",
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "SCFO",
      "description": "Questions the current status quo in settlements between businesses. Supply Chain Finance Optimization (SCFO) therefore offers an innovative solution in the field of financial optimisation, enabling the conversion of liabilities into additional profit, and receivables - into better liquidity.",
      "www": "www.scfo.pl",
      "tags": [
        "Payments",
        "Savings & investments",
        "Value-added services"
      ]
    },
    {
      "startup": "SentiOne",
      "description": "It offers, among others SentiOne Listening - a tool to monitor Internet users' statements, collecting opinions and articles from around the Internet in one place, including from social media and blogs. It also develops a platform to service customers in many communication channels.",
      "www": "sentione.com",
      "tags": [
        "Customer service",
        "Optimisation of internal processes",
        "Data analysis"
      ]
    },
    {
      "startup": "ShareHire",
      "description": "Provider of recruitment recommendation programmes. Creates dedicated portals for acquiring recommendations and provides comprehensive support for them. It offers proven tax and legal solutions, as well as those related to the payment of financial or material prizes.",
      "www": "sharehire.pl",
      "tags": [
        "Optimisation of internal processes",
        "Human Resources"
      ]
    },
    {
      "startup": "Sherlock Waste",
      "description": "A platform supporting companies in building engagement and increasing employee satisfaction. It helps create modern organisations with a culture of openness, a spirit of innovation and teamwork. Sherlock Waste engages employees in helping improve the company - so that everyday problems and challenges are transformed into innovative ideas.",
      "www": "pl.sherlockwaste.com",
      "tags": [
        "Customer engagement",
        "Optimisation of internal processes",
        "Human Resources"
      ]
    },
    {
      "startup": "SMEO SA",
      "description": "The company provides online factoring services. It gives micro and small entrepreneurs quick access to working capital, financing their income invoices and monitoring receivables. Uses big data, AI and scoring algorithms for in-depth creditworthiness analysis.",
      "www": "www.smeo.pl",
      "tags": [
        "Loans and financing"
      ]
    },
    {
      "startup": "Solfy",
      "description": "An installment platform for banks and retail, increasing their turnover and profits. The system divides each payment into equal parts, allowing consumers to pay monthly installments, without interest. Solfy also offers a physical payment card and a mobile application.",
      "www": "Solfy.com",
      "tags": [
        "Payments",
        "Loans and financing",
        "E-commerce & loyalty programs"
      ]
    },
    {
      "startup": "Sportbonus",
      "description": "A unique program that builds commitment among consumers who love sports. The payment card as the program participant's identifier means that we can easily support our beloved club, goal and organisation through daily purchases. Partners are store chains throughout Poland.",
      "www": "sportbonus.pl",
      "tags": [
        "E-commerce & loyalty programs",
        "Customer engagement",
        "Data analysis"
      ]
    },
    {
      "startup": "SupplierPlus",
      "description": "Supplier financing platform. It allows corporations to provide leverage to their suppliers while improving the liquidity of the latter. The tool, acting as reverse factoring, also minimises the risk of banks financing suppliers from the SME segment.",
      "www": "www.supplierplus.com",
      "tags": [
        "Loans and financing"
      ]
    },
    {
      "startup": "Surf4Tax",
      "description": "A company that is working on developing a free application for communicating with financial institutions (e.g. banks), thanks to which accounting offices can provide clients with offers of financial products. This is a new distribution channel for financing offers and additional commission income for accounting offices.",
      "www": "www.surf4tax.com",
      "tags": [
        "Savings & investments"
      ]
    },
    {
      "startup": "Sygnet.ai",
      "description": "Connects the offline and online worlds, building a decentralised e-identity system that will treat the user subjectively, giving him the opportunity to earn on his own data. Sygnet.ai will also provide data management services for online and offline sellers.",
      "www": "https://sygnet.ai/",
      "tags": [
        "Personal finance management (digital wallet, PFM)",
        "E-commerce & loyalty programs",
        "Data analysis"
      ]
    },
    {
      "startup": "Synerise SA",
      "description": "A platform that uses data and AI, helping small and large companies better respond to the needs of their clients. Synerise solutions include: automation, business intelligence, personalisation of communication, as well as real-time pricing policy management.",
      "www": "https://synerise.com",
      "tags": [
        "E-commerce & loyalty programs",
        "Customer engagement",
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "Tensorflight",
      "description": "A solution for automated property inspection based on photos, powered by AI. The software uses computer vision and deep learning systems for precise identification and segmentation of real estate. It serves the insurance industry, among others.",
      "www": "https://tensorflight.com",
      "tags": [
        "Insurance",
        "Data analysis"
      ]
    },
    {
      "startup": "Transparent Data",
      "description": "RegTech, acquiring, enriching and integrating company registration data in real time. It provides them to clients from several dozen sources via API, databases or websites. Thus, it accelerates and automates risk assessment processes.",
      "www": "https://transparentdata.pl/",
      "tags": [
        "Branch solutions",
        "Optimisation of internal processes",
        "Data analysis"
      ]
    },
    {
      "startup": "Trefix",
      "description": "Online application for SME clients to manage currency risk in foreign transactions, control current currency differences or aggregate data from inside and outside the company. It introduces elements that automate and optimise these processes.",
      "www": "https://trefix.co/",
      "tags": [
        "Branch solutions"
      ]
    },
    {
      "startup": "TuPrawnik",
      "description": "Marketplace website dedicated to people looking for professional legal advice. The client describes his legal problem and receives personalised offers from a database of over 300 best lawyers from all over Poland. TuPrawnik also offers quick legal consultations at a fixed price.",
      "www": "www.tuprawnik.pl",
      "tags": [
        "Value-added services",
        "Customer service",
        "Legal services"
      ]
    },
    {
      "startup": "Tutorial Online",
      "description": "A solution that provides support to users of web applications and websites. It replaces traditional, ineffective solutions in the field of knowledge transfer (e.g. e-learning) in business, and supports on-boarding. It monitors the browser and takes over the role of an experienced employee supporting a beginner, showing him where to click and providing necessary information.",
      "www": "https://www.tutorialonline.pl/",
      "tags": [
        "Branch solutions",
        "Customer service",
        "Optimisation of internal processes"
      ]
    },
    {
      "startup": "Twisto Polska",
      "description": "A company that launches innovative solutions in the areas of payments, e-commerce and consumer finance. Provides a financial management application with a free debit line for 45 days and a multi-currency card.",
      "www": "www.twisto.pl",
      "tags": [
        "Payments",
        "Personal finance management (digital wallet, PFM)",
        "Loans and financing"
      ]
    },
    {
      "startup": "Upswot",
      "description": "Allows the financial industry to enter a new level of marketing relations with the small and medium-sized enterprises (SME) sector. It gives access to key data in companies, allowing banks, for example, to send tailor-made offers to each SME representative in real time.",
      "www": "https://upswot.com/",
      "tags": [
        "Internet banking",
        "Customer engagement",
        "Data analysis"
      ]
    },
    {
      "startup": "Vindicat.pl",
      "description": "A system that allows companies to effectively and modernly recover money from contractors. It allows activities related to the collection of debts from the debtor and automatic generation, e.g. a request for payment, a claim to an e-court or posting on the e-debt exchange.",
      "www": "www.vindicat.pl",
      "tags": [
        "Payments",
        "Value-added services",
        "Legal services"
      ]
    },
    {
      "startup": "Vintom sp. z o.o.",
      "description": "A personalised video platform that allows brands to create videos to communicate with clients, increasing their involvement. It provides a unique service experience for each customer. It brings business results in processes related to sales, retention, onboarding or debt collection.",
      "www": "https://www.vintom.com/",
      "tags": [
        "Customer engagement",
        "Customer service",
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "VoiceLab.AI",
      "description": "Technology for automatic speech recognition, voice biometry and natural language analysis. It is used to automate sales and customer service processes. For individual clients, the company offers speech recognition for creating documents.",
      "www": "voicelab.ai",
      "tags": [
        "Internet banking",
        "Customer service",
        "Data analysis"
      ]
    },
    {
      "startup": "Wandlee",
      "description": "A company specialising in implementing chatbots in corporations - it started with customer service and sales, and currently focuses on the area of ​​HR (including onboarding and recruitment). It creates solutions for the largest companies in the world and Wandlee chatbots are already used by 2 million people daily.",
      "www": "https://wandlee.com/",
      "tags": [
        "Optimisation of internal processes",
        "Human Resources",
        "Data analysis"
      ]
    },
    {
      "startup": "Waywer",
      "description": "A platform that automates processes that require communication with clients, partners or employees using interactive content. It adapts to the recipient and allows you to build various types of communication processes - including real-time sales or mass marketing.",
      "www": "waywer.com",
      "tags": [
        "Customer engagement",
        "Human Resources",
        "Marketing and Advertising"
      ]
    },
    {
      "startup": "WorkBee",
      "description": "A platform for managing remote business projects in the sharing economy model. It provides proccessors with the freedom to choose the place, time and rates.",
      "www": "www.workbee.pl",
      "tags": [
        "Customer engagement",
        "Customer service",
        "Human Resources"
      ]
    },
    {
      "startup": "XOXO WiFi",
      "description": "Creator of a mobile router that provides easy access to fast and secure Internet in over 140 countries. It helps avoid expensive roaming charges, and the unique technology connects the user with the strongest local operator in a given location.",
      "www": "www.xoxowifi.com",
      "tags": [
        "Value-added services",
        "Cybersecurity"
      ]
    },
    {
      "startup": "ZenCard sp. z o.o.",
      "description": "Offers a tool for transactional marketing and building loyalty. It enables virtualisation and consolidation of loyalty cards on any payment card. ZenCard technology recognises the consumer, among others by payment card identifier and works in real time.",
      "www": "https://zencard.pl/",
      "tags": [
        "E-commerce & loyalty programs",
        "Customer engagement",
        "Data analysis"
      ]
    },
    {
      "startup": "ZweryfikujFirmę.pl",
      "description": "A fully automated platform for assessing the current financial situation of businesses based on Uniform Control Files (UCFs). The company offers products intended for entrepreneurs (e.g. contractor verification), as well as financial institutions or auditors.",
      "www": "ZweryfikujFirmę.pl",
      "tags": [
        "Loans and financing",
        "Customer service",
        "Data analysis"
      ]
    },
    {
      "startup": "2take.it",
      "description": "A global payback loyalty program developed based on the technology of reading data from receipt photos. At the same time, 2take.it is working on a technology for obtaining data on purchased products using mobile payments.",
      "www": "2take.it ",
      "tags": [
        "Payments",
        "Customer engagement",
        "Data analysis"
      ]
    }
  ],
  tags: [
    'Customer service',
    'Optimisation of internal processes',
    'Data analysis',
    'Customer engagement',
    'Marketing and Advertising',
    'Legal services',
    'Loans and financing',
    'Internet banking',
    'Open Banking',
    'Branch solutions',
    'E-commerce & loyalty programs',
    'Payments',
    'Savings & investments',
    'Value-added services',
    'Cybersecurity',
    'Human Resources',
    'Insurance',
    'Personal finance management (digital wallet, PFM)',
    'Cryptocurrencies & Tokens',
  ],
};

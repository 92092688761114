export default {
  button: `Download`,
  heading: `Download the Report`,
  listItem1: `What startups cooperate with banks in Poland and who are their founders`,
  listItem2: `How the most innovative companies make money`,
  listItem3: `Is piloting and testing a good start of a long relationship with the bank`,
  text1: `Meet the startups that transform Polish banking`,
  text2: `From the report you will find out:`,
  title: `Download the Report`,

  popup: {
    bank: 'Bank',
    download: 'Download',
    header: 'I work at...',
    inne: 'Other',
    inwestor: 'Investor',
    korporacja: 'Other corporation',
    msp: 'SME',
    ngo: 'NGO',
    startup: 'Startup',
  },
};

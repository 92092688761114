import React from 'react';
import BlockScroll from '../atoms/BlockScroll';
import Portal from '../organisms/Portal';
import styled from 'styled-components';
import fonts from '../../utils/fonts';
import CloseButton from '../molecules/CloseButton';
import { rgba } from 'polished';
import breakpoints from '../../utils/breakpoints';

const Backdrop = styled.div`
  background: ${rgba('#000010', 0.7)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 250;
`;

const Wrapper = styled.section`
  background-color: #004A99;
  border-radius: 0.875rem;
  left: 50%;
  max-width: ${props => props.mobileWidth};
  padding: 1.875rem 1.875rem 2.1875rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  z-index: 300;

  @media ${breakpoints.lg} {
    max-width: ${props => props.width}px;
    padding: 1.875rem 2.5rem 2.5rem;
  }
`;

const Header = styled.div`
  color: #FFFFFF;
  font-family: ${fonts.pko};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8125rem;
  margin-bottom: -0.625rem;
  max-width: 21.25rem;

  @media ${breakpoints.lg} {
    font-size: 1.875rem;
    line-height: 2.125rem;
  }
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: 2.5rem;
  top: -1.875rem;
  z-index: 100;
`;

const PopupType2 = ({ close, children, header, width, mobileWidth }) => {
  return (
    <Portal>
      <BlockScroll />
      <Backdrop />
      <Wrapper mobileWidth={mobileWidth ? `${mobileWidth}px` : '100%'} width={width ? width : 650}>
        <StyledCloseButton onClick={close} />

        <Header>{header}</Header>

        {children}
      </Wrapper>
    </Portal>
  );
};

export default PopupType2;

import React from 'react';
import logoPkoTfi from '../../images/logo-pko-tfi.svg';

const LogoPKOTFI = props => {
  return (
    <img src={logoPkoTfi} alt="PKO TFI logo" {...props} />
  );
};

export default LogoPKOTFI;

import React from 'react';
import logoPkoFintech from '../../images/logo-pko-fintech.svg';

const LogoPKOFintech = props => {
  return (
    <img src={logoPkoFintech} alt="PKO Fintech logo" {...props} />
  );
};

export default LogoPKOFintech;

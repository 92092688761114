import styled from 'styled-components';
import fonts from '../../utils/fonts';
import breakpoints from '../../utils/breakpoints';

const Paragraph = styled.p`
  font-size: 0.875rem;
  font-family: ${fonts.barlow};
  font-weight: 400;
  line-height: 1.5;

  @media ${breakpoints.md} {
    font-size: 1.0625rem;
  }
`;

export default Paragraph;

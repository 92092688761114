import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

const Section = styled.section`
  padding-top: 3rem;
  padding-bottom: 2rem;

  @media ${breakpoints.md} {
    padding-top: 8vh;
    padding-top: min(8vh, 5rem);
    padding-bottom: 5vh;
    padding-bottom: min(5vh, 3rem);
  }

  @media ${breakpoints.xl} {
    padding-top: min(8vh, 6rem);
    padding-top: 9vh;
    padding-bottom: 6vh;
    padding-bottom: min(6vh, 4rem);
  }
`;

export default Section;

import styled from 'styled-components';
import Paragraph from '../atoms/Paragraph';
import React from 'react';
import colors from '../../utils/colors';
import { transparentize } from 'polished';
import breakpoints from '../../utils/breakpoints';

const Wrapper = styled.div``;

const StyledParagraph = styled(Paragraph)`
  margin-top: 2rem;
  margin-bottom: 1.875rem;
  min-height: 13.125rem;

  @media ${breakpoints.md} {
    margin-top: 3rem;
    margin-top: 4rem;
  }
`;

const Hr = styled.hr`
  border: 0;
  height: 1px;
  width: 100%;
  margin-top: 2rem;
  background: linear-gradient(
    to right,
    ${colors.grayLight},
    ${transparentize(1, colors.grayLight)} 100%
  );

  @media ${breakpoints.md} {
    margin-top: 4rem;
  }
`;

const AuthorDescription = ({ logo, link, children, ...props }) => (
  <Wrapper {...props}>
    {logo}
    <StyledParagraph>{children}</StyledParagraph>
    {link}
    <Hr />
  </Wrapper>
);

export default AuthorDescription;

export default {
  links: {
    additionalContent: `Materiały Dodatkowe`,
    letsFintech: `Let&rsquo;s Fintech`,
    startups: `100 startupów`,
    results: `Wyniki Badania`,
    experts: `Opinie Ekspertów`,
    startupsDialog: `Wywiady ze startupami`,
    startupWishes: `Startupowa lista życzeń`,
    founderTips: `Founder radzi`,
    reportAuthors: `Autorzy raportu`,
    downloadReport: `Pobierz Raport`,
  },
  reportAuthorsSection: {
    title: `Partnerzy raportu`,
    fintech: `<div><strong>Let’s Fintech with PKO Bank Polski!</strong></div>
Let’s Fintech to platforma rozwoju innowacji jednej z największych instytucji finansowych w regionie
Europy Środkowo-Wschodniej. W ramach Let’s Fintech, bank nawiązuje współprace biznesowe ze
startupami, wdraża technologie oraz prowadzi pilotaże wewnętrzne i produkcyjne.`,
    tfi: `<div><strong>PKO Towarzystwo Funduszy Inwestycyjnych</strong></div>
Fundusz inwestycyjny Venture Capital należący do Grupy PKO Banku Polskiego, funkcjonujący w
ramach PKO TFI. Fundusz inwestuje w projekty na różnym etapie rozwoju (pre-seed to late venture) z
obszaru nowych technologii (w tym fintech) w Polsce oraz za granicą. Fundusz zarządza obecnie 200
mln zł. Kontakt z zespołem VC <a href="mailto:vc@pkotfi.pl">vc@pkotfi.pl</a>.`,
    chmurakrajowa: `<div><strong>Chmura Krajowa</strong></div>
Operator Chmury Krajowej jest spółką technologiczną, utworzoną z inicjatywy PKO Banku Polskiego i
Polskiego Funduszu Rozwoju. Firma rozwija usługi związane z przetwarzaniem i przechowywaniem
danych, dedykowane polskim przedsiębiorstwom, administracji publicznej i sektorowi edukacji.`,
    theheart: `<div><strong>The Heart</strong></div>
The Heart to korporacyjny “Company Builder”, który we współpracy z międzynarodowymi
korporacjami tworzy startupy, produkty i usługi. W oparciu o autorską metodologię budujemy
indywidualnie dopasowane rozwiązania. Ponadto wspieramy korporacje w zwiększaniu przychodów i
minimalizowaniu kosztów poprzez wyszukiwanie i wdrażanie technologii dostarczanych przez
ekosystem startupów z całej Europy.`,
    fintechpoland: `<div><strong>Fintech Poland</strong></div>
Celem Fintech Poland jest prowadzenie platformy dialogu, w ramach której prezentujemy i
współtworzymy artykuły, raporty i wydarzenia promujące powstawanie w Polsce centrum
finansowego nowej generacji. Zespół FinTech Poland to prawnicy, ekonomiści i ludzie technologii.
Łączymy kompetencje strategiczne i regulacyjne.`,
    meet: `Odwiedź`,
  },
};

import styled from 'styled-components';

const FooterDivider = styled.hr`
  border: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0)
  );
  margin: 0 auto 0 0;
  width: 6.25rem;
`;

export default FooterDivider;

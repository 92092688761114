export default {
  links: {
    additionalContent: `Extra content`,
    letsFintech: `Let&rsquo;s Fintech`,
    startups: `100 Startups`,
    results: `Research results`,
    experts: `Expert Opinions`,
    startupsDialog: `Startup Interviews`,
    startupWishes: `Startup Wishlist`,
    founderTips: `Founders tips`,
    reportAuthors: `Authors`,
    downloadReport: `Download the Report`,
  },
  reportAuthorsSection: {
    title: `Authors`,
    fintech: `<div><strong>Let’s Fintech with PKO Bank Polski!</strong></div>
Let’s Fintech is an innovation development platform of one of the largest financial institutions in the
Central and Eastern European region. Within Let’s Fintech, the bank establishes business cooperation
with startups, implements technologies and conducts internal and production pilot projects.`,
    tfi: `<div><strong>PKO VC</strong></div>
Venture Capital Investment Fund is part of the PKO Bank Polski Group and operates under PKO TFI.
The fund invests in projects at various stages of development (from pre-seed to late venture) in the
area of new technologies (including fintech) in Poland and abroad. The fund currently manages PLN
200 million. To contact the VC team send an email to <a href="mailto:vc@pkotfi.pl">vc@pkotfi.pl</a>.`,
    chmurakrajowa: `<div><strong>Domestic Cloud Provider</strong></div>
The Domestic Cloud Provider is a technology company created on the initiative of PKO Bank Polski
and the Polish Development Fund. The company develops services related to data processing and
storage, dedicated to Polish enterprises, public administration and the education sector.`,
    theheart: `<div><strong>The Heart</strong></div>
The Heart is a Corporate Company Builder that creates new businesses, products and services
together with corporate clients. Based on a proprietary developed methodology we build individually
tailored solutions. Moreover we find and integrate technologies created by the ecosystem of
pioneers and startups to help top corporations grow their revenue and reduce costs.`,
    fintechpoland: `<div><strong>Fintech Poland</strong></div>
The goal of Fintech Poland is to run a platform in which we present and co-create articles, reports,
and events promoting the development of a new financial center in Poland. The FinTech Poland team
consists of lawyers, economists, and technology specialists. We combine strategic and regulatory
competences.`,
    meet: `Check out`,
  },
};

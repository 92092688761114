const colors = {
  black: '#000010',
  red: '#E42229',
  blue: '#004A99',
  blueLight: '#065EC9',
  blueLighter: '#9BC9FB',
  gray: '#909090',
  grayLight: '#D9D9D9',
  grayLighter: '#f8f8f8',
};

export default colors;

import { css } from 'styled-components';
import breakpoints from './breakpoints';
import colors from './colors';
import fonts from './fonts';
import { darken, lighten } from 'polished';

export const acceleratedEasing = 'cubic-bezier(0, 0, 0.2, 1)';

export const deceleratedEasing = 'cubic-bezier(0.0, 0.0, 0.2, 1)';

export const containerStyles = css`
  margin: 0 auto;
  ${({ noGutters }) =>
    !noGutters &&
    css`
      padding-right: 25px;
      padding-left: 25px;
    `};

  @media ${breakpoints.md} {
    ${({ noGutters }) =>
      !noGutters &&
      css`
        padding-right: 30px;
        padding-left: 30px;
      `};
  }

  @media ${breakpoints.lg} {
    max-width: 950px;

    ${({ noGutters }) =>
      !noGutters &&
      css`
        padding-left: 40px;
        padding-right: 40px;
      `};

    ${({ fluidRight }) =>
      fluidRight &&
      css`
        overflow: hidden;
        max-width: none;
        margin-right: 0;
        margin-left: calc(50vw - 475px);
      `};
  }

  @media ${breakpoints.xl} and (min-height: 650px) {
    max-width: 1000px;

    ${({ noGutters }) =>
      !noGutters &&
      css`
        padding-left: 50px;
        padding-right: 50px;
      `};

    ${({ fluidRight }) =>
      fluidRight &&
      css`
        overflow: hidden;
        max-width: none;
        margin-right: 0;
        margin-left: calc(50vw - 500px);
      `};
  }

  @media ${breakpoints.xxl} and (min-height: 650px) {
    max-width: 1050px;

    ${({ noGutters }) =>
      !noGutters &&
      css`
        padding-left: 50px;
        padding-right: 50px;
      `};

    ${({ fluidRight }) =>
      fluidRight &&
      css`
        overflow: hidden;
        max-width: none;
        margin-right: 0;
        margin-left: calc(50vw - 525px);
      `};
  }

  @media ${breakpoints.hidpi} {
    max-width: 1180px;

    ${({ noGutters }) =>
      !noGutters &&
      css`
        padding-left: 50px;
        padding-right: 50px;
      `};

    ${({ fluidRight }) =>
      fluidRight &&
      css`
        overflow: hidden;
        max-width: none;
        margin-right: 0;
        margin-left: calc(50vw - 590px);
      `};
  }
`;

export const largerContainerStyles = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
`;

export const cardStyles = css`
  background: white;
  border-radius: 0.875rem;
  max-width: 32rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem 1.875rem;
`;

export const baseBorderRadiusStyles = css`
  border-radius: 0.625rem 0 0.625rem 0;
`;

export const baseButtonStyles = css`
  background-color: ${colors.red};
  color: white;
  font-size: 1.125rem;
  font-weight: bold;
  font-family: ${fonts.barlowCondensed};
  ${baseBorderRadiusStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
  text-decoration: none !important;
  transition: background-color 200ms ${acceleratedEasing};

  &:hover {
    background-color: ${lighten(0.15, colors.red)};
    color: white;
  }

  &:active {
    background-color: ${darken(0.15, colors.red)};
    color: white;
  }

  ${({ transparent }) =>
    transparent &&
    css`
      background-color: transparent;
      color: ${colors.red};

      &:hover {
        background-color: transparent;
        color: ${lighten(0.15, colors.red)};
      }

      &:active {
        background-color: transparent;
        color: ${darken(0.15, colors.red)};
      }
    `};
`;

export const primaryBoxButtonStyles = css`
  ${baseButtonStyles};
  height: 2.8125rem;
  padding: 0.75rem 1.125rem;

  @media ${breakpoints.md} {
    height: 3.75rem;
    padding: 1rem 1.625rem;
    font-size: 1.5rem;
  }
`;

import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import fonts from '../../utils/fonts';

export const PKOH1 = styled.h1`
  font-family: ${fonts.pko};
  font-size: 1.75rem;
  line-height: 1.15;
  margin: 1rem 0;

  @media ${breakpoints.md} {
    margin: 1.75rem 0;
    font-size: 3.125rem;
  }
`;

export const PKOH2 = styled.h2`
  font-family: ${fonts.pko};
  font-size: 1.5rem;
  line-height: 1.1;
  margin: 1rem 0;

  @media ${breakpoints.md} {
    margin: 1rem 0;
    font-size: 2.5rem;
  }
`;

export const PKOH3 = styled.h3`
  font-family: ${fonts.pko};
  font-size: 1.375rem;
  line-height: 1.1;
  margin: 1rem 0;

  @media ${breakpoints.md} {
    font-size: 2.0625rem;
  }
`;

export const PKOH4 = styled.h4`
  font-family: ${fonts.pko};
  font-size: 1.25rem;
  line-height: 1.15;
  font-weight: normal;
  margin: 1rem 0;

  @media ${breakpoints.md} {
    font-size: 1.875rem;
  }
`;

export const PKOH5 = styled.h5`
  font-family: ${fonts.pko};
  font-size: 1rem;
  line-height: 1.1;
  font-weight: bold;
  margin: 1rem 0;

  @media ${breakpoints.md} {
    font-size: 1.3125rem;
  }
`;

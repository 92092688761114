import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  color: #5c5c5c;
  font-size: 0.75rem;
`;

const Copyright = styled.div`
  display: block;
  margin-top: 0.75rem;
`;

const MailLink = styled.a`
  color: inherit;

  &:hover {
    color: white;
  }
`;

const SmallFooter = props => (
  <FooterWrapper {...props}>
    <MailLink href="mailto:fintech@pkobp.pl">fintech@pkobp.pl</MailLink>
    <Copyright>
      &copy; PKO Bank Polski Let&rsquo;s Fintech {new Date().getFullYear()}
    </Copyright>
  </FooterWrapper>
);

export default SmallFooter;

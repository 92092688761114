export default {
  heading: `O Let’s Fintech`,
  title: `O Let’s Fintech`,
  section1: {
    link: 'https://fintech.pkobp.pl/wspolpraca-ze-startupami/',
    button: `Dowiedz się więcej`,
    text: `
      <p>
        <strong>Let’s Fintech with PKO Bank Polski!</strong> to platforma rozwoju innowacji największego banku w regionie Europy Środkowo-Wschodniej. W jej ramach bank nawiązuje partnerstwa biznesowe ze startupami, gotowymi do przeprowadzenia pilotaży wewnętrznych i produkcyjnych oraz wyskalowania swojego biznesu.
      </p>

      <p>
        Let’s Fintech łączy wiele działań związanych ze środowiskiem startupowym: nie tylko współpracę biznesową, ale również dedykowaną infrastrukturę R&D oraz fundusz CVC. Dzięki temu możemy oferować startupom pełen wachlarz współpracy, umożliwiający rozwój i wzrost firmy.
      </p>
    `,
  },
  section2: {
    heading: `Let’s Fintech with&nbsp;PKO&nbsp;BANK&nbsp;POLSKI!`,
    item1: `Współpraca biznesowa ze startupami`,
    item2: `Infrastruktura R&D`,
    item3: `Fundusz CVC`,
    item4: `Współpraca z&nbsp;ekosystemem zewnętrznym`,
  },
  section3: {
    button1: {
      button: `Aplikuj!`,
      link: 'https://fintech.pkobp.pl/wspolpraca-ze-startupami/dolacz-do-let-s-fintech-with-pko-bank-polski/',
      text: `Chcesz współpracować z&nbsp;nami biznesowo?`,
    },
    button2: {
      button: `Napisz do nas`,
      text: `Jesteś zainteresowany inwestycją?`,
    },
    heading: `Współpracuj z Nami!`,
    item1: {
      heading: `500+`,
      text: `zgłoszeń do programu`,
    },
    item2: {
      heading: `10`,
      text: `prowadzonych pilotaży`,
    },
    item3: {
      heading: `12`,
      text: `wdrożonych rozwiązań startupów`,
    },
    text: `Nabór startupów prowadzimy w trybie ciągłym, a model współpracy z bankiem może zakładać: <strong>1)</strong> pilotaż rozwiązania - Proof of Concept (PoC), <strong>2)</strong> wdrożenie komercyjne i <strong>3)</strong> inwestycję kapitałową.`,
  },
  section4: {
    text: `
      <p>
        PKO Bank Polski angażuje się we współpracę ze startupami od prawie 5 lat. W tym czasie, wspólnie ze startupami przeprowadziliśmy największe wdrożenie technologii blockchain w Europie, rozwinęliśmy naszą otwartą bankowość i zbudowaliśmy ofertę usług dodanych dla klientów MŚP. W ramach Let’s Fintech każdy, zgłaszający się do nas zespół traktujemy indywidualnie i dostosowujemy się do specyfiki danego rozwiązania, co pozwala nam optymalizować proces współpracy. Dodatkowo działamy proaktywnie i angażujemy się w różne inicjatywy wspierające ekosystem innowacji - jedną z nich jest raport Startup the Bank.
      </p>
      <p>
        <em><strong>Grzegorz Pawlicki</strong> Dyrektor Biura Innowacji, PKO Bank Polski</em>
      </p>
    `,
  },
  section5: {
    heading: `Nasze wyróżnienia`,
  },
  section6: {
    heading: `Zespół Let’s Fintech`,
    text: `
      <p>
        Jeśli zdecydujesz się nawiązać z nami współpracę, nasz zespół będzie Twoim przewodnikiem po banku. Pomożemy Ci znaleźć właściciela biznesowego, który docelowo wdroży Twoje rozwiązanie, stworzymy dedykowany zespół projektowy, a jeśli szukasz finansowania, nasz zespół CVC przeanalizuje Twój startup w zakresie inwestycji.
      </p>
      <p>
        Jako Let’s Fintech łączymy kompetencje biznesowe i technologiczne – są wśród nas doświadczeni bankowcy, eksperci IT, weterani konsultingu i startupowcy. Dzięki temu szukając zastosowania w banku spojrzymy na Twoje rozwiązanie szeroko i zaangażujemy w projekt najlepszych ekspertów wewnętrznych, którzy pomagają nam wprowadzać  innowacje we wszystkich obszarach grupy kapitałowej PKO.
      </p>
    `,
  },
};

import React, { useState } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import CloseButton from './CloseButton';
import ShareButton from './ShareButton';
import breakpoints from '../../utils/breakpoints';
import LogoPKOMobile from '../images/LogoPKOMobile';

const Wrapper = styled.div`
  @media ${breakpoints.toLg} {
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }
`;

const StyledContainer = styled(Container)`
  @media ${breakpoints.toLg} {
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Logo = styled.div`
  @media ${breakpoints.lg} {
    display: none;
  }
`;

const Buttons = styled.div`
  display: flex;

  @media ${breakpoints.toLg} {
    align-items: center;
  }

  @media ${breakpoints.lg} {
    justify-content: flex-end;
  }
`;

const StyledShareButton = styled(ShareButton)`
  @media ${breakpoints.lg} {
    display: none;
  }
`;

const StyledCloseButton = styled(CloseButton)`
  @media ${breakpoints.toLg} {
    margin-left: 1rem;
  }

  @media ${breakpoints.lg} {
    position: absolute;
    z-index: 100;
    top: -1.875rem;
  }
`;

const PopupMenu = ({ onShare, onClose, ...props }) => {
  const [showSharePopup, setShowSharePopup] = useState(false);

  return (
    <Wrapper {...props}>
      <StyledContainer>
        <Logo>
          <LogoPKOMobile style={{ width: '35px', height: 'auto' }} />
        </Logo>
        <Buttons>
          <StyledShareButton onClick={onShare} transparent />
          <StyledCloseButton onClick={onClose} />
        </Buttons>
      </StyledContainer>
    </Wrapper>
  );
}

export default PopupMenu;

export default {
  button: `Pobierz`,
  heading: `Pobierz Raport`,
  listItem1: `Jakie startupy współpracują z bankami w Polsce i kim są ich założyciele`,
  listItem2: `Jak i na czym zarabiają najbardziej innowacyjne firmy`,
  listItem3: `Czy pilotaże i testy to dobry początek długoterminowej współpracy z bankiem`,
  text1: `Poznaj startupy, które zmieniają polską bankowość`,
  text2: `Z raportu dowiesz się:`,
  title: `Pobierz Raport`,

  popup: {
    bank: 'Bank',
    download: 'Pobierz',
    header: 'Kim jesteś?',
    inne: 'Inne',
    inwestor: 'Inwestor',
    korporacja: 'Inna korporacja',
    msp: 'MŚP',
    ngo: 'NGO',
    startup: 'Startup',
  },
};

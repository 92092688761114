import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import colors from '../../utils/colors';
import { acceleratedEasing, deceleratedEasing } from '../../utils/styles';
import AnchorBold from '../atoms/AnchorBold';
import BlockScroll from '../atoms/BlockScroll';
import CloseButton from '../molecules/CloseButton';
import MenuNavigation from '../molecules/MenuNavigation';
import ShareButton from '../molecules/ShareButton';
import Portal from './Portal';

const closeAnimationDuration = 200;

const slideInLeft = keyframes`
from {
  transform: translateX(100%);
}
to {
  transform: translateX(0%);
}
`;

const slideOutLeft = keyframes`
from {
  transform: translateX(0%);
}
to {
  transform: translateX(100%);
}
`;

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.6) 70%
  );
  animation: ${fadeIn} ${closeAnimationDuration}ms ${deceleratedEasing};

  ${({ closing }) =>
    closing &&
    css`
      animation: ${fadeOut} ${closeAnimationDuration}ms ${deceleratedEasing};
    `};
`;

const MenuContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: ${colors.blue};
  z-index: 100;
  animation: ${slideInLeft} ${closeAnimationDuration}ms ${deceleratedEasing};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 3rem;

  @media ${breakpoints.md} {
    width: 32rem;
    padding: 2rem 3.75rem 3%;
  }

  @media ${breakpoints.xl} {
    width: 38rem;
  }

  ${({ closing }) =>
    closing &&
    css`
      animation: ${slideOutLeft} ${closeAnimationDuration}ms
        ${acceleratedEasing};
    `};
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  @media ${breakpoints.md} {
    flex-direction: row;
    margin-left: -3.75rem;
  }
`;

const StyledCloseButton = styled(CloseButton)`
  margin-left: 1.5rem;

  @media ${breakpoints.md} {
    margin-left: 0;
    transform: translateX(-50%);
  }
`;

const StyledMenuNavigation = styled(MenuNavigation)`
  flex-grow: 2;
  margin: 1rem 0;
  max-height: 100%;
  overflow-y: auto;

  @media ${breakpoints.md} {
    margin: 10% 0 1rem;
  }

  @media screen and (max-height: 670px) {
    margin: 1rem 0;
  }
`;

const Hr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  background-color: ${transparentize(0.7, colors.blueLighter)};
  margin-right: -2rem;

  @media ${breakpoints.md} {
    margin-right: -3.75rem;
  }
`;

const Email = styled(AnchorBold)`
  font-size: 1.5rem;
  margin-top: 0.75rem;
  display: inline-block;

  @media ${breakpoints.md} {
    font-size: 1.875rem;
    margin-top: 1rem;
  }
`;

const StyledShareButton = styled(ShareButton)`
  margin: 0;

  @media ${breakpoints.toMd} {
    width: 3.25rem;
  }

  svg {
    @media ${breakpoints.toMd} {
      font-size: 24px;
      left: -1px;
      position: relative;
    }
  }
`;

const StyledFooter = styled.footer`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Menu = ({ close }) => {
  const [closing, setClosing] = useState(false);

  const closeMenu = () => {
    if (closing) {
      return;
    }
    setClosing(true);
    setTimeout(() => close(), closeAnimationDuration);
  };

  return (
    <>
      <Portal>
        <BlockScroll />
        <Backdrop closing={closing} />
        <MenuContainer closing={closing}>
          <Buttons>
            <StyledCloseButton onClick={closeMenu} />
            <StyledShareButton />
          </Buttons>

          <StyledMenuNavigation close={closeMenu} />

          <StyledFooter>
            <Hr />
            <Email href="mailto:fintech@pkobp.pl">fintech@pkobp.pl</Email>
          </StyledFooter>
        </MenuContainer>
      </Portal>
    </>
  );
};

Menu.propTypes = {
  close: PropTypes.func.isRequired,
};

export default Menu;

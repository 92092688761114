import React from 'react';
import logoMobile from '../../images/logo-mobile.svg';

const LogoPKOMobile = props => {
  return (
    <img src={logoMobile} alt="PKO logo" {...props} />
  );
};

export default LogoPKOMobile;

import styled from 'styled-components';
import { Link } from 'gatsby';
import fonts from '../../utils/fonts';
import breakpoints from '../../utils/breakpoints';
import colors from '../../utils/colors';

const LinkLarge = styled(Link)`
  font-family: ${fonts.pko};
  font-size: 1.25rem;
  line-height: 1.15;
  font-weight: normal;
  text-decoration: none !important;
  color: white;

  @media ${breakpoints.md} {
    font-size: 1.875rem;
  }

  &:hover {
    color: ${colors.blueLighter};
  }
`;

export default LinkLarge;

import styled from 'styled-components';
import { Link } from 'gatsby';
import fonts from '../../utils/fonts';
import colors from '../../utils/colors';

const LinkBold = styled(Link)`
  font-family: ${fonts.barlowCondensed};
  font-size: 1.5rem;
  color: ${colors.blueLighter};
  font-weight: 600;
  line-height: 1.15;
  text-decoration: none !important;

  &:hover {
    color: white;
  }
`;

export default LinkBold;

import languages from '../i18n/languages';
import routes from '../i18n/routes';

const defaultLocale = languages.find(_ => _.default).locale;

export const getRoute = (component, intl, targetLocale = undefined) => {
  const locale = targetLocale || intl.locale;

  if (locale === defaultLocale) {
    return routes[component][locale];
  }

  return `/${locale}` + routes[component][locale];
};
